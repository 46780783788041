
import React,{useState,useEffect}from "react";
// @material-ui/core components
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import Despesas from "views/Despesas/Despesas"
import DespesasLista from "views/Despesas/DespesasLista"
import { Switch, Route, Redirect } from "react-router-dom";

// core components

export default function DespesasGestao() {
  const [value, setValue] = useState(0)
  const [despesasSelecionada,setDespesas] = useState({})
  const [modDespesas,setMode] = useState("create")

  const handleChangeView  = (visao,payload,mode)=>{
    setDespesas(payload)
    setMode(mode)
    setValue(1) 
  }

  if(localStorage.getItem('cod_estabelecimento') == 'null'){
    return <Redirect to="/login"  />
 } 

  return (
    <div>
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        if(newValue == 1 ){
        }else{
          setValue(newValue);
        }
      }}
      showLabels
    >
      <BottomNavigationAction label="Tipos de Despesas" icon={<PlaylistAddSharpIcon />} />
    </BottomNavigation>
    {value === 0 ? (<DespesasLista handleChangeView={handleChangeView}/>):(<Despesas mode={modDespesas} teste={despesasSelecionada}/>)}
    </div>
  );
}