class PublicApiConnector{
   
    constructor(){
    
    } 

    static async consultaCep (cep) {
      const options = {
        method: 'GET',
      }

      try{
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, options)
        if(response.status === 401 || response.status === 500){
          throw response;
        }else{
          return response.json()
        } 
      }catch(error){
        //console.log(error)
        throw error;
      }
    }

    static async listaEstados (componente,payload) {
        const options = {
          method: 'GET',
        }
  
        try{
          const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`, options)
          if(response.status === 401 || response.status === 500){

            throw response;
          }else{
            
           // response.sort((a,b) => (a.sigla > b.sigla) ? 1 : ((b.sigla > a.sigla) ? -1 : 0))
            return response.json()
          } 
        }catch(error){
          //console.log(error)
          throw error;
        }
      }

      static async listaMunicipios (uf) {
        const options = {
          method: 'GET',
        }
  
        try{
          const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/distritos`, options)
          if(response.status === 401 || response.status === 500){
            console.log(response)
            
            throw response;
          }else{
            return response.json()
          } 
        }catch(error){
          //console.log(error)
          throw error;
        }
      }
}

export default PublicApiConnector