import React,{useState,useEffect} from "react";
import { Fragment } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [isLogin,setLogin] = React.useState(true)
  const [openEvent, setOpenEvent] = useState(false);
  const [novasenha, setNovaSenha] = useState('');
  const [novasenhaconfirma, setNovaSenhaConfirma] = useState('');
  const [loading,setLoading] = useState(false)
  const [openSnack, setOpenSnack] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [message,setMessage] = useState('')

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);

  };

  const handleCloseEvent = () => {
    setOpenEvent(false);
    setLoading(false);
    setNovaSenha('')
    setNovaSenhaConfirma('')
  };

  const handleResetOpen = ()=>{
    setOpenEvent(true);
  }

  const handleLogout = ()=>{
    localStorage.setItem('cod_estabelecimento',null)
    setLogin(false)
  }

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleReset = ()=>{
    if(novasenha != '' && novasenhaconfirma != ''){
      if(novasenha == novasenhaconfirma){
        handleLogin()
      }else{
        setLoading(false);
        setTransition(() => TransitionLeft);
        setOpenSnack(true);
        setMessage('A senha não corresponde com a confirmação')
      }
    }else{
      setLoading(false);
      setTransition(() => TransitionLeft);
      setOpenSnack(true);
      setMessage('Não é possivel alterar para uma senha em branco')
    }
  }

  async function handleLogin(){
    setLoading(true);
      try{
        const response = await ApiConnectorTemplate.update('changepassword',{password:novasenha})
        console.log(response)
        setLoading(false);
        setTransition(() => TransitionLeft);
        setOpenSnack(true);
        setMessage('Senha alterada com sucesso')
        setLoading(false);
        setNovaSenha('')
        setNovaSenhaConfirma('')
        setOpenEvent(false);

      }catch(error){
        console.log(error.status)
        setLoading(false);
        setTransition(() => TransitionLeft);
        setOpenSnack(true);
        setMessage('Não foi possível alterar a senha')

       
      }
/*     console.log(user,pass)
    if(pass === 'admin' && user === "admin"){
      //setLogin(true)
      setOpen(true)
    } */
    
 
  }


   if(!isLogin){
     console.log('logado')
     return <Redirect to="/login"  />
  } 

  return (
    <div>

      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}>0</span>
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
{/*                     <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Mike John responded to your email
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You have 5 new tasks
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You{"'"}re now friend with Andrew
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another Notification
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      Another One
                    </MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleResetOpen}
                      className={classes.dropdownItem}
                    >
                      Reset Senha
                    </MenuItem>
{/*                     <MenuItem
                      onClick={handleCloseProfile} 
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem> */}
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout} 
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <Dialog
          open={openEvent}
          onClose={handleCloseEvent}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          
          disableEscapeKeyDown
          maxWidth={'xl'}

        >
          <DialogTitle id="alert-dialog-title">{`Alterar Senha`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                          fullWidth
                          id="novasenha"
                          name="nonasenha"
                          label="Nova Senha"
                          value={novasenha}
                          onChange={event => setNovaSenha(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            type: "password",
                          }}

                          
                        />
                  </GridItem>
              </GridContainer>
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                          fullWidth
                          id="novasenhaconfirma"
                          name="nonasenhaconfirma"
                          label="Nova Senha Confirmação"
                          value={novasenhaconfirma}
                          onChange={event => setNovaSenhaConfirma(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            type: "password",
                          }}

                          
                        />
                  </GridItem>
              </GridContainer>
              <p style={{height:'20px'}}>{loading ? <LinearProgress    /> : null}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Fragment>
            <Button  color="primary" onClick={handleReset} >
              Resetar
            </Button>
            </Fragment>
            
          </DialogActions>
        </Dialog>
        <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />
    </div>
  );
}
