import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import MomentUtils from "@date-io/moment";
import PrintIcon from '@material-ui/icons/Print';
import moment from "moment";
import "moment/locale/pt-br";


class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    this.state = {

    };
  }

  changeDropdown(event) {
    this.setState({ dropdownValue: event.target.value });
  }

  render() {
    return (
      <div>
        <Card>
            <CardHeader color="primary" /* style={{background:"black"}} */>
              <h4>Recibo de Pagamento de Comissão</h4>
              <p >
              
              </p>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
             <p><span><b>Nome do profissional</b></span>:{this.props.infos.nome}</p>
             <p><span><b>Valor pago em R$</b></span>:{this.props.elementos.filhos.reduce((acc,p)=>{return acc + p.valor_comissao},0).toFixed(2).replace(".",",")}</p>
             <p><span><b>Data do pagamento</b></span>:{moment(this.props.elementos.data_pagamento).format("DD/MM/YYYY")}</p> 
             <p><span><b>Data de emissão desta via:</b></span>{moment(new Date()).format("DD/MM/YYYY")}</p>
             <br></br> 
             <br></br>  
             <br></br>  
             <br></br>
             <p style={{marginLeft:'32%'}}>{`                    __________________________________________                      `}</p>
             <p style={{marginLeft:'38%'}}>{`                              Assinatura do profissional                             `}</p>    
             
            <Table stickyHeader  size="small" aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        
                       
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Produto</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Quantidade</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Preço</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Valor Total</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>% Comissão</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Valor Comissão</TableCell>                  
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.props.elementos.filhos.map((row,key) => (
                        <TableRow key={key} style={{fontSize:'10px',cursor:'pointer'}} >
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.desc_item}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.quantidade.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.preco.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.valor.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.comissao.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.valor_comissao.toFixed(2).replace(".",",")}</TableCell>                 
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
 
            </CardBody>
            <CardFooter>
             Documento gerado pelo systembarber
              
            </CardFooter>
          </Card>

 
      </div>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props)
  }
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <PrintIcon style={{cursor:'pointer'}}/>}
          content={() => this.componentRef}
        />
        <div style={{ display: "none" }}><ComponentToPrint ref={el => (this.componentRef = el)} elementos={this.props.elementos} infos={this.props.infos} /></div>
      </div>
    );
  }
}

export default Example;