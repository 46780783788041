
import React,{useState,useEffect}from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import Pontos from "views/Pontos/Pontos"
import PontosLista from "views/Pontos/PontosLista"

// core components

export default function DespesasGestao() {
  const [value, setValue] = useState(0)
  const [pontosSelecionados,setPontos] = useState({})
  const [modPontos,setMode] = useState("create")

  const handleChangeView  = (visao,payload,mode)=>{
    setPontos(payload)
    setMode(mode)
    setValue(1) 
  }

  
  if(localStorage.getItem('cod_estabelecimento') == 'null'){
    return <Redirect to="/login"  />
 } 

  return (
    <div>
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        if(newValue == 1 ){
        }else{
          setValue(newValue);
        }
      }}
      showLabels
    >
      <BottomNavigationAction label="Pontos por Serviço/Produto" icon={<PlaylistAddSharpIcon />} />
    </BottomNavigation>
    {value === 0 ? (<PontosLista handleChangeView={handleChangeView}/>):(<Pontos mode={modPontos} teste={pontosSelecionados}/>)}
    </div>
  );
}