import React ,{useState,useEffect}from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TableCustom from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import { Scrollbars } from 'react-custom-scrollbars';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';




import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'
import { func } from "prop-types";





const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardRecipient:{
    height:"calc(100vh - 180px)"
  }

  

 
};

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const useStyles = makeStyles(styles);

export default function ListaProfissional({handleChangeView}) {
  const componente = 'profissional'
  const classes = useStyles();
  const [listaProfissional,setLista] = useState([])
  const [listaFiltrada,setListaFiltrada] = useState([])
  const [listadePendencias,setListaPendencia] = useState([])
  const [allselecoes,setAllSelecoes] = useState(false)
  const [listaConfirmada,setListaConfirmada] = useState([])
  const [listaDeAcerto,setListaDeAcerto] = useState([])
  const [loading,setLoading] = useState(true)
  const [openDelete, setopenDelete] = useState({action:false,referencia:"",message:""});
  const [updateList,setUpdatelist]  = useState(0)
  const [termos,setTermos] = useState('')
  const [openAcertos,setOpenAcertos] = useState({abertura:false,cod_profissional:null});
  const [openSnack, setOpenSnak] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [message,setMessage] = useState('')
  const [dataInicialPeriodo,setDataIncial] = useState()

  
  useEffect(()=>{
    setLoading(true)
    ApiConnectorTemplate.list(componente,{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      setLista(r)
      setListaFiltrada(r)
      setLoading(false)
    })
  },[updateList])

  useEffect(()=>{
    if(openAcertos.abertura){
      ApiConnectorTemplate.list('acerto',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),cod_profissional:openAcertos.cod_profissional}).then(r=>{
        console.log(r)
        setListaPendencia(r)
/*         setLista(r)
        setListaFiltrada(r)
        setLoading(false) */
      })
    }

  },[openAcertos])

  useEffect(()=>{
    let data = formatDate()
    setDataIncial(data)       
},[])

  const formatDate2 = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
  }

  function handleView(id){
    handleChangeView(1,listaProfissional.reduce((p,a)=>a.cod_profissional === id? a:p,{}),'view')
  }

  function handleEdit(id){
    setOpenAcertos({abertura:true,cod_profissional:id})
  }

  function handleAddOrRemoveListSelected(row){
    let oldList = listaDeAcerto
    let newList = []
    let key = `${row.cod_referencia_venda}_${row.referencia}`
    console.log(key)
    let filtro = oldList.filter(x=> x ==`${key}`)
    if(filtro.length > 0){
      console.log('fluxoRmv')
      newList = oldList.filter(x=>{
          if(x != key){
            return x
          }
      })
    }else{
      console.log('fluxoAdd')
      newList = [...oldList]
      newList.push(key)
    }
    setListaDeAcerto(newList)
  }

/*   function handleCreate(){
    handleChangeView(1,{},'create')
  } */

  function handleConfimDelete(referencia){
     ApiConnectorTemplate.delete(componente,referencia).then(r=>{
      closeDelete()
      setUpdatelist(updateList + 1)
    }) 
  }

  function handleDelete(id){
    let item = listaProfissional.reduce((p,a)=>a.cod_profissional === id ? `${a.nome}`:p,{})
    setopenDelete({action:true,referencia:id,message:item})
  }

  function closeDelete(){
    setopenDelete({action:false,referencia:"",message:""})
  }

  function termosDePesquisa(e){
    setTermos(e.target.value)
  }

  function Search(){
    let keys = termos.split(' ');
    let lista = [...listaProfissional];

    lista = lista.filter(item=>{
        let bodyConsulta = item.nome
        let str = JSON.stringify(bodyConsulta);
        return keys.reduce((p,a)=> str.toUpperCase().indexOf(a.toUpperCase()) >=0 && p !== false,null);
        //return str.toUpperCase().indexOf(filtro.toUpperCase()) >=0;
    })
    setListaFiltrada(lista)
  }

  function handleCloseAcertos(){
    setOpenAcertos({abertura:false,cod_profissional:null})
    setListaDeAcerto([])
    setListaConfirmada([])
    setAllSelecoes(false)
  }


  function buildActions(id){
    return <div  style={{textAlign:"right"}}>
            <IconButton size="small" color="primary" aria-label="edit item" onClick={()=>handleEdit(id)}>
              <AccountBalanceWalletIcon />
            </IconButton>
            <IconButton size="small" color="primary" aria-label="view item" onClick={()=>handleView(id)}>
              <VisibilityIcon />
            </IconButton>
          </div>
  }


  function confirmarSelecao(){
    let listaConfirmada = []
    listaDeAcerto.forEach(element => {
        let id = element.split("_")
        let item = listadePendencias.filter(x=> x.cod_referencia_venda == id[0])
        listaConfirmada = [...listaConfirmada,...item]
    });
    setListaConfirmada(listaConfirmada)
  }

  function marcarDesmarcarTodas(){
    let t = []
    if(!allselecoes){
         t = listadePendencias.map(x=> `${x.cod_referencia_venda}_${x.referencia}`)
         setListaDeAcerto(t)
         setAllSelecoes(true)
    }else{
        setListaDeAcerto([])
        setListaConfirmada([])
        setAllSelecoes(false)
    }
  }


  const handleSalvar = async()=>{

    if(listaConfirmada.length > 0){
        ApiConnectorTemplate.add('acertopagamento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),itensacerto:listaConfirmada,dataPagamento:dataInicialPeriodo})
        .then(r=>{
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Acerto Realizado')
          handleCloseAcertos()  
        })
        .catch(error=>{
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Não foi possível registrar o acerto ')
          handleCloseAcertos() 
        })

    }else{
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Não há itens para gravar')
    } 
  }


  const handleCloseSnack = () => {
    setOpenSnak(false);
  };


  const formatDate = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.cardRecipient}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Acertos de Comissões</h4>
            <p className={classes.cardCategoryWhite}>
              Gestão de comissões devidas aos profissionais
            </p>
          </CardHeader>
          <CardBody>
            <div>
              {loading ? <LinearProgress style={{marginBottom:"10px"}}/> : null }
              
{/*               <Fab style={{right:10,position:'absolute',background:"black"}} size="medium" color="primary" aria-label="add" onClick={handleCreate}>
                <AddIcon />
              </Fab>  */}
            </div>
            <GridContainer >
              <GridItem xs={12} sm={12} md={8} style={{marginTop:"40px"}}>
                <TextField fullWidth  id="standard-basic" label="Filtre por Nome"  onChange={(e)=>termosDePesquisa(e)}  />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{marginTop:"40px"}}>
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={Search}>
                  <SearchIcon />
                </IconButton>
              </label>
              </GridItem>
            </GridContainer>
            <TableCustom
              tableHeaderColor="primary"
              tableHead={["","Nome", "Celular", "Município/UF", ""]}
              tableData={
              
                listaFiltrada.map(profissional=>{
                return  [<Avatar alt="profissional" src={require("assets/img/logo.png")} />,profissional.nome, profissional.telefone_celular, `${profissional.cidade}-${profissional.uf}`, buildActions(profissional.cod_profissional)]
              })
            }
            />
          </CardBody>
        </Card>
      </GridItem>
      <Drawer anchor={"bottom"} open={openDelete.action} onClose={closeDelete} >
        <div >
          <List component="nav" aria-label="main mailbox folders">
              <ListItem >
               <h4> Você deseja realmente remover {openDelete.message} da lista ?</h4>
              <Fab style={{right:60,position:'fixed'}} size="medium" color="secondary" aria-label="add" onClick={()=>handleConfimDelete(openDelete.referencia)}>
                <DeleteIcon />
              </Fab> 
              </ListItem>
          </List>
        </div>
      </Drawer>
      <Dialog 
        open={openAcertos.abertura}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleCloseAcertos} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
        >
        <DialogTitle style={{paddingBottom:"2px"}} id="form-dialog-title">Acertos de Comissões</DialogTitle>
        <DialogContent style={{paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px"}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
            <p>Lançamentos em aberto</p>
            <Scrollbars style={{ height: 150 ,marginTop:'30px'}}>
                  <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        
                       
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Produto</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Quantidade</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Preço</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Valor Total</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>% Comissão</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Valor Comissão</TableCell>
                        <TableCell style={{minWidth:60,fontSize:12,padding:0}}>Data</TableCell>                    
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listadePendencias.map((row,key) => (
                        <TableRow key={key} style={{fontSize:'10px',cursor:'pointer'}} onClick={()=>handleAddOrRemoveListSelected(row)} selected = {listaDeAcerto.reduce((p,a)=>a === `${row.cod_referencia_venda}_${row.referencia}`? true:p,false)}>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.desc_item}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.quantidade.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.preco.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.valor.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.comissao.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.valor_comissao.toFixed(2).replace(".",",")}</TableCell>                 
                          <TableCell  style={{minWidth:60,fontSize:12,padding:0}}>{ moment(row.data).format("DD/MM/YYYY")}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
                </Scrollbars >
            </GridItem>
            <GridItem xs={12} sm={12} md={2} style={{textAlign:'center'}}>
            <TextField 
                  fullWidth
                     label="Valor Total em Aberto R$"
                     margin="normal"
                     variant="filled"
                     disabled  
                     size={'small'}
                     id="valor_total_comissoes"
                     name="valor_total_comissoes"
                     value = {listadePendencias.reduce((acc,p)=>{return acc + p.valor_comissao},0).toFixed(2).replace(".",",")}
                     InputLabelProps={{
                       shrink: true,
                     }}
                     style={{marginBottom:'30px'}}
                  />
          <FormControlLabel
            control={<Checkbox checked={allselecoes} onChange={marcarDesmarcarTodas} name="allselecoes" />}
            label="Marcar / Desmarcar todas"
          />
                  <Button variant="contained" color="primary" onClick = {confirmarSelecao}>
                    Confirmar seleção
                  </Button>
            </GridItem>

          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
            <p>Lançamentos selecionados para dar baixa</p>
            <Scrollbars style={{ height: 150 ,marginTop:'30px'}}>
                  <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Produto</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Quantidade</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Preço</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Valor Total</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>% Comissão</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Valor Comissão</TableCell>
                        <TableCell style={{minWidth:60,fontSize:12,padding:0}}>Data</TableCell>                    
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listaConfirmada.map((row,key) => (
                        <TableRow key={key} style={{fontSize:'10px',cursor:'pointer'}}>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.desc_item}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.quantidade.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.preco.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.valor.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.comissao.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.valor_comissao.toFixed(2).replace(".",",")}</TableCell>                 
                          <TableCell  style={{minWidth:60,fontSize:12,padding:0}}>{ moment(row.data).format("DD/MM/YYYY")}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
                </Scrollbars >
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
            <TextField 
                  fullWidth
                     label="Valor Total Selecionado R$"
                     margin="normal"
                     variant="filled"
                     disabled  
                     size={'small'}
                     id="valor_total_comissoes_selecionado"
                     name="valor_total_comissoes_selecionado"
                     value = {listaConfirmada.reduce((acc,p)=>{return acc + p.valor_comissao},0).toFixed(2).replace(".",",")}
                    
                     InputLabelProps={{
                       shrink: true,
                     }}
                     style={{marginBottom:'30px'}}
                  />
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt-br"}>
                    <DatePicker
                     
                      fullWidth
                      InputLabelProps={{
                                        shrink: true,
                                      }}
                      id="dataInicial"
                      label="Data de Pagamento"
                      format="DD/MM/YYYY"
                      value={dataInicialPeriodo}
                      onChange = {date=> setDataIncial(formatDate2(date._d))}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
            </GridItem>

          </GridContainer>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAcertos} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSalvar} color="primary">
            Baixar Comissão
          </Button>
        </DialogActions>
      </Dialog> 
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        //TransitionComponent={transition}
        message={message}
        //key={transition ? transition.name : ''}
      />     
    </GridContainer>
  );
}






