import React,{useState,useEffect} from "react";

import { makeStyles } from "@material-ui/core/styles";

import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Scrollbars } from 'react-custom-scrollbars';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
/* import CustomInput from "components/CustomInput/CustomInput.js"; */
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import avatar from "assets/img/logo.png"
// dependencias para lidar com formulário
import { useFormik } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";

// operacoes assincronas
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'

import ComponentToPrint from './Example'


import moment from "moment";
import "moment/locale/pt-br";



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  description:{
    textAlign: "justify"
  },
  cardRecipient:{
    height:"calc(100vh - 180px)"
  }



  
};

const TextMaskCelular = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/,' ', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

const TextMaskTelefone = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={['(', /[0-9]/, /\d/,  ')', ' ', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}





const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  nome: yup
  .string('Informe o Nome')
  .required('Nome é um dado obrigatório'),
      
});


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}


export default function Barbearia({mode,teste}) {
 
  const componente = 'profissional'
  const classes = useStyles();
  const formik = useFormik({
     initialValues: {
      nome: mode === 'view' || mode === 'edit' ? teste.nome : '',
      cod_estabelecimento: mode === 'view' || mode === 'edit' ? teste.cod_estabelecimento : '',
      data_de_nascimento:mode === 'view' || mode === 'edit' ? teste.data_de_nascimento : null,
      cpf: mode === 'view' || mode === 'edit' ? teste.cpf : '',
      telefone_celular:mode === 'view' || mode === 'edit'? teste.telefone_celular : '',
      telefone_comercial:mode === 'view' || mode === 'edit' ? teste.telefone_comercial : '',
      email: mode === 'view' || mode == 'edit' ? teste.email : '',
      cep:mode === 'view' || mode === 'edit' ? teste.cep : '',
      rua:mode === 'view' || mode === 'edit' ? teste.rua : '',
      numero:mode === 'view' || mode ==='edit' ? teste.numero : '',
      bairro: mode === 'view' || mode === 'edit' ? teste.bairro : '',
      uf:mode === 'view' || mode === 'edit' ? teste.uf : '',
      cidade:mode === 'view' || mode === 'edit' ? teste.cidade : '',
      complemento:teste && mode === 'view' || mode === 'edit' ? teste.complemento : '',
      observacoes:mode === 'view' || mode === 'edit' ? teste.observacoes : '',
      ativo:mode === 'view' || mode === 'edit' ? teste.ativo : ''
      
      
    }, 
   validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
     if(mode === "create"){
        criarProfissional(componente,values)
      }else if(mode === "edit"){
        let payload = {...values}
        payload.cod_profissional = teste.cod_profissional
        editarProfissional(componente,payload)
      }
 
     // criarEstabelecimento(componente,values)
      //alert(JSON.stringify(values, null, 2));
    },
  });


  const [estabelecimentos,setEstabelecimentos] = useState([])
  // const [municipiosApi,setMunicipio] = useState([])
  // const [estadosApi,setEstadosApi] = useState([])
  const [listaAcertos,setListaAcertos] = useState([])
  const [valorTotal,setValorTotal] = useState(0)
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [loading,setLoading] = useState(false)
  const [message,setMessage] = useState('')
  const [expanded, setExpanded] = useState(false);


/*   const handleClick = (Transition) => () => {
    console.log('entrou')
    setTransition(() => Transition);
    setOpen(true);
  }; */

  const handleClose = () => {
    setOpen(false);
  };

  async function criarProfissional(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.add(componente,payload)
   
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Profissional Registrado')
      formik.resetForm()
      
    }catch(error){
      console.log(error)
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Houve um erro e não foi possível registrar')

    }
  }

  async function editarProfissional(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.update(componente,payload)
    
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Profissional Atualizado')
      //formik.resetForm()
      
    }catch(error){
      console.log(error)
      if(error.status === 401){
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('Não foram encontradas alterações para realizar')
      }
    }
  }

  useEffect(()=>{
    ApiConnectorTemplate.list('estabelecimento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
    
      setEstabelecimentos(r)
    })
  },[]) 

  useEffect(()=>{
   
      ApiConnectorTemplate.list('acertospagos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),cod_profissional:teste.cod_profissional}).then(r=>{
    
      const unique = [...new Set(r.map(item => item.cod_pagamento_de_comissao))];
      
      let estrutura = []
      let valor = 0
      unique.forEach(item=>{
          let ref =  r.find(x=> x.cod_pagamento_de_comissao == item)
          let o = {}
          
          o.cod_pagamento_de_comissao = item
          o.data_pagamento = ref.data_pagamento
          o.filhos = r.filter(x=> x.cod_pagamento_de_comissao == item)
          valor += o.filhos.reduce((acc,p)=>{return acc + p.valor_comissao},0)
          estrutura.push(o)
          
      })
      console.log('kkkkkkkkk',estrutura)
      estrutura.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.data_pagamento) - new Date(a.data_pagamento);
      });
      
      setListaAcertos(estrutura)
      setValorTotal(valor)
    }) 
  },[]) 





const handleChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : false);
};
  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card className={classes.cardRecipient}>
            <CardHeader color="primary" /* style={{background:"black"}} */>
              <h4 className={classes.cardTitleWhite}>Acertos de comissões </h4>
              <p className={classes.cardCategoryWhite}>
              Histórico e recibos de pagamentos
              </p>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
            <div className={classes.root}>
              {
                listaAcertos.map((element,key)=>(
                  <Accordion expanded={expanded === `${element.cod_pagamento_de_comissao}`} onChange={handleChange(`${element.cod_pagamento_de_comissao}`)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>{moment(element.data_pagamento).format("DD/MM/YYYY")}</Typography>
                    
                  </AccordionSummary>
                  <AccordionDetails>

                  <Scrollbars style={{ height: 150 ,marginTop:'30px'}}>
                  <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        
                       
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Produto</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Quantidade</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Preço</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Valor Total</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>% Comissão</TableCell>
                        <TableCell style={{minWidth:100,fontSize:12,padding:0}}>Valor Comissão</TableCell>                  
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {element.filhos.map((row,key) => (
                        <TableRow key={key} style={{fontSize:'10px',cursor:'pointer'}} >
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.desc_item}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.quantidade.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.preco.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.valor.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.comissao.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:100,fontSize:12,padding:0}}>{row.valor_comissao.toFixed(2).replace(".",",")}</TableCell>                 
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
                </Scrollbars >
                <ComponentToPrint elementos={element} infos={formik.values}/>
                


                  </AccordionDetails>
                </Accordion>
                ))
              }
            </div>
 
            </CardBody>
            <CardFooter>
              {mode == 'create' ? <Button onClick={formik.handleSubmit} color="primary">Criar Profissional</Button> : mode == 'edit' ? <Button onClick={formik.handleSubmit}  color="primary">Editar Profissional</Button>:null}
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card profile>
            <CardAvatar profile>
              <a href="#BSYSTEM" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>Profissional</h6>
              <h4 className={classes.cardTitle}>{formik.values.nome}</h4>
              <h5 className={classes.cardTitle}>R$ {valorTotal.toFixed(2).replace(".",",")}</h5>
              {/* <h5 className={classes.cardTitle}>Seg - Sab - 08:00 as 21:00</h5> */}
              <p className={classes.description} style={{ display: 'flex',justifyContent: 'center'}}>
             
              </p>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />


    </div>
  );
}
