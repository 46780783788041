import React,{useState,useEffect} from "react";
// @material-ui/core components
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';


// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
/* import CustomInput from "components/CustomInput/CustomInput.js"; */
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import avatar from "assets/img/logo.png"
// dependencias para lidar com formulário
import { useFormik } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";

// operacoes assincronas
import PublicApiConnector from 'connectors/publicapiconnector.js'
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  description:{
    textAlign: "justify"
  },


  
};

const TextMaskCelular = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/,' ', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

const TextMaskTelefone = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={['(', /[0-9]/, /\d/,  ')', ' ', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

const TextMaskCPF = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={[ /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/,'.', /[0-9]/, /[0-9]/, /[0-9]/,'-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

const TextMaskCEP = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={[ /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/,'-', /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

const TextMaskNascimento = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={[ /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/,'/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

TextMaskCelular.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskTelefone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskCPF.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskCEP.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskNascimento.propTypes = {
  inputRef: PropTypes.func.isRequired,
};



const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  estabelecimento: yup
  .string('Informe o estabelecimento')
  .required('Estabelecimento é um dado obrigatório'),
  nome_fantasia: yup
  .string('Informe o nome fantasia')
  .required('Nome Fantasia é um dado obrigatório'),
  cpf_cnpj: yup
  .string('Informe o CPF')
  .required('CPF é um dado obrigatório'),
  telefone_celular: yup
  .string('Informe o Celular')
  .required('Telefone Celular é um dado obrigatório'),
  telefone_comercial: yup
  .string('Informe o Telefone Comercial'),

  email: yup
  .string('Digite um email válido')
  .email('Digite um email válido')
  .required('Email é um dado obrigatório'),
  cep: yup
  .string('Informe o CEP')
  .required('CEP é um dado obrigatório'),
  rua: yup
  .string('Informe a rua')
  .required('Rua é um dado obrigatório'),
  numero: yup
  .string('Informe o número')
  .required('Número é um dado obrigatório'),
  bairro: yup
  .string('Informe o bairro')
  .required('Bairro é um dado obrigatório'),
  uf: yup
  .string('Informe a UF')
  .required('UF é um dado obrigatório'),
  cidade: yup
  .string('Informe a Cidade')
  .required('Cidade é um dado obrigatório'),
  complemento: yup
  .string('Informe o complemento'),
   usuario: yup
  .string('Informe o usuário')
  .required('Usuário é um dado obrigatório'),
  data_de_pagamento: yup
  .string('Informe a data de pagamento')
  .nullable()
  .required('Data de pagamento é um dado obrigatório'),
  data_validade_licenca: yup
  .string('Informe a data de validade da licença')
  .nullable()
  .required('Data de validade da licença é um dado obrigatório'),
  ativo: yup
  .string('Informe a situação')
  .required('Situação é um dado obrigatório'),
 
        
});


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}


export default function Barbearia({mode,teste}) {  
  const componente = 'estabelecimento'
  const classes = useStyles();
  const formik = useFormik({
     initialValues: {
      estabelecimento: mode === 'view' || mode === 'edit' ? teste.estabelecimento : '',
      nome_fantasia:mode === 'view' || mode === 'edit' ? teste.nome_fantasia : '',
      cpf_cnpj: mode === 'view' || mode === 'edit' ? teste.cpf_cnpj : '',
      telefone_celular:mode === 'view' || mode === 'edit'? teste.telefone_celular : '',
      telefone_comercial:mode === 'view' || mode === 'edit' ? teste.telefone_comercial : '',
      email: mode === 'view' || mode == 'edit' ? teste.email : '',
      cep:mode === 'view' || mode === 'edit' ? teste.cep : '',
      rua:mode === 'view' || mode === 'edit' ? teste.rua : '',
      numero:mode === 'view' || mode ==='edit' ? teste.numero : '',
      bairro: mode === 'view' || mode === 'edit' ? teste.bairro : '',
      uf:mode === 'view' || mode === 'edit' ? teste.uf : '',
      cidade:mode === 'view' || mode === 'edit' ? teste.cidade : '',
      complemento:teste && mode === 'view' || mode === 'edit' ? teste.complemento : '',
      sobre:mode === 'view' || mode === 'edit' ? teste.sobre : '',
      usuario:mode === 'view' || mode === 'edit' ? teste.usuario : '',
      data_de_pagamento:mode === 'view' || mode === 'edit' ? teste.data_de_pagamento : null,
      data_validade_licenca:mode === 'view' || mode === 'edit' ? teste.data_validade_licenca : null ,   
      ativo:mode === 'view' || mode === 'edit' ? teste.ativo : '' ,
   
      
      
    }, 
    validationSchema: validationSchema,
    onSubmit: (values) => {

      if(mode === "create"){
        criarEstabelecimento(componente,values)
      }else if(mode === "edit"){
        let payload = {...values}
        payload.cod_estabelecimento = teste.cod_estabelecimento
        editarEstabelecimento(componente,payload)
      }

     // criarEstabelecimento(componente,values)
      //alert(JSON.stringify(values, null, 2)); 
    },
    validateOnChange:false,
    validateOnBlur:false
  });

  const [municipiosApi,setMunicipio] = useState([])
  const [estadosApi,setEstadosApi] = useState([])
  const [estadoSelecionado,setEstadoSelecionado] = useState('')
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [loading,setLoading] = useState(false)
  const [message,setMessage] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    console.log(date)
    formik.setFieldValue("data_teste",date._i)
    
    //setSelectedDate(date);
  };


    useEffect(()=>{
    PublicApiConnector.listaEstados()
    .then(r=>{
     
      setEstadosApi(r.sort((a,b) => (a.sigla > b.sigla) ? 1 : ((b.sigla > a.sigla) ? -1 : 0)))
    })

  },[]) 

   useEffect(() => {
    PublicApiConnector.listaMunicipios(formik.values.uf).then(r=>{
    
      r.sort(function (a, b) {
        return a.nome.localeCompare(b.nome);
      });

      setMunicipio(r)
    })
  }, [formik.values.uf])


  useEffect(() => {
   if(formik.values.cep.replace(/\s/g, '').length == 10){
    PublicApiConnector.consultaCep(formik.values.cep.replace(/[^0-9]/g, '')).then(r=>{
      if(!r.erro){
        if(r.bairro !== ""){
          formik.setFieldValue("bairro",r.bairro)
        }
        if(r.logradouro !== ""){
          formik.setFieldValue("rua",r.logradouro)
        }

        if(r.uf !== ""){
          formik.setFieldValue("uf",r.uf)
        }

        if(r.localidade !== ""){
          formik.setFieldValue("cidade",r.localidade)
        }
      }else{

      }
    }) 
  } 

}, [formik.values.cep])  



  


/*   const handleClick = (Transition) => () => {
    console.log('entrou')
    setTransition(() => Transition);
    setOpen(true);
  }; */

  const handleClose = () => {
    setOpen(false);
  };

  async function criarEstabelecimento(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.add(componente,payload)
   
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Estabelecimento Registrado')
      formik.resetForm()
      
    }catch(error){
      console.log(error)
      if(error.status == 409){
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('O usuário informado já existe')
      }else{
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('Não foi possível cadastrar')
      }

    }
  }

  async function editarEstabelecimento(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.update(componente,payload)
    
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Estabelecimento Atualizado')
      //formik.resetForm()
      
    }catch(error){
      console.log(error)
      if(error.status === 401){
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('Não foram encontradas alterações para realizar')
      }
    }
  }


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary" /* style={{background:"black"}} */>
              <h4 className={classes.cardTitleWhite}>Dados Cadastrais do Estabelecimento</h4>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
              <GridContainer >

                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="estabelecimento"
                    name="estabelecimento"
                    label="Estabelecimento"
                    value={formik.values.estabelecimento}
                    onChange={formik.handleChange}
                    error={formik.touched.estabelecimento && Boolean(formik.errors.estabelecimento)}
                    helperText={formik.touched.estabelecimento && formik.errors.estabelecimento}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
               
               
              </GridContainer>
              <GridContainer >
                <GridItem xs={12} sm={12} md={8}>
                <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="nome_fantasia"
                    name="nome_fantasia"
                    label="Responsável/Proprietário"
                    value={formik.values.nome_fantasia}
                    onChange={formik.handleChange}
                    error={formik.touched.nome_fantasia && Boolean(formik.errors.nome_fantasia)}
                    helperText={formik.touched.nome_fantasia && formik.errors.nome_fantasia}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <TextField
                    disabled ={mode == 'create' || mode == 'edit'? false : true}
                    fullWidth
                    id="cpf_cnpj"
                    name="cpf_cnpj"
                    label="CPF"
                    value={formik.values.cpf_cnpj}
                    onChange={formik.handleChange}
                    error={formik.touched.cpf_cnpj && Boolean(formik.errors.cpf_cnpj)}
                    helperText={formik.touched.cpf_cnpj && formik.errors.cpf_cnpj}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskCPF,
                    }}
                  />
                </GridItem>
              </GridContainer  >
              <GridContainer >
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskCelular,
                    }}
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="telefone_celular"
                    name="telefone_celular"
                    label="Celular"
                    value={formik.values.telefone_celular}
                    onChange={formik.handleChange}
                    error={formik.touched.telefone_celular && Boolean(formik.errors.telefone_celular)}
                    helperText={formik.touched.telefone_celular && formik.errors.telefone_celular}
                  />
                  
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="telefone_comercial"
                    name="telefone_comercial"
                    label="Telefone Comercial"
                    value={formik.values.telefone_comercial}
                    onChange={formik.handleChange}
                    error={formik.touched.telefone_comercial && Boolean(formik.errors.telefone_comercial)}
                    helperText={formik.touched.telefone_comercial && formik.errors.telefone_comercial}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskTelefone,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>

              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                    <TextField
                        disabled ={mode == 'create' || mode == 'edit' ? false : true}
                        fullWidth
                        id="cep"
                        name="cep"
                        label="CEP"
                        value={formik.values.cep}
                        onChange={formik.handleChange}
                        error={formik.touched.cep && Boolean(formik.errors.cep)}
                        helperText={formik.touched.cep && formik.errors.cep}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: TextMaskCEP,
                        }}
                      />
                    </GridItem>
              </GridContainer>
              <GridContainer >
              <GridItem xs={12} sm={12} md={9}>
                  <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="rua"
                    name="rua"
                    label="Rua"
                    value={formik.values.rua}
                    onChange={formik.handleChange}
                    error={formik.touched.rua && Boolean(formik.errors.rua)}
                    helperText={formik.touched.rua && formik.errors.rua}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="numero"
                    name="numero"
                    label="Número"
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    error={formik.touched.numero && Boolean(formik.errors.numero)}
                    helperText={formik.touched.numero && formik.errors.numero}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                </GridContainer>

                <GridContainer >
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                      disabled ={mode == 'create' || mode == 'edit' ? false : true}
                      fullWidth
                      id="bairro"
                      name="bairro"
                      label="Bairro"
                      value={formik.values.bairro}
                      onChange={formik.handleChange}
                      error={formik.touched.bairro && Boolean(formik.errors.bairro)}
                      helperText={formik.touched.bairro && formik.errors.bairro}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <InputLabel htmlFor="select-uf" style={{fontSize: "12px",marginBottom: "4px"}}>UF</InputLabel>
                        <Select
                          disabled ={mode == 'create' || mode == 'edit' ? false : true}
                          fullWidth
                          id="uf"
                          name="uf"
                          labelId="select-uf"
                          value={formik.values.uf}
                          onChange={formik.handleChange}
                          error={formik.touched.uf && Boolean(formik.errors.uf)}
                          helperText={formik.touched.uf && formik.errors.uf}
                        >
                          {estadosApi.map(estado=>
                              <MenuItem key={estado.id} value={estado.sigla}>{estado.sigla}</MenuItem>
                          )}
                      </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                    <InputLabel htmlFor="select-cidade" style={{fontSize: "12px",marginBottom: "4px"}}>Cidade</InputLabel>
                          <Select
                            disabled ={mode == 'create' || mode == 'edit' ? false : true}
                            fullWidth
                            labelId="select-cidade"
                            id="cidade"
                            name="cidade"
                            value={formik.values.cidade}
                            onChange={formik.handleChange}
                            error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                            helperText={formik.touched.cidade && formik.errors.cidade}
                          >
                           {municipiosApi.map(municipio=>
                                <MenuItem key={municipio.id} value={municipio.nome}>{municipio.nome}</MenuItem>
                            )}
                        </Select>
                </GridItem>
              </GridContainer>
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                        disabled ={mode == 'create' || mode == 'edit' ? false : true}
                        fullWidth
                        id="complemento"
                        name="complemento"
                        label="Complemento"
                        value={formik.values.complemento}
                        onChange={formik.handleChange}
                        error={formik.touched.complemento && Boolean(formik.errors.complemento)}
                        helperText={formik.touched.complemento && formik.errors.complemento}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                      />
                </GridItem>
              </GridContainer >
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                       disabled ={mode == 'create' || mode == 'edit' ? false : true}
                        fullWidth
                        id="sobre"
                        name="sobre"
                        label="Sobre o estabelecimento"
                        value={formik.values.sobre}
                        onChange={formik.handleChange}
                        error={formik.touched.sobre && Boolean(formik.errors.sobre)}
                        helperText={formik.touched.sobre && formik.errors.sobre}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                      />
                </GridItem>
              </GridContainer >
              <GridContainer >
              <GridItem xs={12} sm={12} md={6}>
                <TextField
                        disabled = {mode == 'create' ? false : true}
                        fullWidth
                        id="usuario"
                        name="usuario"
                        label="Usuário"
                        value={formik.values.usuario}
                        onChange={formik.handleChange}
                        error={formik.touched.usuario && Boolean(formik.errors.usuario)}
                        helperText={formik.touched.usuario && formik.errors.usuario}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                      />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt-br"}>
                    <DatePicker
                      disabled = {localStorage.getItem('perfil') == '1'  ? false : true}
                      fullWidth
                      InputLabelProps={{
                                        shrink: true,
                                      }}
                      id="data_de_pagamento"
                      label="Data de Pagamento"
                      format="DD/MM/YYYY"
                      value={formik.values.data_de_pagamento}
                      onChange={date => formik.setFieldValue('data_de_pagamento', date._d, false)}
                      error={formik.touched.data_de_pagamento && Boolean(formik.errors.data_de_pagamento)}
                      helperText={formik.touched.data_de_pagamento && formik.errors.data_de_pagamento}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>

                

              </GridContainer >
              <GridContainer >
              <GridItem xs={12} sm={12} md={6}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt-br"}>
                    <DatePicker
                      disabled = {localStorage.getItem('perfil') == '1'  ? false : true}
                      fullWidth
                      InputLabelProps={{
                                        shrink: true,
                                      }}
                      id="data_validade_licenca"
                      label="Data Vencimento da Licença"
                      format="DD/MM/YYYY"
                      value={formik.values.data_validade_licenca}
                      onChange={date => formik.setFieldValue('data_validade_licenca', date._d, false)}
                      error={formik.touched.data_validade_licenca && Boolean(formik.errors.data_validade_licenca)}
                      helperText={formik.touched.data_validade_licenca && formik.errors.data_validade_licenca}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <InputLabel htmlFor="select-ativo" style={{fontSize: "12px",marginBottom: "4px"}}>Situação</InputLabel>
                          <Select
                            disabled ={localStorage.getItem('perfil') == '1'  ? false : true}
                            fullWidth
                            labelId="select-ativo"
                            id="ativo"
                            name="ativo"
                            value={formik.values.ativo}
                            onChange={formik.handleChange}
                            error={formik.touched.ativo && Boolean(formik.errors.ativo)}
                            helperText={formik.touched.ativo && formik.errors.ativo}
                          >
                                <MenuItem  value={0}>Ativo</MenuItem>
                                <MenuItem  value={1}>Inativo</MenuItem>
                        </Select>
                </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={4}>

                </GridItem>
              </GridContainer>

             {loading ? <LinearProgress style={{marginTop:"20px"}} /> : null}
                
             
            </CardBody>
            <CardFooter>
              {mode == 'create' ? <Button style={{marginTop:'30px'}} onClick={formik.handleSubmit} color="primary">Criar Estabelecimento</Button> : mode == 'edit' ? <Button style={{marginTop:'30px'}} onClick={formik.handleSubmit}  color="primary">Editar Estabelecimento</Button>: <Button style={{visibility: 'hidden',marginTop:'30px'}} >AAA</Button>}
              
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#BSYSTEM" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>Barbearia</h6>
              <h4 className={classes.cardTitle}>{formik.values.estabelecimento}</h4>
              {/* <h5 className={classes.cardTitle}>Seg - Sab - 08:00 as 21:00</h5> */}
              <p className={classes.description} style={{ display: 'flex',justifyContent: 'center'}}>
{/*                 Desde 1998 Coronel Brooke representa o homem moderno,mantendo o estilo do homem raiz sem ser antiquado
                Com um espaço confortável, recebe seus clientes por agendamento.Espaço climatizado com estrutura para
                aproveitar o momento tomando um chopp trincando e uma partida de bilhar */}
                {formik.values.sobre}
              </p>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />


    </div>
  );
}
