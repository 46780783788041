
import React,{useState,useEffect}from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import Comissoes from "views/Comissoes/Comissoes"
import ComissoesLista from "views/Comissoes/ComissoesLista"

// core components

export default function ComissaoGestao() {
  const [value, setValue] = useState(0)
  const [comissaoSelecionada,setComissao] = useState({})
  const [modeComissao,setMode] = useState("create")

  const handleChangeView  = (visao,payload,mode)=>{
    setComissao(payload)
    setMode(mode)
    setValue(1) 
  }

  if(localStorage.getItem('cod_estabelecimento') == 'null'){
    return <Redirect to="/login"  />
 } 

  return (
    <div>
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        if(newValue == 1 ){
        }else{
          setValue(newValue);
        }
      }}
      showLabels
    >
      <BottomNavigationAction label="Comissões" icon={<PlaylistAddSharpIcon />} />
    </BottomNavigation>
    {value === 0 ? (<ComissoesLista handleChangeView={handleChangeView}/>):(<Comissoes mode={modeComissao} teste={comissaoSelecionada}/>)}
    </div>
  );
}