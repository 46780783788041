import React,{useState,useEffect}from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import Barbearia from "views/Barbearia/Barbearia"
import ListaBarbearia from "views/Barbearia/ListaBarbearia"

// core components

export default function Estabelecimento() {
  const [value, setValue] = useState(0)
  const [barbearia,setBarbearia] = useState({})
  const [modeBarbearia,setMode] = useState("create")

  const handleChangeView  = (visao,payload,mode)=>{
    setBarbearia(payload)
    setMode(mode)
    setValue(1) 
  }

  if(localStorage.getItem('cod_estabelecimento') == 'null'){
    return <Redirect to="/login"  />
 } 

  return (
    <div>
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        if(newValue == 1 ){
     /*        setMode('create')
            setBarbearia(false) */
        }else{
          setValue(newValue);
        }
      }}
      showLabels
    >
      <BottomNavigationAction label="Estabelecimentos" icon={<PlaylistAddSharpIcon />} />
    </BottomNavigation>
    {value === 0 ? (<ListaBarbearia handleChangeView={handleChangeView}/>):(<Barbearia mode={modeBarbearia} teste={barbearia}/>)}
    </div>
  );
}
