/*eslint-disable*/

import React,{useState,useEffect} from "react";
import { withStyles } from '@material-ui/core/styles';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classnames from "classnames";

import imagine1 from "assets/img/sidebar-1.jpg";
import imagine2 from "assets/img/sidebar-2.jpg";
import imagine3 from "assets/img/sidebar-3.jpg";
import imagine4 from "assets/img/sidebar-4.jpg";
import imagine5 from "assets/img/sidebar-5.png";
import imagine6 from "assets/img/sidebar-6.jpg";
import imagine7 from "assets/img/sidebar-7.jpg";
import imagine8 from "assets/img/sidebar-8.jpg";
import imagine9 from "assets/img/sidebar-9.jpg";

//import Button from "components/CustomButtons/Button.js";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Portal from '@material-ui/core/Portal';
import { useFormik } from 'formik';
import * as yup from 'yup';

import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const validationSchema = yup.object({
/*   cod_estabelecimento: yup
  .string('Informe o estabelecimento')
  .required('Estabelecimento é um dado obrigatório').typeError("Deve ser informado um Estabelecimento"),
  referencia: yup
  .string('Informe a Descricao')
  .required('Descricao é um dado obrigatório'),

  tipo_origem: yup
  .string('Informe o Tipo')
  .required('O Tipo é um dado obrigatório'),
  quantidade: yup
  .string('Informe o Quantidade')
  .required('A Quantidade é um dado obrigatório'), */

  cod_estabelecimento :yup
  .string('Informe o estabelecimento')
  .required('Estabelecimento é um dado obrigatório').typeError("Deve ser informado um Estabelecimento"),
  saudacao_alerta_retorno : yup  
  .string('Informe a Saudação do alerta de Retorno')
  .required('Alerta de Retorno é um item obrigatório').typeError("Deve ser informado a Saudação do alerta de Retorno"),
  mensagem_alerta_retorno : yup
  .string('Informe a Mensagem do alerta de Retorno')
  .required('Mensagem de Retorno é um item obrigatório').typeError("Deve ser informado a Mensagem do alerta de Retorno"),

  saudacao_alerta_aniversario :  yup  
  .string('Informe a Saudação do alerta de Aniversário')
  .required('Alerta de Aniversário é um item obrigatório').typeError("Deve ser informado a Saudação do alerta de Aniversário"),
  mensagem_alerta_aniversario : yup
  .string('Informe a Mensagem do alerta de Aniversário')
  .required('Mensagem de Aniversário é um item obrigatório').typeError("Deve ser informado a Mensagem do alerta de Aniversário"),

  desconto_debito :  yup
  .number().transform((_, value) => {
      
    if (value.toString().includes('.')) {
      return null;
    }
    return +value.toString().replace(/,/, '.');
  }).typeError("Deve ser informado um valor")
  .required('Desconto débito  é um dado obrigatório'),
  desconto_credito :  yup
  .number().transform((_, value) => {
      
    if (value.toString().includes('.')) {
      return null;
    }
    return +value.toString().replace(/,/, '.');
  }).typeError("Deve ser informado um valor")
  .required('Desconto crédito  é um dado obrigatório'),
  dias_alerta_retorno : yup
  .number().transform((_, value) => {
      
    if (value.toString().includes('.')) {
      return null;
    }
    return +value.toString().replace(/,/, '.');
  }).typeError("Deve ser informado um valor")
  .required('Dias de alerta de retorno  é um dado obrigatório'),
  dias_alerta_despesa :  yup
  .number().transform((_, value) => {
      
    if (value.toString().includes('.')) {
      return null;
    }
    return +value.toString().replace(/,/, '.');
  }).typeError("Deve ser informado um valor")
  .required('Dias de alerta de despesas é um dado obrigatório'),
  pontos_por_real :  yup
  .number().transform((_, value) => {
      
    if (value.toString().includes('.')) {
      return null;
    }
    return +value.toString().replace(/,/, '.');
  }).typeError("Deve ser informado um valor")
  .required('Ponto por real  é um dado obrigatório'),
  expiracao_ponto :  yup
  .string('Informe se os ponto expiram')
  .required('Expiração é um dado obrigatório').typeError("Deve ser informado um status"),
     
});

export default function FixedPlugin(props) {

  const formik = useFormik({
    initialValues: {
/*           cod_configuracao_operacional : mode === 'view' || mode === 'edit' ? teste.cod_configuracao_operacional : '',
          cod_estabelecimento : mode === 'view' || mode === 'edit' ? teste.cod_estabelecimento : '',
          saudacao_alerta_retorno : mode === 'view' || mode === 'edit' ? teste.saudacao_alerta_retorno : '',
          mensagem_alerta_retorno : mode === 'view' || mode === 'edit' ? teste.mensagem_alerta_retorno : '',
          nome_alerta_retorno : mode === 'view' || mode === 'edit' ? teste.nome_alerta_retorno : '',
          saudacao_alerta_aniversario : mode === 'view' || mode === 'edit' ? teste.saudacao_alerta_aniversario : '',
          mensagem_alerta_aniversario : mode === 'view' || mode === 'edit' ? teste.mensagem_alerta_aniversario : '',
          nome_alerta_aniversario : mode === 'view' || mode === 'edit' ? teste.nome_alerta_aniversario : '',
          desconto_debito : mode === 'view' || mode === 'edit' ? teste.desconto_debito : '',
          desconto_credito : mode === 'view' || mode === 'edit' ? teste.desconto_credito : '',
          dias_alerta_retorno : mode === 'view' || mode === 'edit' ? teste.dias_alerta_retorno : '',
          dias_alerta_despesa : mode === 'view' || mode === 'edit' ? teste.dias_alerta_despesa : '',
          pontos_por_real : mode === 'view' || mode === 'edit' ? teste.pontos_por_real : '',
          expiracao_ponto : mode === 'view' || mode === 'edit' ? teste.complemento : '' */

 
          cod_estabelecimento :'',
          saudacao_alerta_retorno : '',
          mensagem_alerta_retorno : '',
          nome_alerta_retorno :  '',
          saudacao_alerta_aniversario :  '',
          mensagem_alerta_aniversario : '',
          nome_alerta_aniversario :  '',
          desconto_debito :  '',
          desconto_credito :  '',
          dias_alerta_retorno : '',
          dias_alerta_despesa :  '',
          pontos_por_real :  '',
          expiracao_ponto :  ''
     
   }, 
  validationSchema: validationSchema,
   onSubmit: (values) => {
     console.log(values)
     if(mode === "new"){
       criarConfig('configuracoesoperacionais',values)
     }else if(mode === "edit"){
      editarConfig('configuracoesoperacionais',values)
     } 
   },
 });

  const [classes, setClasses] = React.useState("dropdown show");
  const [bg_checked, setBg_checked] = React.useState(true);
  const [bgImage, setBgImage] = React.useState(props.bgImage);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnak] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [message,setMessage] = useState('')
  
  const [estabelecimentos,setEstabelecimentos] = useState([])
  const [configEstabelecimento,setConfigEstabelecimento] = useState([])
  const [codEstabelecimento,setCodEstabelecimento] = useState([])
  const [mode,setMode] = useState('')


  useEffect(()=>{
    ApiConnectorTemplate.list('estabelecimento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      console.log(r)
      setEstabelecimentos(r)
    })
  },[]) 

  
  const handleCloseSnack = () => {
    setOpenSnak(false);
  };

  const handleConfig = async(e) => {
    console.log(e.target.value)
    ApiConnectorTemplate.list('configuracoesoperacionais',{cod_estabelecimento:e.target.value}).then(r=>{
      console.log(r)
      if(r.length == 0){
        setMode('new')
        formik.setFieldValue('cod_estabelecimento',e.target.value)
        formik.setFieldValue('desconto_credito',"")
        formik.setFieldValue('desconto_debito',"")
        formik.setFieldValue('dias_alerta_despesa',"")
        formik.setFieldValue('dias_alerta_retorno',"")
        formik.setFieldValue('expiracao_ponto',"")
        formik.setFieldValue('mensagem_alerta_aniversario',"")
        formik.setFieldValue('mensagem_alerta_retorno',"")
        formik.setFieldValue('nome_alerta_aniversario',"")
        formik.setFieldValue('nome_alerta_retorno',"")
        formik.setFieldValue('pontos_por_real',"")
        formik.setFieldValue('saudacao_alerta_aniversario',"")
        formik.setFieldValue('saudacao_alerta_retorno',"")
      }else{
        setMode('edit')
          formik.setFieldValue('cod_estabelecimento',r[0].cod_estabelecimento)
          formik.setFieldValue('desconto_credito',r[0].desconto_credito)
          formik.setFieldValue('desconto_debito',r[0].desconto_debito)
          formik.setFieldValue('dias_alerta_despesa',r[0].dias_alerta_despesa)
          formik.setFieldValue('dias_alerta_retorno',r[0].dias_alerta_retorno)
          formik.setFieldValue('expiracao_ponto',r[0].expiracao_ponto)
          formik.setFieldValue('mensagem_alerta_aniversario',r[0].mensagem_alerta_aniversario)
          formik.setFieldValue('mensagem_alerta_retorno',r[0].mensagem_alerta_retorno)
          formik.setFieldValue('nome_alerta_aniversario',r[0].nome_alerta_aniversario)
          formik.setFieldValue('nome_alerta_retorno',r[0].nome_alerta_retorno)
          formik.setFieldValue('pontos_por_real',r[0].pontos_por_real)
          formik.setFieldValue('saudacao_alerta_aniversario',r[0].saudacao_alerta_aniversario)
          formik.setFieldValue('saudacao_alerta_retorno',r[0].saudacao_alerta_retorno)

      }
    
    }) 
  };



  async function criarConfig(componente,payload){
  
    try{
      const response = await ApiConnectorTemplate.add(componente,payload)
   
      console.log(response)
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Configurações Criadas')
      
      //handleClose()
      
    }catch(error){
      console.log(error)
    }
  }

  async function editarConfig(componente,payload){
  
   
     try{
      const response = await ApiConnectorTemplate.update(componente,payload)
   
      console.log(response)
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Configuraçoes Editadas')
      
      //handleClose()
      
    }catch(error){
      console.log(error)
    } 
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setMode('')
    formik.setFieldValue('cod_estabelecimento',"")
    formik.setFieldValue('desconto_credito',"")
    formik.setFieldValue('desconto_debito',"")
    formik.setFieldValue('dias_alerta_despesa',"")
    formik.setFieldValue('dias_alerta_retorno',"")
    formik.setFieldValue('expiracao_ponto',"")
    formik.setFieldValue('mensagem_alerta_aniversario',"")
    formik.setFieldValue('mensagem_alerta_retorno',"")
    formik.setFieldValue('nome_alerta_aniversario',"")
    formik.setFieldValue('nome_alerta_retorno',"")
    formik.setFieldValue('pontos_por_real',"")
    formik.setFieldValue('saudacao_alerta_aniversario',"")
    formik.setFieldValue('saudacao_alerta_retorno',"")
    setOpen(false);
    
  };

  const handleClick = () => {
    props.handleFixedClick();
  };
  return (
    <div
      className={classnames("fixed-plugin", {
        "rtl-fixed-plugin": props.rtlActive
      })}
    >
      <div id="fixedPluginClasses" className={props.fixedClasses}>
        <div onClick={handleClick}>
          <i className="fa fa-cog fa-2x" />
        </div>
        <ul className="dropdown-menu">
        <li className="header-title">Configurações Gerais do Sistema</li>
        <li className="adjustments-line">
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Abrir Configurações
      </Button>
      <Dialog 
          onClose={handleClose} 
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth={'xl'}
          >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <GridContainer >
        <GridItem xs={12} sm={12} md={6}>
          Configurações Gerais
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
              <InputLabel htmlFor="select-estabelecimento" style={{fontSize: "12px",marginBottom: "4px"}}>Estabelecimento</InputLabel>
                        <Select
                          onChange={handleConfig}
                          fullWidth
                          labelId="select-estabelecimento"
                          id="cod_estabelecimento"
                          name="cod_estabelecimento"
                        >
                        {estabelecimentos.map(estabelecimento=>
                              <MenuItem key={estabelecimento.cod_estabelecimento} value={estabelecimento.cod_estabelecimento}>{estabelecimento.estabelecimento}</MenuItem>
                          )}
                      </Select>
              </GridItem>
        </GridContainer>
        </DialogTitle>
        <DialogContent dividers style={{padding:'0px 0px 0px 0px'}}>

        <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab wrapped label="WhatsApp (Mensagem retorno)" {...a11yProps(0)} />
          <Tab wrapped label="WhatsApp (Mensagem de Aniversário)" {...a11yProps(1)} />
          <Tab wrapped label="Configurações Gerais" {...a11yProps(2)} />

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <GridContainer >
        <GridItem xs={12} sm={12} md={12}>
          <h4 style={{marginTop:'5px',marginBottom:'5px'}}>Alerta de Retorno</h4>
          <TextField
            fullWidth
            id="saudacao_alerta_retorno"
            name="saudacao_alerta_retorno"
            value={formik.values.saudacao_alerta_retorno}
            onChange={formik.handleChange}
            error={formik.touched.saudacao_alerta_retorno && Boolean(formik.errors.saudacao_alerta_retorno)}
            helperText={formik.touched.saudacao_alerta_retorno && formik.errors.saudacao_alerta_retorno}
            label="Saudação"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel control={<Checkbox checked={formik.values.nome_alerta_retorno == "true" || formik.values.nome_alerta_retorno  == true ? true:false} size='small' id="nome_alerta_retorno" name="nome_alerta_retorno"  onChange={formik.handleChange}/>} label="Incluir Nome do Cliente" />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <TextField
         style={{marginTop:'20px'}}
            fullWidth
            id="mensagem_alerta_retorno"
            name="mensagem_alerta_retorno"
            value={formik.values.mensagem_alerta_retorno}
            onChange={formik.handleChange}
            error={formik.touched.mensagem_alerta_retorno && Boolean(formik.errors.mensagem_alerta_retorno)}
            helperText={formik.touched.mensagem_alerta_retorno && formik.errors.mensagem_alerta_retorno}
            label="Mensagem"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <TextField
            style={{marginTop:'20px'}}
            fullWidth
            id="previwalerta"
            name="previewalerta"
            label="Pré -Visualização"
            value={`${formik.values.saudacao_alerta_retorno},${formik.values.mensagem_alerta_retorno}`}
            multiline
            disabled= {true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
      </GridContainer >
      </TabPanel>
      <TabPanel value={value} index={1}>
      <GridContainer >
        <GridItem xs={12} sm={12} md={12}>
          <h4 style={{marginTop:'5px',marginBottom:'5px'}}>Aniversariante</h4>
          <TextField
            fullWidth
            id="saudacao_alerta_aniversario"
            name="saudacao_alerta_aniversario"
            value={formik.values.saudacao_alerta_aniversario}
            onChange={formik.handleChange}
            error={formik.touched.saudacao_alerta_aniversario && Boolean(formik.errors.saudacao_alerta_aniversario)}
            helperText={formik.touched.saudacao_alerta_aniversario && formik.errors.saudacao_alerta_aniversario}
            label="Saudação"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel control={<Checkbox checked={formik.values.nome_alerta_aniversario == "true" || formik.values.nome_alerta_aniversario  == true ? true:false} size='small'  name="nome_alerta_aniversario"  onChange={formik.handleChange}/>} label="Incluir Nome do Cliente" />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <TextField
         style={{marginTop:'20px'}}
            fullWidth
            id="mensagem_alerta_aniversario"
            name="mensagem_alerta_aniversario"
            value={formik.values.mensagem_alerta_aniversario}
            onChange={formik.handleChange}
            error={formik.touched.mensagem_alerta_aniversario && Boolean(formik.errors.mensagem_alerta_aniversario)}
            helperText={formik.touched.mensagem_alerta_aniversario && formik.errors.mensagem_alerta_aniversario}
            label="Mensagem"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <TextField
            style={{marginTop:'20px'}}
            fullWidth
            id="previewaniversario"
            name="previewaniversario"
            label="Pré -Visualização"
            value={`${formik.values.saudacao_alerta_aniversario},${formik.values.mensagem_alerta_aniversario}`}
            multiline
            disabled= {true}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
      </GridContainer >
      </TabPanel>
      <TabPanel value={value} index={2}>
        <h6 style={{marginTop:'5px',marginBottom:'5px'}}>Taxa de vendacom cartão</h6>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <TextField
            disabled
                  style={{marginTop:'20px'}}
                  fullWidth
                  value="Descontar taxa de venda por cartão"
                  label="Crédito"
                  InputLabelProps={{
                    shrink: true,
                  }}
     
              />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
              <TextField
                  style={{marginTop:'20px'}}
                  fullWidth
                  id="desconto_credito"
                  name="desconto_credito"
                  value={formik.values.desconto_credito}
                  onChange={formik.handleChange}
                  error={formik.touched.desconto_credito && Boolean(formik.errors.desconto_credito)}
                  helperText={formik.touched.desconto_credito && formik.errors.desconto_credito}
                  label="%"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
            <TextField
            disabled
                  style={{marginTop:'20px'}}
                  fullWidth
                  value="Descontar taxa de venda por cartão"
                  label="Débito"
                  InputLabelProps={{
                    shrink: true,
                  }}
     
              />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
              <TextField
                  style={{marginTop:'20px'}}
                  fullWidth
                  id="desconto_debito"
                  name="desconto_debito"
                  value={formik.values.desconto_debito}
                  onChange={formik.handleChange}
                  error={formik.touched.desconto_debito && Boolean(formik.errors.desconto_debito)}
                  helperText={formik.touched.desconto_debito && formik.errors.desconto_debito}
                  label="%"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </GridItem>
        </GridContainer>
        <h6 style={{marginTop:'5px',marginBottom:'5px'}}>Alertas</h6>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <TextField
            disabled
                  style={{marginTop:'20px'}}
                  fullWidth
                  value="O alerta aparece quantos dias antes do prazo estipulado"
                  label="Retorno"
                  InputLabelProps={{
                    shrink: true,
                  }}
     
              />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
              <TextField
                  style={{marginTop:'20px'}}
                  fullWidth
                  id="dias_alerta_retorno"
                  name="dias_alerta_retorno"
                  value={formik.values.dias_alerta_retorno}
                  onChange={formik.handleChange}
                  error={formik.touched.dias_alerta_retorno && Boolean(formik.errors.dias_alerta_retorno)}
                  helperText={formik.touched.dias_alerta_retorno && formik.errors.dias_alerta_retorno}
                  label="Dias"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
            <TextField
            disabled
                  style={{marginTop:'20px'}}
                  fullWidth
                  value="O alerta aparece quantos dias antes do prazo estipulado"
                  label="Despesas"
                  InputLabelProps={{
                    shrink: true,
                  }}
     
              />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
              <TextField
                  style={{marginTop:'20px'}}
                  fullWidth
                  id="dias_alerta_despesa"
                  name="dias_alerta_despesa"
                  value={formik.values.dias_alerta_despesa}
                  onChange={formik.handleChange}
                  error={formik.touched.dias_alerta_despesa && Boolean(formik.errors.dias_alerta_despesa)}
                  helperText={formik.touched.dias_alerta_despesa && formik.errors.dias_alerta_despesa}
                  label="Dias"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </GridItem>
        </GridContainer>
        <h6 style={{marginTop:'5px',marginBottom:'5px'}}>Programa de Pontos</h6>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <TextField
            disabled
                  style={{marginTop:'20px'}}
                  fullWidth
                  value="A cada R$ 1,00 gasto com serviço , o cliente acumula : "
                  label="Pontos"
                  InputLabelProps={{
                    shrink: true,
                  }}
     
              />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
              <TextField
                  style={{marginTop:'20px'}}
                  fullWidth
                  id="pontos_por_real"
                  name="pontos_por_real"
                  value={formik.values.pontos_por_real}
                  onChange={formik.handleChange}
                  error={formik.touched.pontos_por_real && Boolean(formik.errors.pontos_por_real)}
                  helperText={formik.touched.pontos_por_real && formik.errors.pontos_por_real}
                  label="Pontos"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
            <TextField
            disabled
                  style={{marginTop:'20px'}}
                  fullWidth
                  value="Os acumulados expiram no fim do ano ?"
                  label="Pontos"
                  InputLabelProps={{
                    shrink: true,
                  }}
     
              />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
          <InputLabel htmlFor="select-resetpontos" style={{fontSize: "12px",marginBottom: "4px",marginTop:"20px"}}>Situação</InputLabel>
                          <Select
                            fullWidth
                            labelId="select-resetpontos"
                            id="expiracao_ponto"
                            name="expiracao_ponto"
                            value={formik.values.expiracao_ponto}
                            onChange={formik.handleChange}
                            error={formik.touched.expiracao_ponto && Boolean(formik.errors.expiracao_ponto)}
                            helperText={formik.touched.expiracao_ponto && formik.errors.expiracao_ponto}
                          >
                                <MenuItem  value={0}>Sim</MenuItem>
                                <MenuItem  value={1}>Não</MenuItem>
                        </Select>
            </GridItem>
        </GridContainer>

      </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button style={{display: mode == '' ? 'none':'block'}} autoFocus onClick={formik.handleSubmit} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>          
        </li>
          <li className="header-title">Cores do Menu</li>
          <li className="adjustments-line">
            <a className="switch-trigger">
              <div>
                <span
                  className={
                    props.bgColor === "purple"
                      ? "badge filter badge-purple active"
                      : "badge filter badge-purple"
                  }
                  data-color="purple"
                  onClick={() => {
                    props.handleColorClick("purple");
                  }}
                />
                <span
                  className={
                    props.bgColor === "blue"
                      ? "badge filter badge-blue active"
                      : "badge filter badge-blue"
                  }
                  data-color="blue"
                  onClick={() => {
                    props.handleColorClick("blue");
                  }}
                />
                <span
                  className={
                    props.bgColor === "green"
                      ? "badge filter badge-green active"
                      : "badge filter badge-green"
                  }
                  data-color="green"
                  onClick={() => {
                    props.handleColorClick("green");
                  }}
                />
                <span
                  className={
                    props.bgColor === "red"
                      ? "badge filter badge-red active"
                      : "badge filter badge-red"
                  }
                  data-color="red"
                  onClick={() => {
                    props.handleColorClick("red");
                  }}
                />
                <span
                  className={
                    props.bgColor === "orange"
                      ? "badge filter badge-orange active"
                      : "badge filter badge-orange"
                  }
                  data-color="orange"
                  onClick={() => {
                    props.handleColorClick("orange");
                  }}
                />
              </div>
            </a>
          </li>
          <li className="header-title">Imagens de Fundo do Menu</li>
          <li className={bgImage === imagine9 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine9);
                props.handleImageClick(imagine9);
              }}
            >
              <img src={imagine9} alt="..." />
            </a>
          </li>
          <li className={bgImage === imagine8 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine8);
                props.handleImageClick(imagine8);
              }}
            >
              <img src={imagine8} alt="..." />
            </a>
          </li>
          <li className={bgImage === imagine7 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine3);
                props.handleImageClick(imagine7);
              }}
            >
              <img src={imagine7} alt="..." />
            </a>
          </li>
          <li className={bgImage === imagine6 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine4);
                props.handleImageClick(imagine6);
              }}
            >
              <img src={imagine6} alt="..." />
            </a>
          </li>
          <li className={bgImage === imagine5 ? "active" : ""}>
            <a
              className="img-holder switch-trigger"
              onClick={() => {
                setBgImage(imagine5);
                props.handleImageClick(imagine5);
              }}
            >
              <img src={imagine5} alt="..." />
            </a>
          </li>
          <li className="adjustments-line" />
        </ul>
      </div>
      <Portal>
        <Snackbar
          open={openSnack}
          onClose={handleCloseSnack}
          //TransitionComponent={transition}
          message={message}
          //key={transition ? transition.name : ''}
          
        />
      </Portal>
    </div>
  );
}

FixedPlugin.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  rtlActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func
};
