import React ,{useState,useEffect}from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinkIcon from '@material-ui/icons/Link';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import SearchIcon from '@material-ui/icons/Search';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

 

import moment from "moment";
import "moment/locale/pt-br";

import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'





const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardRecipient:{
    height:"calc(100vh - 180px)"
  }
};

const useStyles = makeStyles(styles);

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

export default function ListaBarbearia({handleChangeView}) {
  const componente = 'estabelecimento'
  const classes = useStyles();

  const [listaBarbearia,setLista] = useState([])
  const [listaFiltrada,setListaFiltrada] = useState([])
  const [loading,setLoading] = useState(true)
  const [openDelete, setopenDelete] = useState({action:false,referencia:"",message:""});
  const [updateList,setUpdatelist]  = useState(0)
  const [termos,setTermos] = useState('')
  const [open, setOpen] = useState(false);
  const [urlLink,setUrlLink] = useState('')

  const [openSnack, setOpenSnack] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [message,setMessage] = useState('')
  



  useEffect(()=>{
    setLoading(true)
    ApiConnectorTemplate.list(componente,{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
     
      setLista(r)
      setListaFiltrada(r)
      setLoading(false)
    })
  },[updateList])

  function handleView(id){
    handleChangeView(1,listaBarbearia.reduce((p,a)=>a.cod_estabelecimento === id? a:p,{}),'view')
  }

  function handleUrl(id){
      console.log(listaBarbearia.reduce((p,a)=>a.cod_estabelecimento === id? a.cod_link:p,{}))
      let codLink = listaBarbearia.reduce((p,a)=>a.cod_estabelecimento === id? a.cod_link:p,{})
      let urlDev = `http://localhost:9000/${codLink}`
      let url = `https://agendamento.systembarber.com/${codLink}`

      console.log(urlDev)
      console.log(url)
      setUrlLink(url)
      setOpen(true);
  }

  function handleEdit(id){
    handleChangeView(1,listaBarbearia.reduce((p,a)=>a.cod_estabelecimento === id? a:p,{}),'edit')
  }

  function handleCreate(){
    handleChangeView(1,{},'create')
  }

  function handleConfimDelete(referencia){
  
     ApiConnectorTemplate.delete(componente,referencia).then(r=>{
      closeDelete()
      setUpdatelist(updateList + 1)
    }) 
  }


  function handleResetPass(referencia){
    console.log(referencia)
    setLoading(true);
    let usuario = listaBarbearia.reduce((p,a)=>a.cod_estabelecimento === referencia? a.usuario:p,null)
     ApiConnectorTemplate.update('resetpassword',{usuario:usuario})
    .then(r=>{
        console.log('resetou')
        setTransition(() => TransitionLeft);
        setOpenSnack(true);
        setLoading(false);
        setMessage('Senha resetada com sucesso')
    })
    .catch(error=>{
      console.log('nao resetou')
      setTransition(() => TransitionLeft);
      setOpenSnack(true);
      setLoading(false);
      setMessage('Não foi possivel resetar a senha')
    }) 
  }

  function handleDelete(id){
    let item = listaBarbearia.reduce((p,a)=>a.cod_estabelecimento === id ? `${a.estabelecimento} - ${a.nome_fantasia}`:p,{})
    
    setopenDelete({action:true,referencia:id,message:item})
  }

  function closeDelete(){
    setopenDelete({action:false,referencia:"",message:""})
  }

  function buildActions(id){
    return <div  style={{textAlign:"right"}}>
    {/* <IconButton size="small" color="secondary" aria-label="delete item" onClick={()=>handleDelete(id)}>
      <DeleteIcon />
    </IconButton> */}
    {localStorage.getItem('perfil') == 1 ?
    <IconButton size="small" color="primary" aria-label="reset item" onClick={()=>handleResetPass(id)} >
      <ThreeSixtyIcon  />
    </IconButton> : null}
    <IconButton size="small" color="primary" aria-label="edit item" onClick={()=>handleEdit(id)}>
      <EditIcon />
    </IconButton>
    <IconButton size="small" color="primary" aria-label="view item" onClick={()=>handleView(id)}>
      <VisibilityIcon />
    </IconButton>
    <IconButton size="small" color="primary" aria-label="link item" onClick={()=>handleUrl(id)} >
      <LinkIcon />
    </IconButton>
  </div>
  }

  function termosDePesquisa(e){
    setTermos(e.target.value)
  }

  function Search(){
    console.log('buscando')
    let keys = termos.split(' ');
    let lista = [...listaBarbearia];

    lista = lista.filter(item=>{
        /* let bodyConsulta = item.nome */
        let str = JSON.stringify(item);
        return keys.reduce((p,a)=> str.toUpperCase().indexOf(a.toUpperCase()) >=0 && p !== false,null);
        //return str.toUpperCase().indexOf(filtro.toUpperCase()) >=0;
    })

    console.log(lista)
    setListaFiltrada(lista)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleCopyUrl = ()=>{
    navigator.clipboard.writeText(urlLink);
    setOpen(false);
  }


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.cardRecipient}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Barbearias Cadastradas</h4>
            <p className={classes.cardCategoryWhite}>
              Edição e Exclusão de estabelecimentos
            </p>
          </CardHeader>
          <CardBody>
             <div>
              {loading ? <LinearProgress style={{marginBottom:"10px"}}/> : null }
              
              {localStorage.getItem('perfil') == '1' ? 
              <Fab style={{right:10,position:'absolute',background:"black"}} size="medium" color="primary" aria-label="add" onClick={handleCreate}>
                <AddIcon />
              </Fab> : null}
            </div> 
            <GridContainer >
              <GridItem xs={12} sm={12} md={8} style={{marginTop:"40px"}}>
                <TextField fullWidth  id="standard-basic" label="Filtre por informações do cadastro"  onChange={(e)=>termosDePesquisa(e)}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{marginTop:"40px"}}>
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={Search}>
                  <SearchIcon />
                </IconButton>
              </label>
              </GridItem>
            </GridContainer>
      
            <Table
              tableHeaderColor="primary"
              tableHead={["Estabelecimento", "Responsável/Proprietário", "Cidade/UF", "Validade",""]}
              tableData={
              
                listaFiltrada.map(barbearia=>{
                return  [barbearia.estabelecimento, barbearia.nome_fantasia, `${barbearia.cidade}-${barbearia.uf}`,moment(barbearia.data_validade_licenca).format("DD/MM/YYYY"), buildActions(barbearia.cod_estabelecimento)]
              })
            }
            />
          </CardBody>

        </Card>
      </GridItem>
      <Drawer anchor={"bottom"} open={openDelete.action} onClose={closeDelete} >
        <div >
          <List component="nav" aria-label="main mailbox folders">
              <ListItem >
               <h4> Você deseja realmente remover {openDelete.message} da lista ?</h4>
              <Fab style={{right:60,position:'fixed'}} size="medium" color="secondary" aria-label="add" onClick={()=>handleConfimDelete(openDelete.referencia)}>
                <DeleteIcon />
              </Fab> 
              </ListItem>
          </List>
          
        </div>
      </Drawer>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle id="alert-dialog-title">{`URL de Agendamento`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <p> {urlLink}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
            <Button onClick={handleCopyUrl} color="primary">
              Copiar
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />
      
    </GridContainer>
  );
}






