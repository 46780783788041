
import React,{useState,useEffect}from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import Servicos from "views/Servicos/Servicos"
import ServicosLista from "views/Servicos/ServicosLista"

// core components

export default function ProfissionalGestao() {
  const [value, setValue] = useState(0)
  const [servicoSelecionado,setServico] = useState({})
  const [modeServico,setMode] = useState("create")

  const handleChangeView  = (visao,payload,mode)=>{
    setServico(payload)
    setMode(mode)
    setValue(1) 
  }

  if(localStorage.getItem('cod_estabelecimento') === 'null'){
    return <Redirect to="/login"  />
 } 

  return (
    <div>
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        if(newValue == 1 ){
        }else{
          setValue(newValue);
        }
      }}
      showLabels
    >
      <BottomNavigationAction label="Serviços" icon={<PlaylistAddSharpIcon />} />
    </BottomNavigation>
    {value === 0 ? (<ServicosLista handleChangeView={handleChangeView}/>):(<Servicos mode={modeServico} teste={servicoSelecionado}/>)}
    </div>
  );
}