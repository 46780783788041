
import React,{useState,useEffect}from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import BottomNavigation from '@material-ui/core/BottomNavigation';

import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import Acertos from "views/Acertos/Acertos"
import AcertosLista from "views/Acertos/AcertosLista"


// core components

export default function AcertoGestao() {
  const [value, setValue] = useState(0)
  const [profissionalSelecionado,setProfissional] = useState({})
  const [modeAcerto,setMode] = useState("create")

  const handleChangeView  = (visao,payload,mode)=>{
    setProfissional(payload)
    setMode(mode)
    setValue(1) 
  }

  if(localStorage.getItem('cod_estabelecimento') == 'null'){
    return <Redirect to="/login"  />
 } 

  return (
    <div>
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        if(newValue == 1 ){
        }else{
          setValue(newValue);
        }
      }}
      showLabels
    >
      <BottomNavigationAction label="Acertos" icon={<PlaylistAddSharpIcon />} />
    </BottomNavigation>
    {value === 0 ? (<AcertosLista handleChangeView={handleChangeView}/>):(<Acertos mode={modeAcerto} teste={profissionalSelecionado}/>)}
    </div>
  );
}