import React,{useState,useEffect}from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import IconButton from '@material-ui/core/IconButton';
import TableCustom from "components/Table/Table.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'
import { Formik, useFormik } from 'formik';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import * as yup from 'yup';
import { Fragment } from "react";
import ListaCliente from "views/Cliente/ClienteLista";
import { Search } from "@material-ui/icons";


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,

    position: 'relative',
    minHeight: 500,
    paddingLeft:0,
    paddingRight:0 
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

const validationSchema = yup.object({
/*
  cod_cliente: yup
  .string('Informe o Cliente')
  .required('Cliente é um dado obrigatório'),
   cod_profissional: yup
  .string('Informe o Profissional')
  .required('Profissional é um dado obrigatório'),
  cod_produto:  yup
  .string('Informe o Produto')
  .required('Produto é um dado obrigatório'),
  cod_comissao: yup
  .string('Informe a Comissao Fixa')
  .required('Comissao Fixa é um dado obrigatório'),
  quantidade_produto: yup
  .string('Informe a Quantidade de Produto')
  .required('Quantidade de Produto é um dado obrigatório'),
  valor_unitario_produto:  yup
  .string('Informe o Valor Unitário do Produto')
  .required('Valor Unitário do Produto é um dado obrigatório'),
  valor_venda_produto: yup
  .string('Informe o Valor de Venda')
  .required('Valor de Venda é um dado obrigatório'),
  cod_servico:  yup
  .string('Informe o Serviço')
  .required('Serviço é um dado obrigatório'),
  cod_comissao_servico:  yup
  .string('Informe a Comissão de Serviço')
  .required('Comissão de Serviço é um dado obrigatório'),
  valor_venda_servico: yup
  .string('Informe o Valor de Venda')
  .required('Valor de Vendaé um dado obrigatório'), */
  
   
        
});

export default function ComandaContainer(TransitionLeft) {
  const classes = useStyles();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
     cod_cliente:  '',
     cod_profissional:'',
     cod_servico: '',
     cod_comissao_servico:'',
     valor_venda_servico: '',
     cod_produto: '',
     comissao_fixa_produto:'',
     quantidade_produto:'',
     valor_unitario_produto: '',
     valor_venda_produto:'',
     valor_total_comanda:'',
     data_de_pagamento: "",
     cod_meio_pagamento: ""

   }, 
   validationSchema: validationSchema,
   onSubmit: (values) => {
    console.log(values)
   },
   validateOnChange:false,
   validateOnBlur:false
 });

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnak] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [message,setMessage] = useState('')

  const [resetForm,setResetForm] = useState(1)
  const [listaFiltrada,setListaFiltrada] = useState([])
  const [listaFiltradaFaturamento,setListaFiltradaFaturamento] = useState([])
  const [listaFiltradaDespesa,setListaFiltradaDespesa] = useState([])
  const [totalFaturamento,setTotalFaturamento] = useState(0)
  const [lucroRealPercentual,setLucroRealPercentual] = useState(0)
  const [lucroRealReais,setLucroRealReais] = useState(0)

  const [totalDespesa,setTotalDespesa] = useState(0)

  const [dataInicialPeriodo,setDataIncial] = useState()
  const [dataFinalPeriodo,setDataFinal] = useState()
  const [tipoDeFiltro,setTipoDeFiltro] = useState(0)
  const [filtrarPor,setFiltrarPor] = useState(0)
  const [filtrarNome,setFiltrarNome] = useState('')


 


        useEffect(()=>{
/*             if(open == true){
                Promise.all([
                 
                    ApiConnectorTemplate.list('produtos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                    ApiConnectorTemplate.list('servicos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                    ApiConnectorTemplate.list('comissoes',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                    ApiConnectorTemplate.list('mododepagamento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                ])
                .then(values=>{
                    console.log(values)

                    setListaProdutos(values[0])
                    setListaServicos(values[1])
                    setListaComissao(values[2])
                    setListaMeioPagamento(values[3])
                })
                .catch(error=>{
                    console.log(error)
                })
            } */
        },[])




      
      useEffect(()=>{
        console.log(formatDate())
        let data = formatDate()
        setDataIncial(data)
        setDataFinal(data)
        
        
    },[])






  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatDate = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


const formatDateSeteDias = () => {
  var d = new Date();
  d.setDate(d.getDate()-6);
  var dd = d,
      month = '' + (dd.getMonth() + 1),
      day = '' + dd.getDate(),
      year = dd.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

const formatDateTrintaDias = () => {
  var d = new Date();
 d.setDate(d.getDate()-29);

  var dd = d,
  
      month = '' + (dd.getMonth() + 1),
      day = '' + dd.getDate(),
      year = dd.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

const formatDate2 = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}


const FiltroDePerido = (periodo)=>{
  console.log('filtrandoData',periodo)
    let dataInicio = ''
    let dataFim = ''
    if(periodo == 1){
      dataInicio = formatDateSeteDias()
      dataFim =  formatDate()
    }else if (periodo == 2){
      dataInicio = formatDateTrintaDias()
      dataFim =  formatDate()
    }

    console.log(dataInicio,dataFim)
    setDataIncial(dataInicio)
    setDataFinal(dataFim)
    setTipoDeFiltro(periodo)
}
  





  const handleCloseSnack = () => {
    setOpenSnak(false);
  };


  const handleSearchRelatorio=()=>{
    if(dataInicialPeriodo && dataFinalPeriodo){
      ApiConnectorTemplate.list('balanco',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),di:dataInicialPeriodo,df:dataFinalPeriodo}).then(results=>{
     
        let cleaner = results.filter(x=> x.valor !== null)
        let listaFaturamento = cleaner.filter(x=>x.tipo == 'FATURAMENTO')
        let listaDespesas =  cleaner.filter(x=>x.tipo == 'DESPESA')
        let valorDespesas = listaDespesas.reduce(function(acc, val) { return acc + val.valor; }, 0)
        let valorFaturado = listaFaturamento.reduce(function(acc, val) { return acc + val.valor; }, 0)
        let lucroRealReais = (valorFaturado - valorDespesas)
        let lucroRealPercentual = ((lucroRealReais / valorFaturado)*100)
       
        setListaFiltradaDespesa(listaDespesas)
        setListaFiltradaFaturamento(listaFaturamento)
        setTotalDespesa(valorDespesas)
        setTotalFaturamento(valorFaturado)
        setLucroRealReais(lucroRealReais)
        if(lucroRealPercentual)
        setLucroRealPercentual(lucroRealPercentual)
      })

    }else{
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Selecione um período válido para pesquisar')
    }
  }



 




  if(localStorage.getItem('cod_estabelecimento') == 'null'){
    return <Redirect to="/login"  />
  } 


  return (
    <div className={classes.root} style={{paddingLeft:'0px',paddingRight:'0px'}}>
      <AppBar position="static" color="default">
        <Tabs
          style={{paddingLeft:'0px',paddingRight:'0px'}}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <Tab label="Balanço do Estabelecimento" {...a11yProps(0)} />
{/*           <Tab label="Despesas" {...a11yProps(1)} />
          <Tab label="Estoque" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
         {/*  Aqui será paresentado o relatório de consulta do Lançamento */}
          <GridContainer >
              <GridItem xs={12} sm={12} md={2}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt-br"}>
                    <DatePicker
                     
                      fullWidth
                      InputLabelProps={{
                                        shrink: true,
                                      }}
                      id="dataInicial"
                      label="Data Inicial"
                      format="DD/MM/YYYY"
                      value={dataInicialPeriodo}
                      onChange = {date=> setDataIncial(formatDate2(date._d))}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt-br"}>
                    <DatePicker
                     
                      fullWidth
                      InputLabelProps={{
                                        shrink: true,
                                      }}
                      id="dataFinal"
                      label="Data Final"
                      format="DD/MM/YYYY"
                      value={dataFinalPeriodo}
                      onChange = {date=> setDataFinal(formatDate2(date._d))}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <InputLabel htmlFor="select-tipode" style={{fontSize: "12px",marginBottom: "4px"}}>Tipo De Lançamento :</InputLabel>
                          <Select
                           
                            fullWidth
                            labelId="select-tipode"
                            id="tipode"
                            name="tipode"
                            value={tipoDeFiltro}
                            onChange={(e)=>FiltroDePerido(e.target.value)}

                          >
                                <MenuItem  value={0}>Personalizado</MenuItem>
                                <MenuItem  value={1}>Últimos 7 dias</MenuItem>
                                <MenuItem  value={2}>Últimos 30 dias</MenuItem>
                                <MenuItem  value={3}>Mês corrente</MenuItem>
                                <MenuItem  value={4}>Mês anterior</MenuItem>
                        </Select>
                </GridItem>


                <IconButton size="small" color="primary" aria-label="edit item" onClick={handleSearchRelatorio}>
                        <SearchIcon />
                </IconButton>   
                </GridContainer>
                <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <p>Despesa no período</p>
                    <Scrollbars style={{ height: 230 ,marginTop:'30px'}}>
                      <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{minWidth:150,fontSize:10,padding:0}}>Item</TableCell>
                            <TableCell style={{minWidth:200,fontSize:10,padding:0}}>Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listaFiltradaDespesa.map((row,key) => (
                            <TableRow key={key} style={{fontSize:'10px'}}>
                              <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{row.item}</TableCell>
                              <TableCell  style={{minWidth:200,fontSize:10,padding:0}}>R${row.valor.toFixed(2).replace(".",",")}</TableCell>
                            </TableRow>
                          ))}
                          
                        </TableBody>
                    </Table>
                    </Scrollbars > 
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <p>Faturamento no período</p>
                <Scrollbars style={{ height: 230 ,marginTop:'30px'}}>
                      <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{minWidth:150,fontSize:10,padding:0}}>Item</TableCell>
                            <TableCell style={{minWidth:200,fontSize:10,padding:0}}>Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listaFiltradaFaturamento.map((row,key) => (
                            <TableRow key={key} style={{fontSize:'10px'}}>
                              <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{row.item}</TableCell>
                              <TableCell  style={{minWidth:200,fontSize:10,padding:0}}>R${row.valor.toFixed(2).replace(".",",")}</TableCell>
                            </TableRow>
                          ))}
                          
                        </TableBody>
                    </Table>
                    </Scrollbars > 
                </GridItem>
                </GridContainer>
                <GridContainer>
                  <TextField
                        variant="filled"
                        disabled 
                        label="R$ Total Faturamento"
                        margin="normal"
                        size={'small'}
                        id="total_faturamento"
                        name="total_faturamento"
                        value={totalFaturamento.toFixed(2).replace(".",",")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{width:'200px',padding:'5px'}}
                  />   
                <TextField
                        variant="filled"
                        disabled 
                        label="R$ Total Despesa "
                        margin="normal"
                        size={'small'}
                        id="total_despesa"
                        name="total_despesa"
                        value={totalDespesa.toFixed(2).replace(".",",")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{width:'200px',padding:'5px'}}
                  /> 
                  <TextField
                        variant="filled"
                        disabled 
                        label="Lucro real do Perído em R$"
                        margin="normal"
                        size={'small'}
                        id="lucro_real_reais"
                        name="lucro_real_reais"
                        value={lucroRealReais.toFixed(2).replace(".",",")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{width:'200px',padding:'5px'}}
                  />   
                <TextField
                        variant="filled"
                        disabled 
                        label="Lucro real do Perído em % "
                        margin="normal"
                        size={'small'}
                        id="lucro_real_percentual"
                        name="lucro_real_percentual"
                        value={lucroRealPercentual.toFixed(2).replace(".",",")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{width:'200px',padding:'5px'}}
                  /> 
                </GridContainer>  

        </TabPanel>

      </SwipeableViews>

 
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        //TransitionComponent={transition}
        message={message}
        //key={transition ? transition.name : ''}
      />
    </div>
  );
}
