import React,{useState,useEffect} from "react";
// @material-ui/core components
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
/* import CustomInput from "components/CustomInput/CustomInput.js"; */
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import avatar from "assets/img/logo.png"
// dependencias para lidar com formulário
import { useFormik } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";

// operacoes assincronas

import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  description:{
    textAlign: "justify"
  },
  cardRecipient:{
    height:"calc(100vh - 180px)"
  }

  


  
};






const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  cod_estabelecimento: yup
  .string('Informe o estabelecimento')
  .required('Estabelecimento é um dado obrigatório').typeError("Deve ser informado um Estabelecimento"),
  referencia: yup
  .string('Informe a Descricao')
  .required('Descricao é um dado obrigatório'),

  tipo_origem: yup
  .string('Informe o Tipo')
  .required('O Tipo é um dado obrigatório'),
  quantidade: yup
  .string('Informe o Quantidade')
  .required('A Quantidade é um dado obrigatório'),
     
});


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}


export default function Ponto({mode,teste}) {
 
  const componente = 'pontosporprodutoservico'
  const classes = useStyles();
  const formik = useFormik({
     initialValues: {
      cod_estabelecimento: mode === 'view' || mode === 'edit' ? teste.cod_estabelecimento : '',
      tipo_origem: mode === 'view' || mode === 'edit' ? teste.tipo_origem : '',
      referencia: mode === 'view' || mode === 'edit' ? teste.referencia : '',
      quantidade: mode === 'view' || mode === 'edit' ? teste.quantidade : '',
      complemento: mode === 'view' || mode === 'edit' ? teste.complemento : '',
      
    }, 
   validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
      if(mode === "create"){
        criarPonto(componente,values)
      }else if(mode === "edit"){
        let payload = {...values}
        payload.cod_ponto = teste.cod_ponto
        editarPonto(componente,payload)
      } 
    },
  });


  const [estabelecimentos,setEstabelecimentos] = useState([])
  const [servicos,setServicos] = useState([])
  const [produtos,setProdutos] = useState([])
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [loading,setLoading] = useState(false)
  const [message,setMessage] = useState('')


/*   const handleClick = (Transition) => () => {
    console.log('entrou')
    setTransition(() => Transition);
    setOpen(true);
  }; */

  const handleClose = () => {
    setOpen(false);
  };

  async function criarPonto(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.add(componente,payload)
   
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Ponto Registrado')
      formik.resetForm()
      
    }catch(error){
      console.log(error)
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Houve um erro e não foi possível registrar')

    }
  }

  async function editarPonto(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.update(componente,payload)
    
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Ponto Atualizado')
      //formik.resetForm()
      
    }catch(error){
      console.log(error)
      if(error.status === 401){
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('Não foram encontradas alterações para realizar')
      }
    }
  }

  useEffect(()=>{
    ApiConnectorTemplate.list('estabelecimento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      console.log(r)
      setEstabelecimentos(r)
    })
  },[]) 

  useEffect(()=>{
    ApiConnectorTemplate.list('produtos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      console.log(r)
      setProdutos(r)
    })
  },[]) 

  useEffect(()=>{
    ApiConnectorTemplate.list('servicos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      console.log(r)
      setServicos(r)
    })
  },[]) 


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.cardRecipient}>
            <CardHeader color="primary" /* style={{background:"black"}} */>
              <h4 className={classes.cardTitleWhite}>Cadastro de itens para resgate de pontos</h4>
              <p className={classes.cardCategoryWhite}>
              Edição e Exclusão de Pontos
              </p>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
            <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
              <InputLabel htmlFor="select-estabelecimento" style={{fontSize: "12px",marginBottom: "4px"}}>Estabelecimento</InputLabel>
                        <Select
                          disabled ={mode == 'create' || mode == 'edit' ? false : true}
                          fullWidth
                          labelId="select-estabelecimento"
                          id="cod_estabelecimento"
                          name="cod_estabelecimento"
                          value={formik.values.cod_estabelecimento}
                          onChange={formik.handleChange}
                          error={formik.touched.cod_estabelecimento && Boolean(formik.errors.cod_estabelecimento)}
                          helperText={formik.touched.cod_estabelecimento && formik.errors.cod_estabelecimento}
                        >
                        {estabelecimentos.map(estabelecimento=>
                              <MenuItem key={estabelecimento.cod_estabelecimento} value={estabelecimento.cod_estabelecimento}>{estabelecimento.estabelecimento}</MenuItem>
                          )}
                      </Select>
              </GridItem>
              </GridContainer  >
            {mode == 'create' || mode == 'edit' ?
            <GridContainer >
              <GridItem xs={12} sm={12} md={6}>
              <h6 style={{marginBottom:'2px',marginTop:'2px'}}>Selecione um produto como referência</h6>
              <span>(Duplo Clique)</span>
              <Container style={{maxHeight:'200px',overflow:"overlay"}}>
                      <Table  size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Produto</TableCell>
                            <TableCell align="right">Valor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            produtos.map(produto=>{
                                      return <TableRow hover onDoubleClick ={()=>{formik.setFieldValue("tipo_origem",'Produto');formik.setFieldValue("referencia",produto.cod_produto)}} key={produto.produto}><TableCell align="left">{produto.produto}</TableCell><TableCell align="right">{produto.preco.toFixed(2).toString().replace(".",",")}</TableCell> </TableRow> })   
                          }
                        </TableBody>
                      </Table>
                </Container>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h6 style={{marginBottom:'2px',marginTop:'2px'}}>Selecione um serviço como referência</h6>
                <span>(Duplo Clique)</span>
                <Container style={{maxHeight:'200px',overflow:"overlay"}}>
                <Table  size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Serviço</TableCell>
                              <TableCell align="right">Valor</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              servicos.map(servicos=>{
                                        return <TableRow hover  onDoubleClick ={()=>{formik.setFieldValue("tipo_origem",'Serviço');formik.setFieldValue("referencia",servicos.cod_servico)}} key={servicos.servico}><TableCell align="left">{servicos.servico}</TableCell><TableCell align="right">{servicos.preco.toFixed(2).toString().replace(".",",")}</TableCell> </TableRow> })   
                            }
                          
                          </TableBody>
                        </Table>
                </Container>
              </GridItem>
            </GridContainer>:null}

              <GridContainer >
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    disabled ={true}
                    fullWidth
                    id="tipo_origem"
                    name="tipo_origem"
                    label="Tipo do Item"
                    value={formik.values.tipo_origem}
                    onChange={formik.handleChange}
                    error={formik.touched.tipo_origem && Boolean(formik.errors.tipo_origem)}
                    helperText={formik.touched.tipo_origem && formik.errors.tipo_origem}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    disabled ={true}
                    fullWidth
                    id="referencia"
                    name="referencia"
                    label="Descricao do Item"
                    value={formik.values.tipo_origem === "Produto" ? produtos.reduce((p,a)=>a.cod_produto === formik.values.referencia? a.produto:p,''):servicos.reduce((p,a)=>a.cod_servico === formik.values.referencia? a.servico:p,'')} 
                    onChange={formik.handleChange}
                    error={formik.touched.referencia && Boolean(formik.errors.referencia)}
                    helperText={formik.touched.referencia && formik.errors.referencia}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={formik.values.complemento}
                    onChange={formik.handleChange}
                    error={formik.touched.complemento && Boolean(formik.errors.complemento)}
                    helperText={formik.touched.complemento && formik.errors.complemento}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="quantidade"
                    name="quantidade"
                    label="Quantidade"
                    value={formik.values.quantidade}
                    onChange={formik.handleChange}
                    error={formik.touched.quantidade && Boolean(formik.errors.quantidade)}
                    helperText={formik.touched.quantidade && formik.errors.quantidade}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
              </GridContainer>

             {loading ? <LinearProgress style={{marginTop:"20px"}} /> : null}
            </CardBody>
            <CardFooter>
              {mode == 'create' ? <Button onClick={formik.handleSubmit} color="primary">Salvar Cadastro</Button> : mode == 'edit' ? <Button onClick={formik.handleSubmit}  color="primary">Editar Ponto</Button>:null}
              
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />


    </div>
  );
}
