class ApiConnector{
    constructor(){
      
    }
    static server(){
        return `https://api-teste.systembarber.com` 
        //return `http://localhost:3001` 
    }
    static serverAutentication(){
      return `http://localhost:3555`
  }

  static getRouterComponent (component) {
      const listaRouter =[
          {componente:"estabelecimento",router:"/estabelecimento/sb/estabelecimento"},
          {componente:"cliente",router:"/cliente/sb/cliente"},
          {componente:"profissional",router:"/profissional/sb/profissional"},
          {componente:"servicos",router:"/servicos/sb/servicos"},
          {componente:"produtos",router:"/produtos/sb/produtos"},
          {componente:"comissoes",router:"/comissoes/sb/comissoes"},
          {componente:"tiposdedespesas",router:"/tiposdedespesas/sb/tiposdedespesas"}, 
          {componente:"pontosporprodutoservico",router:"/ponto/sb/ponto"},
          {componente:"pontoscliente",router:"/ponto/sb/ponto/saldo/cliente"},
          {componente:"pontostroca",router:"/ponto/sb/ponto/pontostroca"},
          
          {componente:"configuracoesoperacionais",router:"/configuracoesoperacionais/sb/configuracoesoperacionais"},
          {componente:"mododepagamento",router:"/mododepagamento/sb/mododepagamento"},
          {componente:"comanda",router:"/comanda/sb/comanda"},
          {componente:"comandadelete",router:"/comanda/sb/comanda/deleteitem"},
          {componente:"lancamentodedespesas",router:"/lancamentodedespesas/sb/lancamentodedespesas"},
          {componente:"lancamentopagamentodedespesas",router:"/lancamentodedespesas/sb/lancamentodedespesas/lancamentopagamentodedespesas"},
          {componente:"cancelamentodedespesas",router:"/lancamentodedespesas/sb/lancamentodedespesas/cancelamentodedespesas"},
          {componente:"lancamentodeestoque",router:"/lancamentodeestoque/sb/lancamentodeestoque"},
          {componente:"estoqueentradas",router:"/lancamentodeestoque/sb/lancamentodeestoque/estoqueentradas"},
          {componente:"estoquesaidas",router:"/lancamentodeestoque/sb/lancamentodeestoque/estoquesaidas"},
          {componente:"acerto",router:"/acerto/sb/acerto"},
          {componente:"acertopagamento",router:"/acerto/sb/acerto/pagamento"},
          {componente:"acertospagos",router:"/acerto/sb/acerto/pagos"},
          {componente:"balanco",router:"/balanco/sb/balanco"},
          {componente:"agenda",router:"/agenda/sb/agenda"},
          {componente:"agendaconfirmaexterno",router:"/agenda/sb/agenda/confirma/externo"},
          {componente:"login",router:"/acesso/sb/acesso/login"},
          {componente:"changepassword",router:"/acesso/sb/acesso/changepassword"},
          {componente:"resetpassword",router:"/acesso/sb/acesso/resetpassword"}

          
  
      ] 
      return listaRouter.reduce((p,a)=>a.componente === component ? a.router:p,'')
    }

}

export default ApiConnector
