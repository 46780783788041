import React, { useState, useEffect } from "react";
// @material-ui/core components
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
/* import CustomInput from "components/CustomInput/CustomInput.js"; */
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import avatar from "assets/img/logo.png"
// dependencias para lidar com formulário
import { useFormik } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";

// operacoes assincronas
import PublicApiConnector from 'connectors/publicapiconnector.js'
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'


import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  description: {
    
    display: 'flex !important',
    justifyContent: 'center !important'
  },



};

const TextMaskCelular = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    /* showMask */
    />
  );
}

const TextMaskTelefone = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    /* showMask */
    />
  );
}

const TextMaskCPF = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    /* showMask */
    />
  );
}

const TextMaskCEP = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={[/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
    /* showMask */
    />
  );
}


const TextMaskNascimento = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
    /* showMask */
    />
  );
}

const TextMaskHora = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={[/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
    /* showMask */
    />
  );
}

TextMaskCelular.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskTelefone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskCPF.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskCEP.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskNascimento.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskHora.propTypes = {
  inputRef: PropTypes.func.isRequired,
};




const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  nome: yup
    .string('Informe o Nome')
    .required('Nome é um dado obrigatório'),
  data_de_nascimento: yup
    .string('Informe o nome fantasia')
    .nullable(),

  cod_estabelecimento: yup
    .string('Informe o nome fantasia')
    .required('Estabelecimento é um dado obrigatório'),
  cpf: yup
    .string('Informe o CPF'),

  telefone_celular: yup
    .string('Informe o Celular')
    .required('Telefone Celular é um dado obrigatório'),
  telefone_comercial: yup
    .string('Informe o Telefone Comercial'),

  email: yup
    .string('Digite um email válido')
    .email('Digite um email válido'),
  usuario: yup
    .string('Digite um usuario válido')
    .required('Usuario é um dado obrigatório'),
  cep: yup
    .string('Informe o CEP'),

  rua: yup
    .string('Informe a rua'),

  numero: yup
    .string('Informe o número'),

  bairro: yup
    .string('Informe o bairro'),

  uf: yup
    .string('Informe a UF'),

  cidade: yup
    .string('Informe a Cidade'),

  complemento: yup
    .string('Informe o complemento'),
  tempo_de_trabalho: yup
    .number('Informe o tempo de trabalho'),
  ativo: yup
    .string('Informe a Situação'),
  tempo_de_trabalho: yup
    .number('Informe o Tempo de Trabalho')
    .required('Tempo de Trabalho é um dado obrigatório')
    .integer()
    .positive()
    .max(60,'Deve ser um valor entre 1 e 60')
    .min(1)
    .typeError('Deve ser um valor entre 1 e 60'),

  hora_inicio: yup
    .string('Informe o horário de inicio da jornada')
    .required('inicio da jornada é um dado obrigatório'),
  hora_fim: yup
    .string('Informe o horário de fim da jornada')
    .required('fim da jornada é um dado obrigatório'),
  perfil: yup
    .number('Informe o perfil').required('Perfil é um dado obrigatório'),


});


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}


export default function Barbearia({ mode, teste }) {

  const componente = 'profissional'
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      nome: mode === 'view' || mode === 'edit' ? teste.nome : '',
      cod_estabelecimento: mode === 'view' || mode === 'edit' ? teste.cod_estabelecimento : '',
      data_de_nascimento: mode === 'view' || mode === 'edit' ? teste.data_de_nascimento : null,
      cpf: mode === 'view' || mode === 'edit' ? teste.cpf : '',
      telefone_celular: mode === 'view' || mode === 'edit' ? teste.telefone_celular : '',
      telefone_comercial: mode === 'view' || mode === 'edit' ? teste.telefone_comercial : '',
      email: mode === 'view' || mode == 'edit' ? teste.email : '',
      usuario: mode === 'view' || mode == 'edit' ? teste.usuario : '',
      cep: mode === 'view' || mode === 'edit' ? teste.cep : '',
      rua: mode === 'view' || mode === 'edit' ? teste.rua : '',
      numero: mode === 'view' || mode === 'edit' ? teste.numero : '',
      bairro: mode === 'view' || mode === 'edit' ? teste.bairro : '',
      uf: mode === 'view' || mode === 'edit' ? teste.uf : '',
      cidade: mode === 'view' || mode === 'edit' ? teste.cidade : '',
      complemento: teste && mode === 'view' || mode === 'edit' ? teste.complemento : '',
      tempo_de_trabalho: teste && mode === 'view' || mode === 'edit' ? teste.tempo_de_trabalho : '',
      hora_inicio: teste && mode === 'view' || mode === 'edit' ? teste.hora_inicio : '',
      hora_fim: teste && mode === 'view' || mode === 'edit' ? teste.hora_fim : '',
      observacoes: mode === 'view' || mode === 'edit' ? teste.observacoes : '',
      ativo: mode === 'view' || mode === 'edit' ? teste.ativo : '',
      perfil: mode === 'view' || mode === 'edit' ? teste.perfil : ''



    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
      if (mode === "create") {
        criarProfissional(componente, values)
      } else if (mode === "edit") {
        let payload = { ...values }
        payload.cod_profissional = teste.cod_profissional
        editarProfissional(componente, payload)
      }

      // criarEstabelecimento(componente,values)
      //alert(JSON.stringify(values, null, 2));
    },
  });


  const [estabelecimentos, setEstabelecimentos] = useState([])
  const [municipiosApi, setMunicipio] = useState([])
  const [estadosApi, setEstadosApi] = useState([])
  const [estadoSelecionado, setEstadoSelecionado] = useState('')
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')


  /*   const handleClick = (Transition) => () => {
      console.log('entrou')
      setTransition(() => Transition);
      setOpen(true);
    }; */

  const handleClose = () => {
    setOpen(false);
  };

  async function criarProfissional(componente, payload, TransitionLeft) {
    setLoading(true);
    try {
      const response = await ApiConnectorTemplate.add(componente, payload)

      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Profissional Registrado')
      formik.resetForm()

    } catch (error) {
      console.log(error)
      if(error.status == 409){
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('O usuário informado já existe')
      }else{
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('Houve um erro e não foi possível registrar')
      }

    }


  }

  async function editarProfissional(componente, payload, TransitionLeft) {
    setLoading(true);
    try {
      const response = await ApiConnectorTemplate.update(componente, payload)

      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Profissional Atualizado')
      //formik.resetForm()

    } catch (error) {
      console.log(error)
      if (error.status === 401) {
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('Não foram encontradas alterações para realizar')
      }
    }
  }

  useEffect(() => {
    ApiConnectorTemplate.list('estabelecimento', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento') }).then(r => {

      setEstabelecimentos(r)
    })
  }, [])

  useEffect(() => {
    PublicApiConnector.listaEstados().then(r => {

      setEstadosApi(r.sort((a, b) => (a.sigla > b.sigla) ? 1 : ((b.sigla > a.sigla) ? -1 : 0)))
    })
  }, [])

  useEffect(() => {
    PublicApiConnector.listaMunicipios(formik.values.uf).then(r => {
      r.sort(function (a, b) {
        return a.nome.localeCompare(b.nome);
      });
      setMunicipio(r)
    })
  }, [formik.values.uf])


  useEffect(() => {

    if (formik.values.cep.replace(/\s/g, '').length == 10) {
      PublicApiConnector.consultaCep(formik.values.cep.replace(/[^0-9]/g, '')).then(r => {
        console.log(r)
        if (!r.erro) {
          if (r.bairro !== "") {
            formik.setFieldValue("bairro", r.bairro)
          }
          if (r.logradouro !== "") {
            formik.setFieldValue("rua", r.logradouro)
          }

          if (r.uf !== "") {
            formik.setFieldValue("uf", r.uf)
          }

          if (r.localidade !== "") {
            formik.setFieldValue("cidade", r.localidade)
          }

        } else {
          /*         formik.setFieldValue("bairro","")
                  formik.setFieldValue("rua","")
                  formik.setFieldValue("uf","")
                  formik.setFieldValue("cidade","") */
        }
      })
    }

  }, [formik.values.cep])


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary" /* style={{background:"black"}} */>
              <h4 className={classes.cardTitleWhite}>Dados Cadastrais do Profissional</h4>
              <p className={classes.cardCategoryWhite}>
                Edição e Exclusão de profissionais
              </p>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="nome"
                    name="nome"
                    label="Nome"
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                    error={formik.touched.nome && Boolean(formik.errors.nome)}
                    helperText={formik.touched.nome && formik.errors.nome}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer >
                <GridItem xs={12} sm={12} md={4}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} muiTheme={{ headerColor: 'black' }} locale={"pt-br"}>
                    <DatePicker

                      disabled={mode == 'view' ? true : false}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="data_de_nascimento"
                      label="Data de Nascimento"
                      format="DD/MM/YYYY"
                      value={formik.values.data_de_nascimento}
                      onChange={date => formik.setFieldValue('data_de_nascimento', date._d, false)}
                      error={formik.touched.data_de_nascimento && Boolean(formik.errors.data_de_nascimento)}
                      helperText={formik.touched.data_de_nascimento && formik.errors.data_de_nascimento}
                    /*                       KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                          }} */
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel htmlFor="select-estabelecimento" style={{ fontSize: "12px", marginBottom: "4px" }}>Estabelecimento</InputLabel>
                  <Select
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    labelId="select-estabelecimento"
                    id="cod_estabelecimento"
                    name="cod_estabelecimento"
                    value={formik.values.cod_estabelecimento}
                    onChange={formik.handleChange}
                    error={formik.touched.cod_estabelecimento && Boolean(formik.errors.cod_estabelecimento)}
                    helperText={formik.touched.cod_estabelecimento && formik.errors.cod_estabelecimento}
                  >
                    {estabelecimentos.map(estabelecimento =>
                      <MenuItem key={estabelecimento.cod_estabelecimento} value={estabelecimento.cod_estabelecimento}>{estabelecimento.estabelecimento}</MenuItem>
                    )}
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="cpf"
                    name="cpf"
                    label="CPF"
                    value={formik.values.cpf}
                    onChange={formik.handleChange}
                    error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                    helperText={formik.touched.cpf && formik.errors.cpf}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskCPF,
                    }}
                  />
                </GridItem>
              </GridContainer  >
              <GridContainer >
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskCelular,
                    }}
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="telefone_celular"
                    name="telefone_celular"
                    label="Celular"
                    value={formik.values.telefone_celular}
                    onChange={formik.handleChange}
                    error={formik.touched.telefone_celular && Boolean(formik.errors.telefone_celular)}
                    helperText={formik.touched.telefone_celular && formik.errors.telefone_celular}
                  />

                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="telefone_comercial"
                    name="telefone_comercial"
                    label="Telefone Comercial"
                    value={formik.values.telefone_comercial}
                    onChange={formik.handleChange}
                    error={formik.touched.telefone_comercial && Boolean(formik.errors.telefone_comercial)}
                    helperText={formik.touched.telefone_comercial && formik.errors.telefone_comercial}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskTelefone,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer >
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    disabled={(mode == 'create' || mode == 'create' ? false : true)}
                    fullWidth
                    id="usuario"
                    name="usuario"
                    label="Usuario"
                    value={formik.values.usuario}
                    onChange={formik.handleChange}
                    error={formik.touched.usuario && Boolean(formik.errors.usuario)}
                    helperText={formik.touched.usuario && formik.errors.usuario}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>

              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="cep"
                    name="cep"
                    label="CEP"
                    value={formik.values.cep}
                    onChange={formik.handleChange}
                    error={formik.touched.cep && Boolean(formik.errors.cep)}
                    helperText={formik.touched.cep && formik.errors.cep}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskCEP,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer >
                <GridItem xs={12} sm={12} md={9}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="rua"
                    name="rua"
                    label="Rua"
                    value={formik.values.rua}
                    onChange={formik.handleChange}
                    error={formik.touched.rua && Boolean(formik.errors.rua)}
                    helperText={formik.touched.rua && formik.errors.rua}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="numero"
                    name="numero"
                    label="Número"
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    error={formik.touched.numero && Boolean(formik.errors.numero)}
                    helperText={formik.touched.numero && formik.errors.numero}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer >
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="bairro"
                    name="bairro"
                    label="Bairro"
                    value={formik.values.bairro}
                    onChange={formik.handleChange}
                    error={formik.touched.bairro && Boolean(formik.errors.bairro)}
                    helperText={formik.touched.bairro && formik.errors.bairro}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>


                  <InputLabel htmlFor="select-uf" style={{ fontSize: "12px", marginBottom: "4px" }}>UF</InputLabel>
                  <Select
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="uf"
                    name="uf"
                    labelId="select-uf"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    error={formik.touched.uf && Boolean(formik.errors.uf)}
                    helperText={formik.touched.uf && formik.errors.uf}
                  >
                    {estadosApi.map(estado =>
                      <MenuItem key={estado.id} value={estado.sigla}>{estado.sigla}</MenuItem>
                    )}
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel htmlFor="select-cidade" style={{ fontSize: "12px", marginBottom: "4px" }}>Cidade</InputLabel>
                  <Select
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    labelId="select-cidade"
                    id="cidade"
                    name="cidade"
                    value={formik.values.cidade}
                    onChange={formik.handleChange}
                    error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                    helperText={formik.touched.cidade && formik.errors.cidade}
                  >
                    {municipiosApi.map(municipio =>
                      <MenuItem key={municipio.id} value={municipio.nome}>{municipio.nome}</MenuItem>
                    )}
                  </Select>
                </GridItem>
              </GridContainer>
              <GridContainer >
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={formik.values.complemento}
                    onChange={formik.handleChange}
                    error={formik.touched.complemento && Boolean(formik.errors.complemento)}
                    helperText={formik.touched.complemento && formik.errors.complemento}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel htmlFor="select-ativo" style={{ fontSize: "12px", marginBottom: "4px" }}>Situação</InputLabel>
                  <Select
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    labelId="select-ativo"
                    id="ativo"
                    name="ativo"
                    value={formik.values.ativo}
                    onChange={formik.handleChange}
                    error={formik.touched.ativo && Boolean(formik.errors.ativo)}
                    helperText={formik.touched.ativo && formik.errors.ativo}
                  >

                    <MenuItem value={0}>Ativo</MenuItem>
                    <MenuItem value={1}>Inativo</MenuItem>

                  </Select>

                </GridItem>
              </GridContainer >
              <GridContainer >
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="tempo_de_trabalho"
                    name="tempo_de_trabalho"
                    label="Duração do serviço"
                    value={formik.values.tempo_de_trabalho}
                    onChange={formik.handleChange}
                    error={formik.touched.tempo_de_trabalho && Boolean(formik.errors.tempo_de_trabalho)}
                    helperText={formik.touched.tempo_de_trabalho && formik.errors.tempo_de_trabalho}
                    InputLabelProps={{
                      shrink: true,
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    type="time"
                    id="hora_inicio"
                    name="hora_inicio"
                    label="Hora início Jornada"
                    value={formik.values.hora_inicio}
                    onChange={formik.handleChange}
                    error={formik.touched.hora_inicio && Boolean(formik.errors.hora_inicio)}
                    helperText={formik.touched.hora_inicio && formik.errors.hora_inicio}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskHora,
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    type="time"
                    id="hora_fim"
                    name="hora_fim"
                    label="Hora Fim Jornada"
                    value={formik.values.hora_fim}
                    onChange={formik.handleChange}
                    error={formik.touched.hora_fim && Boolean(formik.errors.hora_fim)}
                    helperText={formik.touched.hora_fim && formik.errors.hora_fim}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskHora,
                    }}

                  />
                </GridItem>
              </GridContainer >
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    disabled={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="observacoes"
                    name="observacoes"
                    label="Sobre o profissional"
                    value={formik.values.observacoes}
                    onChange={formik.handleChange}
                    error={formik.touched.observacoes && Boolean(formik.errors.observacoes)}
                    helperText={formik.touched.observacoes && formik.errors.observacoes}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                  />
                </GridItem>
              </GridContainer >
              <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel htmlFor="select-perfil" style={{ fontSize: "12px", marginBottom: "4px" }}>Perfil</InputLabel>
                <Select
                  disabled={mode == 'create' || mode == 'edit' ? false : true}
                  fullWidth
                  labelId="select-perfil"
                  id="perfil"
                  name="perfil"
                  value={formik.values.perfil}
                  onChange={formik.handleChange}
                  error={formik.touched.perfil && Boolean(formik.errors.perfil)}
                  helperText={formik.touched.perfil && formik.errors.perfil}
                >

                  <MenuItem value={3}>Comum</MenuItem>
                  <MenuItem value={4}>Administrador</MenuItem>

                </Select>

              </GridItem>
              </GridContainer>
              {loading ? <LinearProgress style={{ marginTop: "20px" }} /> : null}


            </CardBody>
            <CardFooter>
              {mode == 'create' ? <Button onClick={formik.handleSubmit} color="primary">Criar Profissional</Button> : mode == 'edit' ? <Button onClick={formik.handleSubmit} color="primary">Editar Profissional</Button> : <Button style={{visibility: 'hidden'}} >AAA</Button>}

            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#BSYSTEM" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>Profissional</h6>
              <h4 className={classes.cardTitle}>{formik.values.nome}</h4>
              {/* <h5 className={classes.cardTitle}>Seg - Sab - 08:00 as 21:00</h5> */}
              <p className={classes.description} >
                {/*                 Desde 1998 Coronel Brooke representa o homem moderno,mantendo o estilo do homem raiz sem ser antiquado
                Com um espaço confortável, recebe seus clientes por agendamento.Espaço climatizado com estrutura para
                aproveitar o momento tomando um chopp trincando e uma partida de bilhar */}
                {formik.values.observacoes}
              </p>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />


    </div>
  );
}
