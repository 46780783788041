import ApiConnector from 'connectors/apiconnector'

class ApiConnectorTemplate extends ApiConnector{
   
    constructor(){
      
    } 

    static async list (componente,payload) {
      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token':localStorage.getItem('token'),
          'x-sistema':'SB'
        },
        body: JSON.stringify(payload)
      }

      try{
        const response = await fetch(`${ApiConnector.server()}${ApiConnector.getRouterComponent(componente)}`, options)
        if(response.status === 401 || response.status === 500 || response.status === 402){
          throw response;
        }else{
          return response.json()
        } 
      }catch(error){
        //console.log(error)
        throw error;
      }
    }

    static async delete (componente,id) {
      const options = {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token':localStorage.getItem('token'),
          'x-sistema':'SB'
        },
        body: JSON.stringify({id:id})
      }
      try{
        const response = await fetch(`${ApiConnector.server()}${ApiConnector.getRouterComponent(componente)}`, options)
        if(response.status === 401 || response.status === 500){
          throw response;
        }else{
          return response.json()
        }
      }catch(error){
        console.log(error)
        throw error;
      }
    }

    static async add (componente,payload) {
      const options = {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token':localStorage.getItem('token'),
          'x-sistema':'SB'
        },
        body: JSON.stringify(payload)
      }
      try{
        const response = await fetch(`${ApiConnector.server()}${ApiConnector.getRouterComponent(componente)}`, options)
        if(response.status === 401 || response.status === 500 || response.status === 502 || response.status === 409){
          throw response;
        }else{
          return response.json()
        }
      }catch(error){
        //console.log(error)
        throw error;
      }
    }

    static async update (componente,payload) {
      const options = {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token':localStorage.getItem('token'),
          'x-sistema':'SB'
        },
        body: JSON.stringify(payload)
      }
      try{
        const response = await fetch(`${ApiConnector.server()}${ApiConnector.getRouterComponent(componente)}`, options)
        if(response.status === 401 || response.status === 500 || response.status === 409){
          throw response;
        }else{
          return response.json()
        }
      }catch(error){
        //console.log(error)
        throw error;
      }
    }
}

export default ApiConnectorTemplate