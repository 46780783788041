import React,{useState,useEffect}from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import IconButton from '@material-ui/core/IconButton';
import TableCustom from "components/Table/Table.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'
import { Formik, useFormik } from 'formik';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import * as yup from 'yup';
import { Fragment } from "react";
import ListaCliente from "views/Cliente/ClienteLista";
import { Search } from "@material-ui/icons";
import { createTrue } from "typescript";


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,

    position: 'relative',
    minHeight: 500,
    paddingLeft:0,
    paddingRight:0 
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    minWidth: '90vw',
    maxWidth: '90vw'
  },
  resumos:{
    color: "white",
    background: "linear-gradient(156deg, #3c4042, #2d0b32)"
  }
}));

const validationSchema = yup.object({
/*
  cod_cliente: yup
  .string('Informe o Cliente')
  .required('Cliente é um dado obrigatório'),
   cod_profissional: yup
  .string('Informe o Profissional')
  .required('Profissional é um dado obrigatório'),
  cod_produto:  yup
  .string('Informe o Produto')
  .required('Produto é um dado obrigatório'),
  cod_comissao: yup
  .string('Informe a Comissao Fixa')
  .required('Comissao Fixa é um dado obrigatório'),
  quantidade_produto: yup
  .string('Informe a Quantidade de Produto')
  .required('Quantidade de Produto é um dado obrigatório'),
  valor_unitario_produto:  yup
  .string('Informe o Valor Unitário do Produto')
  .required('Valor Unitário do Produto é um dado obrigatório'),
  valor_venda_produto: yup
  .string('Informe o Valor de Venda')
  .required('Valor de Venda é um dado obrigatório'),
  cod_servico:  yup
  .string('Informe o Serviço')
  .required('Serviço é um dado obrigatório'),
  cod_comissao_servico:  yup
  .string('Informe a Comissão de Serviço')
  .required('Comissão de Serviço é um dado obrigatório'),
  valor_venda_servico: yup
  .string('Informe o Valor de Venda')
  .required('Valor de Vendaé um dado obrigatório'), */
  
   
        
});

export default function ComandaContainer(TransitionLeft) {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const formik = useFormik({
    initialValues: {
     cod_cliente:  '',
     cod_profissional:'',
     cod_servico: '',
     cod_comissao_servico:'',
     valor_venda_servico: '',
     cod_produto: '',
     comissao_fixa_produto:'',
     quantidade_produto:'',
     valor_unitario_produto: '',
     valor_venda_produto:'',
     valor_total_comanda:'',
     pontos_total_comanda:'',
     data_de_pagamento: "",
     cod_meio_pagamento: ""

   }, 
   validationSchema: validationSchema,
   onSubmit: (values) => {
    console.log(values)
   },
   validateOnChange:false,
   validateOnBlur:false
 });

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openPoints, setOpenPoints] = useState(false);
  const [openServicoDeTroca, setOpenServicoDeTroca] = useState(false);
  const [openClassificacaoDeComissao, setOpenClassificacaoDeComissao] = useState(false);
  const [openSnack, setOpenSnak] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [message,setMessage] = useState('')
  const [listaClientes,setListaClientes] = useState([])
  const [listaProfissional,setListProfissional] = useState([])
  const [listaServicos,setListaServicos]= useState([])
  const [listaProdutos,setListaProdutos] = useState([])
  const [listaComissoes,setListaComissao] = useState([])
  const [itensComanda,setItemComanda] = useState([])
  const [resetForm,setResetForm] = useState(1)
  const [resetFormFim,setResetFormFim] = useState(1)
  const [listaMeioPagamento,setListaMeioPagamento] = useState([])
  const [dataInicialPeriodo,setDataIncial] = useState()
  const [dataFinalPeriodo,setDataFinal] = useState()
  const [tipoDeFiltro,setTipoDeFiltro] = useState(0)
  const [filtrarPor,setFiltrarPor] = useState(0)
  const [filtrarNome,setFiltrarNome] = useState('')
  const [listaRelatorio,setListaRelatorio] = useState([])
  const [listaFiltrada,setListaFiltrada] = useState([])
  const [totalDinheiro,setTotalDinherio] = useState([])
  const [totalPix,setTotalPix] = useState([])
  const [totalServicos,setTotalServicos] = useState([])
  const [totalDebito,setTotalDebito] = useState([])
  const [totalCredito,setTotalCredito] = useState([])
  const [totalComissao,setTotalComissao] = useState([])
  const [totalProdutos,setTotalProdutos] = useState([])
  const [openDelete, setopenDelete] = useState({action:false,referencia:"",message:""});
  const [loadingPoints, setLoadingPoints] = useState(false)
  const [loadingComanda, setLoadingComanda] = useState(false)
  
  const [loadingClassificacaoDeComissao, setClassificacaoDeComissao] = useState(false)
  const [loadingServicoDeTroca, setLoadingServicoDeTroca] = useState(false)
  const [listaServiosDeTroca,setServiosDeTroca] = useState([])
  const [listaPontosCliente,setListaPontosCliente] = useState([])
  const [listaDeSelecaoDeTroca,setListaDeSelecaoDeTroca] = useState([])





        useEffect(()=>{
            if(open == true){
                Promise.all([
                 
                    ApiConnectorTemplate.list('produtos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                    ApiConnectorTemplate.list('servicos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                    ApiConnectorTemplate.list('comissoes',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                    ApiConnectorTemplate.list('mododepagamento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                ])
                .then(values=>{
                    console.log(values)

                    setListaProdutos(values[0])
                    setListaServicos(values[1])
                    setListaComissao(values[2])
                    setListaMeioPagamento(values[3])
                })
                .catch(error=>{
                    console.log(error)
                })
            }
        },[open])


        useEffect(()=>{
          console.log(itensComanda)
          if(itensComanda.length > 0){
            let itensAvalorizar = itensComanda.filter(x=>{
              if(x.fidelidade == false){
                return x
              }
            })
            let t = itensAvalorizar.map(item => parseFloat(item.valor.replace(",","."))).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual;},0)
            formik.setFieldValue("valor_total_comanda",t.toFixed(2).replace(".",","))
          }


          if(itensComanda.length > 0){
            let itensAvalorizar = itensComanda.filter(x=>{
              if(x.fidelidade == true){
                return x
              }
            })
            let t = itensAvalorizar.map(item => parseFloat(item.pontos_fidelidade)).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual;},0)
            formik.setFieldValue("pontos_total_comanda",t.toFixed(2).replace(".",","))
          }




          
          
      },[itensComanda])

      
      useEffect(() => {
        console.log(formatDate())
        let data = formatDate()
        setDataIncial(data)
        setDataFinal(data)
      }, [])

      useEffect(()=>{
        if(openPoints == true){
          searchPointsCustomer()
        }
      },[openPoints])


      useEffect(()=>{
        if(openServicoDeTroca == true){
          checkItensToChange()
        }
      },[openServicoDeTroca])



    //

      useEffect(()=>{
        
            Promise.all([
                ApiConnectorTemplate.list('cliente',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
                ApiConnectorTemplate.list('profissional',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),
              
            ])
            .then(values=>{
                console.log(values)
                setListaClientes(values[0])
                setListProfissional(values[1])
                
            })
            .catch(error=>{
                console.log(error)
            })
        
    },[])



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatDate = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const formatDate2 = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm()
    setItemComanda([])
    setOpen(false);
  };


  const handleCheckPoints = () => {
    if(formik.values.cod_cliente){
      setOpenPoints(true)
      setLoadingPoints(true)
    }else{
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Selecione um cliente para consultar')
    }
  };

  const handleClosePoints = () => {
    setOpenPoints(false)
  };

  const handleCloseSnack = () => {
    setOpenSnak(false);
  };

  const handleChangePoints = () => {
    setOpenServicoDeTroca(true)
    setLoadingServicoDeTroca(true)
  };


  const handleCloseChangePoints = () => {
    setOpenServicoDeTroca(false)
  };


  const handleCloseClassificacaoDeComissao = ()=>{
    setOpenClassificacaoDeComissao(false)
  }

  const applyComissaoPonto = (item,value)=>{
    console.log(listaDeSelecaoDeTroca)
    console.log(item,value)
    let oldList = listaDeSelecaoDeTroca
    let newList = oldList.map(item=>{
      if(item.cod_ponto == item.cod_ponto){
         item.comissao = listaComissoes.reduce((p,a)=>a.comissao === value && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento') ? a.cod_comissao:p,'')
      }
      return item
    })

    setListaDeSelecaoDeTroca(newList)


    
  }


  function handleAddOrRemoveListSelected(row){
    console.log(row)
    let oldList = listaDeSelecaoDeTroca
    let newList = []
 
     
    let filtro = oldList.filter(x=> x.cod_ponto == row.cod_ponto)
    if(filtro.length > 0){
   
      newList = oldList.filter(x=>{
          if(x.cod_ponto != row.cod_ponto){
            return x
          }
      })
    }else{
      newList = [...oldList]
      newList.push(row)
    }
    setListaDeSelecaoDeTroca(newList)
  }


  

  const handleConvertPointToServiceOrProduct = () => {
    console.log(listaDeSelecaoDeTroca)
    let totalSelecionado = listaDeSelecaoDeTroca.reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.quantidade;},0)
    if(totalSelecionado <=listaPontosCliente.filter(x=>{return x.status == 'ATIVO'}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos;},0)){
       const checkServicos = listaDeSelecaoDeTroca.filter(x=> x.tipo_origem == 'Serviço')
       if(checkServicos.length > 0){
          setOpenClassificacaoDeComissao(true)
          setOpenClassificacaoDeComissao(true)

       }else{
        console.log(itensComanda)
        let {cod_cliente,cod_profissional,cod_produto,comissao_fixa_produto,quantidade_produto,valor_unitario_produto,valor_venda_produto} = formik.values
        let tempComanda = []
        for (let index = 0; index < listaDeSelecaoDeTroca.length; index++) {
          const element = listaDeSelecaoDeTroca[index];
          let obj = {}
          obj.cliente = cod_cliente
          obj.funcionario = cod_profissional
          obj.servico_produto = element.referencia
          obj.comissao =  listaProdutos.reduce((p,a)=>a.cod_produto === element.referencia ? a.percentual_de_comissao:p,0).toFixed(2).replace(".",",")
          obj.quantidade = "1"
          obj.valor = listaProdutos.reduce((p,a)=>a.cod_produto === element.referencia ? a.preco:p,0).toFixed(2).replace(".",",")
          obj.tipo = 'produto'
          obj.fidelidade = true
          obj.complemento_fidelidade = element.complemento
          obj.pontos_fidelidade = element.quantidade

          tempComanda.push(obj)
        }

        console.log(tempComanda)
        setItemComanda([...itensComanda,...tempComanda])
        setOpenServicoDeTroca(false)
        setOpenPoints(false)
       }
    }else{
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('A quantidade de pontos do cliente é inferior a quantidade necessária para trocar')
     
    }
  };



  const searchPointsCustomer = async()=>{
    ApiConnectorTemplate.list('pontoscliente',{cod_cliente:formik.values.cod_cliente,cod_estabelecimento:localStorage.getItem('cod_estabelecimento')})
    .then(response=>{
      console.log(response)
      let pontosFormatados = response.map(item=>{
        if(item.expiracao_ponto){
          if(item.ano_referencia != item.ano_corrente){
            item.status = 'EXPIRADO'
          }else{
            item.status = 'ATIVO'
          }

        }else {
          item.status = 'ATIVO'
        }

        return item
      })
      setListaPontosCliente(pontosFormatados)
      setLoadingPoints(false)
    })
    .catch(error=>{
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Não foi possível listar os pontos do cliente selecionado')
      setLoadingPoints(false)
      //handleClosePoints() 
    })
  }

  const checkItensToChange = async()=>{
    ApiConnectorTemplate.list('pontostroca',{qtd_pontos:((listaPontosCliente.filter(x=>{return x.status == 'ATIVO'}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos;},0)) - (itensComanda.filter(x=>{return x.fidelidade == true}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos_fidelidade;},0))),cod_estabelecimento:localStorage.getItem('cod_estabelecimento')})
    .then(response=>{
      console.log(response)
      setServiosDeTroca(response)
      setLoadingServicoDeTroca(false)
    })
    .catch(error=>{
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Não foi possível listar itens que sejam possível ser trocados')
      setLoadingServicoDeTroca(false)
      //handleClosePoints() 
    })
  }

  const handleSalvar = async()=>{
    if(itensComanda.length > 0){
      if(formik.values.data_de_pagamento !== null && formik.values.cod_meio_pagamento !== ""){
        ApiConnectorTemplate.add('comanda',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),itenscomanda:itensComanda,valortotal:formik.values.valor_total_comanda,formadepagamento:formik.values.cod_meio_pagamento})
        .then(r=>{
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Comanda Finalizada')
          setItemComanda([])
          setResetFormFim(resetFormFim + 1)
          formik.setFieldValue('cod_meio_pagamento','')
          formik.setFieldValue('valor_total_comanda','')
          formik.setFieldValue('pontos_total_comanda','')
          
          handleSearchRelatorio() 
         
        })
        .catch(error=>{
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Não foi possível lancar essa comanda')
          handleClose() 
        })

      }else{
        setTransition(() => TransitionLeft);
        setOpenSnak(true);
        setMessage('Verifique a data e o meio de Pagamento')
      }
    }else{
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Não há itens para gravar')
    }
  }

  const handleAddServico = ()=>{
    let {cod_cliente,cod_profissional,cod_servico,cod_comissao_servico,valor_venda_servico} = formik.values
    if(cod_cliente == "" || cod_profissional == "" || cod_servico == "" || cod_comissao_servico == "" || valor_venda_servico == ""){
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Certifique se que os campos estejam preenchidos')
    }else{

      let obj = {}
      obj.cliente = cod_cliente
      obj.funcionario = cod_profissional
      obj.servico_produto = cod_servico
      obj.comissao = cod_comissao_servico
      obj.quantidade = 1
      obj.valor = valor_venda_servico
      obj.tipo = 'servico'
      obj.fidelidade = false
      obj.complemento_fidelidade = null
      obj.pontos_fidelidade = 0

      let t = [obj]
      setItemComanda([...itensComanda,...t])
      setResetForm(resetForm + 1)
      formik.setFieldValue('cod_servico','')
      formik.setFieldValue('cod_comissao_servico','')
      formik.setFieldValue('valor_venda_servico','')
      formik.setFieldValue('cod_produto','')
      formik.setFieldValue('comissao_fixa_produto','')
      formik.setFieldValue('quantidade_produto','')
      formik.setFieldValue('valor_unitario_produto','')
      formik.setFieldValue('valor_venda_produto','')
      formik.setFieldValue('valor_total_comanda','')
      formik.setFieldValue('pontos_total_comanda','')

      
   

    }

  }


  const handleAddProduto = ()=>{
    let {cod_cliente,cod_profissional,cod_produto,comissao_fixa_produto,quantidade_produto,valor_unitario_produto,valor_venda_produto} = formik.values
    if(formik.values.cod_cliente == "" || formik.values.cod_profissional == "" || formik.values.cod_produto == "" || formik.valor_venda_produto || formik.values.quantidade_produto == "" ){
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Certifique se que os campos para produto estejam preenchidos')
    }else{

      let obj = {}
      obj.cliente = cod_cliente
      obj.funcionario = cod_profissional
      obj.servico_produto = cod_produto
      obj.comissao = comissao_fixa_produto
      obj.quantidade = quantidade_produto
      obj.valor = valor_venda_produto
      obj.tipo = 'produto'
      obj.fidelidade = false
      obj.complemento_fidelidade = null
      obj.pontos_fidelidade = 0

      let t = [obj]
      setItemComanda([...itensComanda,...t])
      setResetForm(resetForm + 1)
      formik.setFieldValue('cod_servico','')
      formik.setFieldValue('cod_comissao_servico','')
      formik.setFieldValue('valor_venda_servico','')
      formik.setFieldValue('cod_produto','')
      formik.setFieldValue('comissao_fixa_produto','')
      formik.setFieldValue('quantidade_produto','')
      formik.setFieldValue('valor_unitario_produto','')
      formik.setFieldValue('valor_venda_produto','')
      formik.setFieldValue('valor_total_comanda','')
      formik.setFieldValue('pontos_total_comanda','')

      
    }
  }

 const  handleRemoveItem=(key)=>{
    let t = []
    itensComanda.forEach((element,index) => {
        if(index !== key){
          t.push(element)
        }
    });

    setItemComanda(t)
  }


  const handleDeleteFato = (row)=>{
    setopenDelete({action:true,referencia:row,message:'Deseja realmente excluir esse item ?'})

  }

  const handleConfimDelete=(row)=>{
    ApiConnectorTemplate.update('comandadelete',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),payload:row})
      .then(result=>{
        console.log(result)
        handleSearchRelatorio()
        setopenDelete({action:false,referencia:"",message:''})
      })
      .catch(error=>{
        console.log(error)
      }) 
  }

  const closeDelete = (row)=>{
    setopenDelete({action:false,referencia:"",message:''})

  }



  const handleSearchRelatorio=()=>{
    if(dataInicialPeriodo && dataFinalPeriodo){
      setLoadingComanda(true)
      ApiConnectorTemplate.list('comanda',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),di:dataInicialPeriodo,df:dataFinalPeriodo})
      .then(results=>{
        let listaTratada = []
        if(tipoDeFiltro == 1){
            listaTratada = results.filter(x=>x.tipo_de_item == 'produtos')
        }else if(tipoDeFiltro == 2){
          listaTratada = results.filter(x=>x.tipo_de_item == 'servicos')
        }else{
          listaTratada = results
        }

        if(filtrarPor == 1  && filtrarNome !== ''){
            listaTratada = listaTratada.filter(x=>x.cliente == filtrarNome)
        }else if(filtrarPor == 2  && filtrarNome !== ''){
          listaTratada = listaTratada.filter(x=>x.profissional == filtrarNome)
        }

          setListaRelatorio(results)

          if(results.length == 0){
            setTransition(() => TransitionLeft);
            setOpenSnak(true);
            setMessage('Nenhum registro encontrado com os paâmetros de filtro selecionados')
          }
          
          let dinheiro =  listaTratada.filter(x=>x.forma_de_pagamento == 'DINHEIRO').reduce(function(acc, val) { return acc + val.valor_total; }, 0)
          let debito =  listaTratada.filter(x=>x.forma_de_pagamento == 'DÉBITO').reduce(function(acc, val) { return acc + val.valor_total; }, 0)
          let crédito =  listaTratada.filter(x=>x.forma_de_pagamento == 'CRÉDITO').reduce(function(acc, val) { return acc + val.valor_total; }, 0)
          let pix =  listaTratada.filter(x=>x.forma_de_pagamento == 'PIX').reduce(function(acc, val) { return acc + val.valor_total; }, 0)
          let servicos =  listaTratada.filter(x=>x.tipo_de_item == 'servicos').reduce(function(acc, val) { return acc + val.valor_total; }, 0)
          let produtos =  listaTratada.filter(x=>x.tipo_de_item == 'produtos').reduce(function(acc, val) { return acc + val.valor_total; }, 0)
          let comissao =  listaTratada.reduce(function(acc, val) { return acc + val.valor_comissao; }, 0)
          console.log(dinheiro,debito)
          setListaFiltrada(listaTratada)
          setTotalDinherio(dinheiro.toFixed(2).replace(".",","))
          setTotalDebito(debito.toFixed(2).replace(".",","))
          setTotalPix(pix.toFixed(2).replace(".",","))
          setTotalCredito(crédito.toFixed(2).replace(".",","))
          setTotalServicos(servicos.toFixed(2).replace(".",","))
          setTotalProdutos(produtos.toFixed(2).replace(".",","))
          setTotalComissao(comissao.toFixed(2).replace(".",","))
          setLoadingComanda(false)
         
      })
      .catch(error=>{
        setTransition(() => TransitionLeft);
        setOpenSnak(true);
        setMessage('Não foi possivel lista os itens da comanda')
        setListaFiltrada([])
        setListaRelatorio([])
        setLoadingComanda(false)
      })

    }else{
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Selecione um período válido para pesquisar')
    }


  }

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: 'primary',
      className: classes.fab,
      icon: <AddIcon />,
      label: 'Add',
      callback:handleClickOpen
    }

  ];




  if(localStorage.getItem('cod_estabelecimento') == 'null'){
    return <Redirect to="/login"  />
  } 


  return (
    <div className={classes.root} style={{paddingLeft:'0px',paddingRight:'0px'}}>
      <AppBar position="static" color="default">
        <Tabs
          style={{paddingLeft:'0px',paddingRight:'0px'}}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <Tab label="Serviços/Produtos" {...a11yProps(0)} />
{/*           <Tab label="Despesas" {...a11yProps(1)} />
          <Tab label="Estoque" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ height: "calc(100vh - 130px)"}}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        {loadingComanda ? <LinearProgress style={{ marginTop: "20px" }} /> : null}
         {/*  Aqui será paresentado o relatório de consulta do Lançamento */}
          <GridContainer >
            
              <GridItem xs={12} sm={12} md={2}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt-br"} >
                    <DatePicker
                     style={{'cursor':'pointer'}}
                      fullWidth
                      InputLabelProps={{
                                        shrink: true,
                                      }}
                      id="dataInicial"
                      label="Data Inicial"
                      format="DD/MM/YYYY"
                      value={dataInicialPeriodo}
                      onChange = {date=> setDataIncial(formatDate2(date._d))}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      

                      }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt-br"}>
                    <DatePicker
                     
                      fullWidth
                      InputLabelProps={{
                                        shrink: true,
                                      }}
                      id="dataFinal"
                      label="Data Final"
                      format="DD/MM/YYYY"
                      value={dataFinalPeriodo}
                      onChange = {date=> setDataFinal(formatDate2(date._d))}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                <InputLabel htmlFor="select-tipode" style={{fontSize: "12px",marginBottom: "4px"}}>Tipo De Lançamento :</InputLabel>
                          <Select
                           
                            fullWidth
                            labelId="select-tipode"
                            id="tipode"
                            name="tipode"
                            value={tipoDeFiltro}
                            onChange={(e)=>setTipoDeFiltro(e.target.value)}

                          >
                                <MenuItem  value={0}>Todos</MenuItem>
                                <MenuItem  value={1}>Produtos</MenuItem>
                                <MenuItem  value={2}>Serviços</MenuItem>
                        </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                <InputLabel htmlFor="select-filtrarpor" style={{fontSize: "12px",marginBottom: "4px"}}>Filtrar por :</InputLabel>
                          <Select
                            
                            fullWidth
                            labelId="select-filtrarpor"
                            id="filtrarpor"
                            name="filtrarpor"
                            value={filtrarPor}
                            onChange={(e)=>{
                                setFiltrarPor(e.target.value)
                                setResetForm(resetForm + 1)
                                setFiltrarNome('')
                              }}

                          >
                                <MenuItem  value={0}>Todos</MenuItem>
                                <MenuItem  value={1}>Cliente</MenuItem>
                                <MenuItem  value={2}>Profissional</MenuItem>
                        </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Autocomplete
                        style={{marginTop:'0px'}}
    
                        id="nomefiltro"
                        noOptionsText={'Nome não localizado'}
                        size={'small'}
                        key={resetForm}
                        onInputChange={(e,value)=>{
                          
                          setFiltrarNome(value)
                          //formik.setFieldValue("cod_cliente",listaClientes.reduce((p,a)=>a.nome === value  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.cod_cliente:p,''))
                        }}
                        NomeFiltro
                        options={ filtrarPor == 2 ?  listaProfissional.map((option) => option.nome):filtrarPor == 1 ? listaClientes.map((option) => option.nome):[]}
                        renderInput={(params) => (
                        <TextField 
                        style={{marginTop:'2px'}}
                          {...params} 
                          label="Digite o nome" 
                          margin="normal" 
                          name="nomefiltro"
                          InputLabelProps={{
                            shrink: true,
                          }}
                           />
                        )}
                />
                </GridItem>
                <IconButton size="small" color="primary" aria-label="edit item" onClick={handleSearchRelatorio}>
                        <SearchIcon />
                </IconButton> 
             {/*    {window.innerWidth >= 960 ? <Fab style={{right:30,position:'absolute',background:"black"}} size="medium" color="primary" aria-label="add" onClick={handleClickOpen}><AddIcon /></Fab> : null} */}
              

                
                
                
                

{/*                 <TableCustom
                    style={{fontSize:'10px'}}
                    tableHeaderColor="primary"
                    tableHead={["Profissional","Cliente", "Serviço/Produto", "Tipo Comissão", "Data","Quantidade","Valor","Valor Comissão","Pontos","Tipo Pagamento","Acerto"]}
                    tableData={
                    
                      listaRelatorio.map(itemRel=>{
                        console.log(itemRel)
                      return  [itemRel.profissional, itemRel.cliente,itemRel.produto,"",itemRel.data,itemRel.quantidade,itemRel.valor_total,itemRel.valor_comissao,itemRel.pontos,itemRel.forma_pagamento,itemRel.pagamento_funcionario]
                    })
                  }
                  />  */} 
    
                <Scrollbars style={{ height: 'calc(100vh - 400px)' ,marginTop:'30px'}}>
                  <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                       <TableCell></TableCell>
                        <TableCell style={{minWidth:150,fontSize:10,padding:0}}>Profissional</TableCell>
                        <TableCell style={{minWidth:200,fontSize:10,padding:0}}>Cliente</TableCell>
                        <TableCell style={{minWidth:150,fontSize:10,padding:0}}>Serviço/Produto</TableCell>
                        <TableCell style={{minWidth:200,fontSize:10,padding:0}}>Tipo Comissão</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Data</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Quantidade</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Valor</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Valor Comissão</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Pontos</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Tipos de Pagamento</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Acerto</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listaFiltrada.map((row,key) => (
                        <TableRow key={key} style={{fontSize:'10px'}}>
                          <TableCell>
                            <IconButton size="small" color="secondary" aria-label="delete item" onClick={()=>handleDeleteFato(row)} >
                            <DeleteIcon style={{fontSize:'15px'}}/>
                          </IconButton>             
                          </TableCell>
                          <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{row.profissional}</TableCell>
                          <TableCell  style={{minWidth:200,fontSize:10,padding:0}}>{row.cliente}</TableCell>
                          <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{row.produto}</TableCell>
                          <TableCell  style={{minWidth:200,fontSize:10,padding:0}}>{row.tipo_comissao}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{moment(row.data).format("DD/MM/YYYY")}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{row.quantidade}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{row.valor_total.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{row.valor_comissao.toFixed(2).replace(".",",")}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{row.pontos}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{row.forma_de_pagamento}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{row.pagamento_funcionario}</TableCell>

                          
                        </TableRow>
                      ))}
                      
                    </TableBody>
                </Table>
                
                </Scrollbars >
                <GridContainer>
                
                    <TextField
                      
                      variant="filled"
                      disabled 
                      label="R$ Dinheiro"
                      margin="normal"
                      size={'small'}
                      id="total_dinheiro"
                      name="total_dinheiro"
                      value={totalDinheiro}
                      InputLabelProps={{
                        style: { color: '#FFFFFF' },
                        shrink: true
                        
                      }}
                      InputProps={{
                        className: classes.resumos,
                      }}
                      style={{width:'120px',padding:'5px'}}
                    />                    
               
                  
                    <TextField
                 
                      variant="filled"
                      disabled 
                      label="R$ Débito "
                      margin="normal"
                      size={'small'}
                      id="total_debito"
                      name="total_debito"
                      value={totalDebito}
                      InputLabelProps={{
                        style: { color: '#FFFFFF' },
                        shrink: true
                      }}
                      InputProps={{
                        className: classes.resumos,
                      }}
                      style={{width:'120px',padding:'5px'}}
                    />                    
               
                 
                    <TextField
                      variant="filled"
                      disabled 
                      label="R$ Crédito"
                      margin="normal"
                      size={'small'}
                      id="total_credito"
                      name="total_credito"
                      value={totalCredito}
                      InputLabelProps={{
                        style: { color: '#FFFFFF' },
                        shrink: true
                      }}
                      InputProps={{
                        className: classes.resumos,
                      }}
                      style={{width:'120px',padding:'5px'}}
                    /> 

                    <TextField
                      variant="filled"
                      disabled 
                      label="R$ Pix"
                      margin="normal"
                      size={'small'}
                      id="total_pix"
                      name="total_pix"
                      value={totalPix}
                      InputLabelProps={{
                        style: { color: '#FFFFFF' },
                        shrink: true
                      }}
                      InputProps={{
                        className: classes.resumos,
                      }}
                      style={{width:'120px',padding:'5px'}}
                    />                     
                  
                  
                    <TextField
                      variant="filled"
                      disabled 
                      label="R$ Total Serviços"
                      margin="normal"
                      size={'small'}
                      id="total_servicos"
                      name="total_servicos"
                      value={totalServicos}
                      InputLabelProps={{
                        style: { color: '#FFFFFF' },
                        shrink: true
                      }}
                      InputProps={{
                        className: classes.resumos,
                      }}
                      style={{width:'120px',padding:'5px'}}
                    />                    
                 
                  
                    <TextField
                      variant="filled"
                      disabled 
                      label="R$ Total Produtos"
                      margin="normal"
                      size={'small'}
                      id="total_produtos"
                      name="total_produtos"
                      value={totalProdutos}
                      InputLabelProps={{
                        style: { color: '#FFFFFF' },
                        shrink: true,
                      }}
                      InputProps={{
                        className: classes.resumos,
                      }}
                      style={{width:'120px',padding:'5px'}}
                    />                    
                                
                  
                    <TextField
                      variant="filled"
                      disabled 
                      label="R$ Comissão"
                      margin="normal"
                      size={'small'}
                      id="total_comissao"
                      name="total_comissao"
                      value={totalComissao}
                      InputLabelProps={{
                        style: { color: '#FFFFFF' },
                        shrink: true
                      }}
                      InputProps={{
                        className: classes.resumos,
                      }}
                      style={{width:'120px',padding:'5px'}}
                    />                    
                 
                  
                  
                 
                </GridContainer>  
                                          
                </GridContainer>

        </TabPanel>
      </SwipeableViews>
      {localStorage.getItem('perfil') == 3  ? null :


     /*  window.innerWidth >= 960 ? null : */
      fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          <Fab aria-label={fab.label} className={fab.className} color={fab.color} onClick={fab.callback} style={{background:"black"}}>
            {fab.icon}
          </Fab>
        </Zoom>
      ))}
      <Dialog 
        open={open}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
    

        

        >
        <DialogTitle style={{paddingBottom:"2px"}} id="form-dialog-title">Comanda de Serviços e Produtos</DialogTitle>
        <DialogContent style={{minHeight:'410px',paddingLeft:"0px",paddingRight:"0px",paddingTop:"0px"}}>
          <GridContainer style={{marginTop:"0px"}} >
          <GridItem xs={12} sm={12} md={10}>

            <GridContainer style={{marginTop:"0px"}} >
              
                <GridItem xs={12} sm={12} md={6}>
                <Autocomplete

                        id="cod_cliente"
                        noOptionsText={'Nenhum Cliente Localizado'}
                        size={'small'}
                        key={resetFormFim}
                        onInputChange={(e,value)=>{
                          listaClientes.reduce((p,a)=>a.nome === value? a:p,{})
                          formik.setFieldValue("cod_cliente",listaClientes.reduce((p,a)=>a.nome === value  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.cod_cliente:p,''))
                        }}
                        Clientes
                        options={listaClientes.map((option) => option.nome)}
                        renderInput={(params) => (
                        <TextField 
                          {...params} 
                          label="Cliente" 
                          margin="normal" 
                        
                          name="cod_cliente"
                          error={formik.touched.cod_cliente && Boolean(formik.errors.cod_cliente)}
                          helperText={formik.touched.cod_cliente && formik.errors.cod_cliente}
                          InputLabelProps={{
                            shrink: true,
                          }}
                           />
                        )}

                />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <Autocomplete
                        id="cod_profissional"
                        Profissional
                        noOptionsText={'Nenhum Profissional Localizado'}
                        size={'small'}
                        key={resetFormFim}
                        onInputChange={(e,value)=>{
                          formik.setFieldValue("cod_profissional",listaProfissional.reduce((p,a)=>a.nome === value  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.cod_profissional:p,''))
                        }}
                        options={listaProfissional.map((option) => option.nome)}
                        renderInput={(params) => (
                        <TextField
                           {...params}
                            label="Profissional"
                             margin="normal" 
                       
                             id="cod_profissional"
                             name="cod_profissional"
                             error={formik.touched.cod_profissional && Boolean(formik.errors.cod_profissional)}
                             helperText={formik.touched.cod_profissional && formik.errors.cod_profissional}
                             InputLabelProps={{
                               shrink: true,
                             }}
                           />
                        )}
                />
                </GridItem>
            </GridContainer>

            <GridContainer style={{marginTop:"0px"}} >
                <GridItem xs={12} sm={12} md={5}>
                <Autocomplete
                        id="cod_servico"
                        Serviço
                        loading
                        noOptionsText={'Servico Não Localizado'}
                        size={'small'}
                        key={resetForm}
                        options={listaServicos.map((option) => option.servico)}
                        onInputChange={(e,value)=>{
                          formik.setFieldValue("cod_servico",listaServicos.reduce((p,a)=>a.servico === value  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.cod_servico:p,''))
                          formik.setFieldValue("valor_venda_servico",listaServicos.reduce((p,a)=>a.servico === value && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento') ? a.preco.toFixed(2).replace(".",","):p,''))
                        }}
                        renderInput={(params) => (
                        <TextField
                           {...params} 
                           label="Serviço" 
                           margin="normal" 
                          
                           name="cod_servico"
                           error={formik.touched.cod_servico && Boolean(formik.errors.cod_servico)}
                           helperText={formik.touched.cod_servico && formik.errors.cod_servico}
                           InputLabelProps={{
                             shrink: true,
                           }}
                           
                           />
                        )}
                />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <Autocomplete
                        id="cod_comissao_servico"
                        Serviço
                        loading
                        noOptionsText={'Comissao Não localizada'}
                        size={'small'}
                        key={resetForm}
                        options={listaComissoes.map((option) => option.comissao)}
                        onInputChange={(e,value)=>{
                          formik.setFieldValue("cod_comissao_servico",listaComissoes.reduce((p,a)=>a.comissao === value && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento') ? a.cod_comissao:p,''))
                        }}
                        renderInput={(params) => (
                            <TextField 
                              {...params} 
                              label="Comissao" 
                              margin="normal" 
                             
                              name="cod_comissao_servico"
                              error={formik.touched.cod_comissao_servico && Boolean(formik.errors.cod_comissao_servico)}
                              helperText={formik.touched.cod_comissao_servico && formik.errors.cod_comissao_servico}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                        )}
                />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField 
                     label="Valor"
                     margin="normal"
                    
                     size={'small'}
                     key={resetForm}
                     id="valor_venda_servico"
                     name="valor_venda_servico"
                     value={formik.values.valor_venda_servico}
                     onChange={formik.handleChange}
                     error={formik.touched.valor_venda_servico && Boolean(formik.errors.valor_venda_servico)}
                     helperText={formik.touched.valor_venda_servico && formik.errors.valor_venda_servico}
                     InputLabelProps={{
                       shrink: true,
                     }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1} style={{display:'grid'}}>
                    <IconButton size="small" color="primary" aria-label="edit item" onClick={handleAddServico}>
                        <AddIcon />
                    </IconButton>
                </GridItem>
            </GridContainer>
            <GridContainer style={{marginTop:"0px"}}>
            <GridItem xs={12} sm={12} md={5}>
                <Autocomplete
                        id="cod_produto"
                        Serviço
                        loading
                        noOptionsText={'Produto Não Localizado'}
                        size={'small'}
                        options={listaProdutos.map((option) => option.produto)}
                        key={resetForm}
                        onInputChange={(e,value)=>{
                  
                          formik.setFieldValue("cod_produto",listaProdutos.reduce((p,a)=>a.produto === value  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.cod_produto:p,''))
                          formik.setFieldValue("comissao_fixa_produto",listaProdutos.reduce((p,a)=>a.produto === value && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento') ? a.percentual_de_comissao.toFixed(2).replace(".",","):p,''))
                          formik.setFieldValue("valor_unitario_produto",listaProdutos.reduce((p,a)=>a.produto === value && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento') ? a.preco.toFixed(2).replace(".",","):p,''))
                          formik.setFieldValue("valor_venda_produto",(formik.values.valor_unitario_produto*formik.values.quantidade_produto).toFixed(2).replace(".",","))
 
                        }}
                        renderInput={(params) => (
                        <TextField
                           {...params} 
                           style={{padding:"1px"}}
                           label="Produto" 
                           margin="normal" 
                           value={formik.values.cod_produto}
                           name="cod_produto"
                           error={formik.touched.cod_produto && Boolean(formik.errors.cod_produto)}
                           helperText={formik.touched.cod_produto && formik.errors.cod_produto}
                           InputLabelProps={{
                             shrink: true,
                           }}
                           
                           />
                        )}
                />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                <TextField 
                     label="Comissão %"
                     margin="normal"
                     disabled
                     size={'small'}
                     key={resetForm}
                     id="comissao_fixa_produto"
                     name="comissao_fixa_produto"
                     value={formik.values.comissao_fixa_produto}
                     onChange={formik.handleChange}
                     error={formik.touched.comissao_fixa_produto && Boolean(formik.errors.comissao_fixa_produto)}
                     helperText={formik.touched.comissao_fixa_produto && formik.errors.comissao_fixa_produto}
                     InputLabelProps={{
                       shrink: true,
                     }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                <TextField 
                     label="Qtd"
                     margin="normal"
                
                     size={'small'}
                     key={resetForm}
                     id="quantidade_produto"
                     name="quantidade_produto"
                     value={formik.values.quantidade_produto}
                     onChange={(e)=>{
                       console.log(e.target.value)
                        formik.setFieldValue("quantidade_produto",e.target.value)
                        if(e.target.value !== '' && formik.values.valor_unitario_produto !== ''){
                          formik.setFieldValue("valor_venda_produto",(parseFloat(e.target.value)*parseFloat(formik.values.valor_unitario_produto.replace(",","."))).toFixed(2).replace(".",","))
                        }else{
                          formik.setFieldValue("valor_venda_produto",'')
                        }
                        
                     }}
                     error={formik.touched.quantidade_produto && Boolean(formik.errors.quantidade_produto)}
                     helperText={formik.touched.quantidade_produto && formik.errors.quantidade_produto}
                     InputLabelProps={{
                       shrink: true,
                     }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                <TextField 
                     label="Uni R$"
                     margin="normal"
                      
                     size={'small'}
                     key={resetForm}
                     id="valor_unitario_produto"
                     name="valor_unitario_produto"
                     value={formik.values.valor_unitario_produto}
                     onChange={(e)=>{
                  
                       formik.setFieldValue("valor_unitario_produto",e.target.value)
                       if(e.target.value !== '' && formik.values.quantidade_produto !== ''){
                         formik.setFieldValue("valor_venda_produto",(parseFloat(e.target.value)*formik.values.quantidade_produto).toFixed(2).replace(".",","))
                       }else{
                         formik.setFieldValue("valor_venda_produto",'')
                       }
                       
                    }}
                     error={formik.touched.valor_unitario_produto && Boolean(formik.errors.valor_unitario_produto)}
                     helperText={formik.touched.valor_unitario_produto && formik.errors.valor_unitario_produto}
                     InputLabelProps={{
                       shrink: true,
                     }}
                  />
                </GridItem> 
                <GridItem xs={12} sm={12} md={2}>
                <TextField 
                     label="Total R$"
                     margin="normal"
                   
                     size={'small'}
                     key={resetForm}
                     id="valor_venda_produto"
                     name="valor_venda_produto"
                     value={formik.values.valor_venda_produto}
                     onChange={formik.handleChange}
                     error={formik.touched.valor_venda_produto && Boolean(formik.errors.valor_venda_produto)}
                     helperText={formik.touched.valor_venda_produto && formik.errors.valor_venda_produto}
                     InputLabelProps={{
                       shrink: true,
                     }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1} style={{display:'grid'}}>
                <IconButton size="small" color="primary" aria-label="edit item" onClick={handleAddProduto}>
                    <AddIcon />
                </IconButton>
                </GridItem>                                                                   
            </GridContainer>
            <Divider variant="middle" />
            <Scrollbars style={{ height: 'calc(100vh - 420px)' }}>
            <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{minWidth:120,fontSize:12}} >Cliente</TableCell>
                  <TableCell style={{minWidth:120,fontSize:12}}>Profissional</TableCell>
                  <TableCell style={{minWidth:150,fontSize:12}}>Serviço/Produto</TableCell>
                  <TableCell style={{minWidth:60,fontSize:12}}>Comissão</TableCell>
                  <TableCell style={{minWidth:60,fontSize:12}}>Quantidade</TableCell>
                  <TableCell style={{minWidth:40,fontSize:12}}>Valor</TableCell>
                  <TableCell style={{minWidth:40,fontSize:12}}>Fidelidade</TableCell>
                  <TableCell style={{minWidth:40,fontSize:12}}>Pontos</TableCell>
                  <TableCell style={{minWidth:30,fontSize:12}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itensComanda.map((row,key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row" style={{minWidth:120,fontSize:12}}>
                      {listaClientes.reduce((p,a)=>a.cod_cliente === row.cliente? a.nome:p,'N/A')}
                    </TableCell>
                    <TableCell style={{minWidth:120,fontSize:12}} >{listaProfissional.reduce((p,a)=>a.cod_profissional === row.funcionario? a.nome:p,'N/A') }</TableCell>
                    <TableCell style={{minWidth:120,fontSize:12}}>{row.tipo == 'servico' ? listaServicos.reduce((p,a)=>a.cod_servico === row.servico_produto? row.fidelidade ? `${a.servico} ${row.complemento_fidelidade}` : a.servico:p,'N/A') :listaProdutos.reduce((p,a)=>a.cod_produto === row.servico_produto? row.fidelidade ? `${a.produto} ${row.complemento_fidelidade}` : a.produto:p,'N/A')}</TableCell>
                    <TableCell style={{minWidth:150,fontSize:12}}>{row.tipo == 'servico' ? listaComissoes.reduce((p,a)=>a.cod_comissao === row.comissao? a.comissao:p,'N/A') :`PRODUTO;${row.comissao}%`}</TableCell>
                    <TableCell style={{minWidth:60,fontSize:12}}>{row.quantidade}</TableCell>
                    <TableCell style={{minWidth:40,fontSize:12}}>{row.valor}</TableCell>
                    <TableCell style={{minWidth:40,fontSize:12}}>{row.fidelidade ? 'SIM' : 'NÃO'}</TableCell>
                    <TableCell style={{minWidth:40,fontSize:12}}>{row.pontos_fidelidade}</TableCell>
                    <TableCell style={{minWidth:30,fontSize:12}}>

                      <IconButton size="small" color="secondary" aria-label="delete item" onClick={()=>handleRemoveItem(key)}>
                      <DeleteIcon />
                    </IconButton>             
                    </TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
          </Table>
          </Scrollbars>
      </GridItem>
          <GridItem xs={12} sm={12} md={2}>
              <TextField 
                  fullWidth
                     label="Valor Total R$"
                     margin="normal"
                     variant="filled"
                     disabled  
                     size={'small'}
                     id="valor_total_comanda"
                     name="valor_total_comanda"
                     value={formik.values.valor_total_comanda}
                     onChange={formik.handleChange}
                     error={formik.touched.valor_total_comanda && Boolean(formik.errors.valor_total_comanda)}
                     helperText={formik.touched.valor_total_comanda && formik.errors.valor_total_comanda}
                     InputLabelProps={{
                       shrink: true,
                     }}
                     style={{marginBottom:'30px'}}
                  />

              <TextField 
                  fullWidth
                     label="Pontos"
                     margin="normal"
                     variant="filled"
                     disabled  
                     size={'small'}
                     id="pontos_total_comanda"
                     name="pontos_total_comanda"
                     value={formik.values.pontos_total_comanda}
                     onChange={formik.handleChange}
                     error={formik.touched.pontos_total_comanda && Boolean(formik.errors.pontos_total_comanda)}
                     helperText={formik.touched.pontos_total_comanda && formik.errors.pontos_total_comanda}
                     InputLabelProps={{
                       shrink: true,
                     }}
                     style={{marginBottom:'30px'}}
                  />
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}  locale={"pt-br"} >
                    <DatePicker
                    style={{marginBottom:'30px'}}
                      
                      fullWidth
                      InputLabelProps={{
                                        shrink: true,
                                      }}
                      id="data_de_pagamento"
                      label="Data de Pagamento"
                      format="DD/MM/YYYY"
                      value={formik.values.data_de_pagamento}
                      onChange={date => formik.setFieldValue('data_de_pagamento', date._d, false)}
                      error={formik.touched.data_de_pagamento && Boolean(formik.errors.data_de_pagamento)}
                      helperText={formik.touched.data_de_pagamento && formik.errors.data_de_pagamento}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Autocomplete
                        style={{marginBottom:'20px'}}
                        id="cod_meio_pagamento"
                        MeioPagamento
                        loading
                        noOptionsText={'Meio de Pagamento Não localizada'}
                        size={'small'}
                        key={resetFormFim}
                        options={listaMeioPagamento.map((option) => option.forma_de_pagamento)}
                        onInputChange={(e,value)=>{
                          formik.setFieldValue("cod_meio_pagamento",listaMeioPagamento.reduce((p,a)=>a.forma_de_pagamento === value? a.cod_forma_de_pagamento:p,''))
                        }}
                        renderInput={(params) => (
                            <TextField 
                              {...params} 
                              label="Meio de Pagamento" 
                              margin="normal" 
                             
                              name="cod_meio_pagamento"
                              error={formik.touched.cod_meio_pagamento && Boolean(formik.errors.cod_meio_pagamento)}
                              helperText={formik.touched.cod_meio_pagamento && formik.errors.cod_meio_pagamento}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                        )}
                />
              {listaMeioPagamento.reduce((p,a)=>a.cod_forma_de_pagamento === formik.values.cod_meio_pagamento && a.forma_de_pagamento == 'DINHEIRO' ? true:p,false) ? 
              
              <Fragment>
              <TextField 
                  fullWidth
                     label="Valor Entregue"
                     margin="normal"
                 
                      
                     size={'small'}
                     id="valor_entregue"
                     name="valor_entregue"
                     value={formik.values.valor_entregue}
                     onChange={(e)=>{
                       formik.setFieldValue('valor_entregue',e.target.value)
                       if(formik.values.valor_total_comanda !== "" && e.target.value !==""){
                        formik.setFieldValue('troco',(parseFloat(e.target.value) - parseFloat(formik.values.valor_total_comanda.replace(",","."))).toFixed(2).replace(".",","))
                       }else{
                        formik.setFieldValue('troco',"")
                       }
                     }}
                     error={formik.touched.valor_entregue && Boolean(formik.errors.valor_entregue)}
                     helperText={formik.touched.valor_entregue && formik.errors.valor_entregue}
                     InputLabelProps={{
                       shrink: true,
                     }}
                    
                  />
                <TextField 
                  fullWidth
                     label="Troco"
                     margin="normal"
                     variant="filled"
                     disabled  
                     size={'small'}
                     id="troco"
                     name="troco"
                     value={formik.values.troco}
                     onChange={formik.handleChange}
                     error={formik.touched.troco && Boolean(formik.errors.troco)}
                     helperText={formik.touched.troco && formik.errors.troco}
                     InputLabelProps={{
                       shrink: true,
                     }}
                     
                  />
                  </Fragment> : null}
      </GridItem> 
      </GridContainer>
        </DialogContent>

        <DialogActions>
        <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCheckPoints} color="primary">
            PROGRAMA FIDELIDADE
          </Button>

          <Button onClick={handleSalvar} color="primary">
            Salvar Comanda
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
       
        open={openPoints}
        onClose={handleClosePoints}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="responsive-dialog-title">{`Pontos de ${listaClientes.reduce((p,a)=>a.cod_cliente == formik.values.cod_cliente  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.nome:p,'')}`}</DialogTitle>
        <DialogContent>
          {loadingPoints ? <LinearProgress style={{ marginTop: "20px" }} /> : null}
          <DialogContentText>
          <Scrollbars style={{ height: 'calc(100vh - 400px)' ,marginTop:'30px'}}>
                  <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                       
                        <TableCell style={{minWidth:150,fontSize:10,padding:0}}>Ano Referencia</TableCell>
                        <TableCell style={{minWidth:200,fontSize:10,padding:0}}>Data</TableCell>
                        <TableCell style={{minWidth:150,fontSize:10,padding:0}}>Serviço/Produto</TableCell>
                        <TableCell style={{minWidth:200,fontSize:10,padding:0}}>Pontos</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Tipo</TableCell>
                        <TableCell style={{minWidth:80,fontSize:10,padding:0}}>Status</TableCell>
                       
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listaPontosCliente.map((row,key) => (
                        <TableRow key={key} style={{fontSize:'10px'}}>
                          <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{row.ano_referencia}</TableCell>
                          <TableCell  style={{minWidth:200,fontSize:10,padding:0}}>{moment(row.data).format("DD/MM/YYYY")}</TableCell>
                          <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{row.produto}</TableCell>
                          <TableCell  style={{minWidth:200,fontSize:10,padding:0}}>{row.pontos}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{row.tipo_de_item}</TableCell>
                          <TableCell  style={{minWidth:80,fontSize:10,padding:0}}>{row.status}</TableCell>
                          
                        </TableRow>
                      ))}
                      
                    </TableBody>
                </Table>
                
                </Scrollbars >
                <GridContainer>
                
                <TextField
                  variant="filled"
                  disabled 
                  label="Pontos Gerados"
                  margin="normal"
                  size={'small'}
                  id="pontos_totais"
                  name="pontos_totais"
                  value={listaPontosCliente.reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos;},0)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width:'120px',padding:'5px'}}
                /> 
               <TextField
                  variant="filled"
                  disabled 
                  label="Pontos Trocados"
                  margin="normal"
                  size={'small'}
                  id="pontos_totais"
                  name="pontos_totais"
                  value={listaPontosCliente.reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos;},0)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width:'120px',padding:'5px'}}
                /> 

                <TextField
                  variant="filled"
                  disabled 
                  label="Pontos Expirados"
                  margin="normal"
                  size={'small'}
                  id="pontos_expirado"
                  name="pontos_expirado"
                  value={listaPontosCliente.filter(x=>{return x.status == 'EXPIRADO'}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos;},0)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width:'120px',padding:'5px'}}
                /> 

              <TextField
                  variant="filled"
                  disabled 
                  label="PontosComanda"
                  margin="normal"
                  size={'small'}
                  id="pontos_comanda"
                  name="pontos_comanda"
                  value={itensComanda.filter(x=>{return x.fidelidade == true}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos_fidelidade;},0)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width:'120px',padding:'5px'}}
                /> 
                <TextField
                  variant="filled"
                  disabled 
                  label="Pontos Disponível"
                  margin="normal"
                  size={'small'}
                  id="pontos_disponiveis"
                  name="pontos_disponiveis"
                  value={(listaPontosCliente.filter(x=>{return x.status == 'ATIVO'}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos;},0)) - (itensComanda.filter(x=>{return x.fidelidade == true}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos_fidelidade;},0))}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{width:'120px',padding:'5px'}}
                /> 
              </GridContainer> 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleChangePoints} color="primary">
            Trocar pontos ativos
          </Button>
          <Button onClick={handleClosePoints} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
       
       open={openServicoDeTroca}
       onClose={handleCloseChangePoints}
       aria-labelledby="responsive-dialog-title"
       classes={{ paper: classes.dialogPaper }}
     >
       <DialogTitle id="responsive-dialog-title">{`Você poderá trocar pelos produtos/serviços listados | Saldo atual de Pontos ativos : ${(listaPontosCliente.filter(x=>{return x.status == 'ATIVO'}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos;},0)) - (itensComanda.filter(x=>{return x.fidelidade == true}).reduce(function(acumulador, valorAtual, index, array) {return acumulador + valorAtual.pontos_fidelidade;},0))}`}</DialogTitle>
       <DialogContent>
         {loadingServicoDeTroca ? <LinearProgress style={{ marginTop: "20px" }} /> : null}
         <DialogContentText>
         <Scrollbars style={{ height: 'calc(100vh - 420px)' }}>
            <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{minWidth:150,fontSize:10,padding:0}} >Item</TableCell>
                  <TableCell style={{minWidth:150,fontSize:10,padding:0}}>Categoria</TableCell>
                  <TableCell style={{minWidth:150,fontSize:10,padding:0}}>Quantidade</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
              {listaServiosDeTroca.map((row,key) => (
                        <TableRow key={key} style={{fontSize:'10px'}} onClick={()=>handleAddOrRemoveListSelected(row)} selected = {listaDeSelecaoDeTroca.map(item=> item.cod_ponto).includes(row.cod_ponto)}>
                          <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{`${row.produto_servico} ${row.complemento}`}</TableCell>
                          <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{row.tipo_origem}</TableCell>
                          <TableCell  style={{minWidth:150,fontSize:10,padding:0}}>{row.quantidade}</TableCell>

                          
                        </TableRow>
                      ))}
              </TableBody>
          </Table>
          </Scrollbars>

         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button autoFocus  onClick={handleConvertPointToServiceOrProduct} color="primary">
           Ok
         </Button>
         <Button onClick={handleCloseChangePoints} color="primary" autoFocus>
           Cancelar
         </Button>
       </DialogActions>
     </Dialog>
     <Dialog
       
       open={openClassificacaoDeComissao}
       onClose={handleCloseClassificacaoDeComissao}
       aria-labelledby="responsive-dialog-title"
       classes={{ paper: classes.dialogPaper }}
     >
       <DialogTitle id="responsive-dialog-title">{`Classifique as comissões para os pontos de categoria Serviço`}</DialogTitle>
       <DialogContent>
         {loadingClassificacaoDeComissao ? <LinearProgress style={{ marginTop: "20px" }} /> : null}
         <DialogContentText>

         {listaDeSelecaoDeTroca.map((item,key)=>(
          <GridContainer style={{marginTop:"0px"}} >
                <GridItem xs={12} sm={12} md={6}>
                <TextField 
                     label="Serviço"
                     margin="normal"
                     style={{width: '90%'}}
                     disabled 
                   
                     key={key}
                     id={key}
                     name={key}
                     value={`${item.produto_servico} ${item.complemento}`}
                    
                    
                     InputLabelProps={{
                       shrink: true,
                     }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <Autocomplete
                        id={key}
                       
                        loading
                        noOptionsText={'Comissao Não localizada'}
                        size={'small'}
                        key={resetForm}
                        options={listaComissoes.map((option) => option.comissao)}
                        onInputChange={(e,value)=>{
                          console.log(item,value)
                          applyComissaoPonto(item,value)
                        }} 
                        renderInput={(params) => (
                            <TextField 
                              {...params} 
                              label="Comissao" 
                              margin="normal" 
                             
                              name="cod_comissao_servico_ponto"
                           
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                        )}
                />
                </GridItem>
            </GridContainer>
         ))}


         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button autoFocus   color="primary">
           Ok
         </Button>
         <Button onClick={handleCloseClassificacaoDeComissao} color="primary" autoFocus>
           Cancelar
         </Button>
       </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        //TransitionComponent={transition}
        message={message}
        //key={transition ? transition.name : ''}
      />
      <Drawer anchor={"bottom"} open={openDelete.action} onClose={closeDelete} >
        <div >
          <List component="nav" aria-label="main mailbox folders">
              <ListItem >
               <h4> Você deseja realmente remover {openDelete.message} ?</h4>
              <Fab style={{right:60,position:'fixed'}} size="medium" color="secondary" aria-label="add" onClick={()=>handleConfimDelete(openDelete.referencia)}>
                <DeleteIcon />
              </Fab> 
              </ListItem>
          </List>
          
        </div>
      </Drawer>
    </div>
  );
}
