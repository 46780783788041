
import React,{useState,useEffect}from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddSharpIcon from '@material-ui/icons/PlaylistAddSharp';
import Cliente from "views/Cliente/Cliente"
import ClienteLista from "views/Cliente/ClienteLista"

// core components

export default function ClienteGestao() {
  const [value, setValue] = useState(0)
  const [clienteSelecionado,setCliente] = useState({})
  const [modeCliente,setMode] = useState("create")

  const handleChangeView  = (visao,payload,mode)=>{
    setCliente(payload)
    setMode(mode)
    setValue(1) 
  }

  if(localStorage.getItem('cod_estabelecimento') == 'null'){
    return <Redirect to="/login"  />
 } 

  return (
    <div>
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        if(newValue == 1 ){
        }else{
          setValue(newValue);
        }
      }}
      showLabels
    >
      <BottomNavigationAction label="Clientes" icon={<PlaylistAddSharpIcon />} />
    </BottomNavigation>
    {value === 0 ? (<ClienteLista handleChangeView={handleChangeView}/>):(<Cliente mode={modeCliente} teste={clienteSelecionado}/>)}
    </div>
  );
}