import React ,{useState,useEffect}from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';


import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'





const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardRecipient:{
    height:"calc(100vh - 180px)"
  }
};

const useStyles = makeStyles(styles);

export default function ListaPontos({handleChangeView}) {
  const componente = 'pontosporprodutoservico'
  const classes = useStyles();

  const [listaPontos,setLista] = useState([])
  const [listaFiltrada,setListaFiltrada] = useState([])
  const [loading,setLoading] = useState(true)
  const [openDelete, setopenDelete] = useState({action:false,referencia:"",message:""});
  const [updateList,setUpdatelist]  = useState(0)
  const [termos,setTermos] = useState('')
  const [servicos,setServicos] = useState([])
  const [produtos,setProdutos] = useState([])


  useEffect(()=>{
    ApiConnectorTemplate.list('produtos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      console.log(r)
      setProdutos(r)
    })
  },[]) 

  useEffect(()=>{
    ApiConnectorTemplate.list('servicos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      console.log(r)
      setServicos(r)
    })
  },[]) 

  useEffect(()=>{
    setLoading(true)
    ApiConnectorTemplate.list(componente,{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
    
      console.log(r)
      setLista(r)
      setListaFiltrada(r)
      setLoading(false)
    })
    .catch(error=>{
        setLoading(false)
    })
  },[updateList])

  function handleView(id){
    handleChangeView(1,listaPontos.reduce((p,a)=>a.cod_ponto === id? a:p,{}),'view')
  }

  function handleEdit(id){
    handleChangeView(1,listaPontos.reduce((p,a)=>a.cod_ponto === id? a:p,{}),'edit')
  }

  function handleCreate(){
    handleChangeView(1,{},'create')
  }

  function handleConfimDelete(referencia){
  
     ApiConnectorTemplate.delete(componente,referencia).then(r=>{
      closeDelete()
      setUpdatelist(updateList + 1)
    }) 
  }

  function handleDelete(id){
    let item = listaPontos.reduce((p,a)=>a.cod_ponto === id ? `${a.tipo_origem}`:p,{})
    
    setopenDelete({action:true,referencia:id,message:item})
  }

  function closeDelete(){
    setopenDelete({action:false,referencia:"",message:""})
  }

  function buildActions(id){
    return <div style={{textAlign:"right"}}>
    <IconButton size="small" color="secondary" aria-label="delete item" onClick={()=>handleDelete(id)}>
      <DeleteIcon />
    </IconButton>
    <IconButton size="small" color="primary" aria-label="edit item" onClick={()=>handleEdit(id)}>
      <EditIcon />
    </IconButton>
    <IconButton size="small" color="primary" aria-label="view item" onClick={()=>handleView(id)}>
      <VisibilityIcon />
    </IconButton>
  </div>
  }

  function termosDePesquisa(e){
    setTermos(e.target.value)
  }

  function Search(){
    console.log('buscando')
    let keys = termos.split(' ');
    let lista = [...listaPontos];

    lista = lista.map(item=>{
        item.referencia_desc = item.tipo_origem === "Produto" ? produtos.reduce((p,a)=>a.cod_produto === item.referencia? a.produto:p,''):servicos.reduce((p,a)=>a.cod_servico === item.referencia? a.servico:p,'')
        return item
    })

/*     lista = lista.map(item=>{
        item.referencia = item.tipo_origem === "Produto" ? produtos.reduce((p,a)=>a.cod_produto === item.referencia? a.produto:p,''):servicos.reduce((p,a)=>a.cod_servico === item.referencia? a.servico:p,'')
        return item
    }) */

    lista = lista.filter(item=>{
        /* let bodyConsulta = item.nome */
        
        let str = JSON.stringify(item);
        return keys.reduce((p,a)=> str.toUpperCase().indexOf(a.toUpperCase()) >=0 && p !== false,null);
        //return str.toUpperCase().indexOf(filtro.toUpperCase()) >=0;
    })


    setListaFiltrada(lista)
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className={classes.cardRecipient}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Itens disponíveis para resgate de Pontos</h4>
            <p className={classes.cardCategoryWhite}>
              Edição e exclusão de itens por pontos
            </p>
          </CardHeader>
          <CardBody>
            <div>
              {loading ? <LinearProgress style={{marginBottom:"10px"}}/> : null }
              
              <Fab style={{right:60,position:'absolute',background:"black"}} size="medium" color="primary" aria-label="add" onClick={handleCreate}>
                <AddIcon />
              </Fab> 
            </div>
            <GridContainer >
              <GridItem xs={12} sm={12} md={8} style={{marginTop:"40px"}}>
                <TextField fullWidth  id="standard-basic" label="Filtre por Descrição"  onChange={(e)=>termosDePesquisa(e)}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{marginTop:"40px"}}>
              <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={Search}>
                  <SearchIcon />
                </IconButton>
              </label>
              </GridItem>
            </GridContainer>
            <Table
              tableHeaderColor="primary"
              tableHead={["Tipo de Item",  "Descricao","Pontos de Resgate","",""]}
              tableData={
                listaFiltrada.map(pontos=>{
                    let descricaoFormatada = pontos.tipo_origem === "Produto" ? produtos.reduce((p,a)=>a.cod_produto === pontos.referencia? a.produto:p,''):servicos.reduce((p,a)=>a.cod_servico === pontos.referencia? a.servico:p,'')
                    
                return  [pontos.tipo_origem,`${descricaoFormatada} ${pontos.complemento ?pontos.complemento : '' }`,pontos.quantidade,"", buildActions(pontos.cod_ponto)]
              })
            }
            />
          </CardBody>

        </Card>
      </GridItem>
      <Drawer anchor={"bottom"} open={openDelete.action} onClose={closeDelete} >
        <div >
          <List component="nav" aria-label="main mailbox folders">
              <ListItem >
               <h4> Você deseja realmente remover {openDelete.message} da lista ?</h4>
              <Fab style={{right:60,position:'fixed'}} size="medium" color="secondary" aria-label="add" onClick={()=>handleConfimDelete(openDelete.referencia)}>
                <DeleteIcon />
              </Fab> 
              </ListItem>
          </List>
          
        </div>
      </Drawer>
    </GridContainer>
  );
}






