/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import React from "react";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import StoreIcon from '@material-ui/icons/Store';
import TodaySharpIcon from '@material-ui/icons/TodaySharp';
import PeopleIcon from '@material-ui/icons/People';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import SvgIcon from '@material-ui/core/SvgIcon';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// core components/views for Admin layout
import ProfissionalContainer from "views/Profissional/ProfissionalContainer"
import BarbeariaContainer from "views/Barbearia/BarbeariaContainer"
import ClienteContainer from "views/Cliente/ClienteContainer"
import ServicosContainer from "views/Servicos/ServicosContainer"
import ProdutosContainer from "views/Produtos/ProdutosContainer"
import ComissoesContainer from "views/Comissoes/ComissoesContainer"
import DespesasContainer from "views/Despesas/DespesasContainer"
import PontosContainer from "views/Pontos/PontosContainer"
import ComandaContainer from "views/Comanda/ComandaContainer"
import BalancoContainer from "views/Balanco/BalancoContainer"
import LancamentosContainer from "views/Lancamentos/LancamentosContainer"
import AcertoContainer from "views/Acertos/AcertosContainer"
import AgendaContainer from "views/Agenda/AgendaContainer.js"
import DashboardPage from "views/Dashboard/Dashboard.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
import ListAltIcon from '@material-ui/icons/ListAlt';
import ScheduleIcon from '@material-ui/icons/Schedule';
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";


function CuteIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><circle cx="6" cy="18" fill="none" r="2"/><circle cx="12" cy="12" fill="none" r=".5"/><circle cx="6" cy="6" fill="none" r="2"/><path d="M9.64 7.64c.23-.5.36-1.05.36-1.64 0-2.21-1.79-4-4-4S2 3.79 2 6s1.79 4 4 4c.59 0 1.14-.13 1.64-.36L10 12l-2.36 2.36C7.14 14.13 6.59 14 6 14c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4c0-.59-.13-1.14-.36-1.64L12 14l7 7h3v-1L9.64 7.64zM6 8c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm0 12c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm6-7.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5.5.22.5.5-.22.5-.5.5zM19 3l-6 6 2 2 7-7V3z"/>
    </SvgIcon>
  );
}

function ShipIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0zm18.31 6l-2.76 5z" fill="none"/><path d="M11 9h2V6h3V4h-3V1h-2v3H8v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-9.83-3.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4h-.01l-1.1 2-2.76 5H8.53l-.13-.27L6.16 6l-.95-2-.94-2H1v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.13 0-.25-.11-.25-.25z"/>
    </SvgIcon>
  );
}

function MoneyComission(props){
  return(
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none"/><path d="M12.5 6.9c1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-.53.12-1.03.3-1.48.54l1.47 1.47c.41-.17.91-.27 1.51-.27zM5.33 4.06L4.06 5.33 7.5 8.77c0 2.08 1.56 3.21 3.91 3.91l3.51 3.51c-.34.48-1.05.91-2.42.91-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c.96-.18 1.82-.55 2.45-1.12l2.22 2.22 1.27-1.27L5.33 4.06z"/>
    </SvgIcon>
  )
}

function Financial(props){
  return(
    <SvgIcon {...props}>
      <rect fill="none" height="24" width="24"/><g><path d="M19.83,7.5l-2.27-2.27c0.07-0.42,0.18-0.81,0.32-1.15C17.96,3.9,18,3.71,18,3.5C18,2.67,17.33,2,16.5,2 c-1.64,0-3.09,0.79-4,2l-5,0C4.46,4,2,6.46,2,9.5S4.5,21,4.5,21l5.5,0v-2h2v2l5.5,0l1.68-5.59L22,14.47V7.5H19.83z M13,9H8V7h5V9z M16,11c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C17,10.55,16.55,11,16,11z"/></g>
    </SvgIcon>
  )
}

const dashboardRoutes = [
/*   {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
    
  },  */
  {
    path: "/agenda",
    name: "Agenda",
    rtlName: "Agenda",
    icon: ScheduleIcon,
    component: AgendaContainer,
    layout: "/admin"
  },
  {
    path: "/balanco",
    name: "Balanço",
    rtlName: "Balanco",
    icon: TrendingUpIcon,
    component: BalancoContainer,
    layout: "/admin"
  },
  {
    path: "/comanda",
    name: "Comanda",
    rtlName: "Comanda",
    icon: ListAltIcon,
    component: ComandaContainer,
    layout: "/admin"
  },
  {
    path: "/lancamentos",
    name: "Despesas/Estoques",
    rtlName: "Lançamentos",
    icon: ListAltIcon,
    component: LancamentosContainer,
    layout: "/admin"
  }, 
  {
    path: "/acertos",
    name: "Acertos",
    rtlName: "Acertos",
    icon: AccountBalanceWalletIcon,
    component: AcertoContainer,
    layout: "/admin"
  },    
  {
    path: "/barbearia",
    name: "Barbearia",
    rtlName: "Barbearia",
    icon: StoreIcon,
    component: BarbeariaContainer,
    layout: "/admin"
  },
  {
    path: "/profissionais",
    name: "Profissionais",
    rtlName: "Profissional",
    icon: Person,
    component: ProfissionalContainer,
    layout: "/admin"
  },
  {
    path: "/servicos",
    name: "Servicos",
    rtlName: "Servicos",
    icon: CuteIcon,
    component: ServicosContainer,
    layout: "/admin"
  },
  {
    path: "/produtos",
    name: "Produtos",
    rtlName: "Produtos",
    icon: ShipIcon,
    component: ProdutosContainer,
    layout: "/admin"
  },
  {
    path: "/clientes",
    name: "Clientes",
    rtlName: "Clientes",
    icon: PeopleIcon,
    component: ClienteContainer,
    layout: "/admin"
  },
  {
    path: "/comissoes",
    name: "Tipos de Comissões",
    rtlName: "Comissões",
    icon: MoneyComission,
    component: ComissoesContainer,
    layout: "/admin"
  },
  {
    path: "/despesas",
    name: "Tipos de Despesas",
    rtlName: "Despesas",
    icon: Financial,
    component: DespesasContainer,
    layout: "/admin"
  },
  {
    path: "/pontos",
    name: "Cadastro de Pontos",
    rtlName: "Cadastro de Pontos",
    icon: GpsFixedIcon,
    component: PontosContainer,
    layout: "/admin"
  },
/*   {
    path: "/agenda",
    name: "Agenda",
    rtlName: "Agenda",
    icon: TodaySharpIcon,
    component: AgendaContainer,
    layout: "/admin"
  }, */
  /* {
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin"
  },
 */

/*   {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl"
  }, */
/*   {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin"
  } */
];


export default dashboardRoutes;
