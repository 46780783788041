 import React ,{useState,useEffect} from "react";
 import { Router, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from '@material-ui/core/TextField';
import Icon from "@material-ui/core/Icon";
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import styles from "assets/jss/material-dashboard-react/views/loginPage.js";

import image from "assets/img/sidebar-9.jpg";
import logo from "assets/img/logo.png";
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'
import { useFormik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  cod_estabelecimento: yup
  .string('Informe o nome fantasia')
  .required('Estabelecimento é um dado obrigatório'),
    
});

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [isLogin,setLogin] = useState(false)
  const [user,setUser] = useState('')
  const [pass,setPass] = useState('')
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [message,setMessage] = useState('')
  const [loading,setLoading] = useState(false)
  const [listaEstabelecimentos, setListaEstabelecimento] = useState([]);

  useEffect(()=>{
    if(open == true){
        Promise.all([
            ApiConnectorTemplate.list('estabelecimento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}),

        ])
        .then(values=>{
            console.log(values)
            setListaEstabelecimento(values[0])
        })
        .catch(error=>{
            console.log(error)
        })
    }
},[open])

const formik = useFormik({
  initialValues: {
   cod_estabelecimento: ''
 }, 
validationSchema: validationSchema,
 onSubmit: (values) => {
   console.log(values)
   localStorage.setItem('cod_estabelecimento',values.cod_estabelecimento)
   setLogin(true)

 },
});

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;


  async function handleLogin(){
    setLoading(true);
      try{
        const response = await ApiConnectorTemplate.list('login',{usuario:user,senha:pass})
        console.log(response)
        setLoading(false);
        localStorage.setItem('cod_estabelecimento',response.cod_estabelecimento)
        localStorage.setItem('perfil',response.cod_perfil)
        localStorage.setItem('token',response.token)
        localStorage.setItem('estabelecimento',response.estabelecimento)
        localStorage.setItem('usuario',response.usuario)
        setLogin(true)
      }catch(error){
        console.log(error.status)
        if(error.status == 401){
          setLoading(false);
          setTransition(() => TransitionLeft);
          setOpenSnack(true);
          setMessage('Usuário ou senha inválido')
        }else if(error.status == 402){
          setLoading(false);
          setTransition(() => TransitionLeft);
          setOpenSnack(true);
          setMessage('A licença desse estabelecimento esta expirada')
        }else{
          setLoading(false);
          setTransition(() => TransitionLeft);
          setOpenSnack(true);
          setMessage('Não foi possivel logar')
        }

       
      }
/*     console.log(user,pass)
    if(pass === 'admin' && user === "admin"){
      //setLogin(true)
      setOpen(true)
    } */
    
 
  }

  function handleUser(e){
    console.log(e)
  }

  
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  if(isLogin){
    return <Redirect to="/admin"  />
  }


  return (
   
    <div>

      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={4} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Login</h4>
{/*                     <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <TwitterIcon/>
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <FacebookIcon/>
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <Icon className={"fab fa-google-plus-g"} />
                      </Button>
                    </div> */}
                  </CardHeader>
                  <p className={classes.divider}><img src={logo} alt="logo"  style={{width: "90px",top: "10px",verticalAlign: "middle",border: "0"}} /></p>
                  <CardBody style={{height: "200px"}}>
{/*                     <CustomInput
                      labelText="First Name..."
                      id="first"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    /> */}
                    <TextField id="user" label="Usuario"
                    style={{marginTop:"15px"}}
                                        fullWidth
                                        inputProps={{
                                          type: "text",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <People className={classes.inputIconsColor} />
                                            </InputAdornment>
                                          )
                                        }}
                                        onChange={(event)=>{
                                          setUser(event.target.value)
                                        }}
                    />
                    <TextField id="spass" label="Senha"
                    style={{marginTop:"15px"}}
                                        fullWidth
                                        inputProps={{
                                          type: "password",
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <People className={classes.inputIconsColor} />
                                            </InputAdornment>
                                          )
                                        }}
                                        onChange={(event)=>{
                                          setPass(event.target.value)
                                        }}
                    />
    {/*                 <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    /> */}
                    <p style={{height:'20px'}}>{loading ? <LinearProgress    /> : null}</p>
                     
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                 
                    <Button simple color="primary" size="lg" onClick={()=>handleLogin()}>
                      Login
                    </Button>
                    
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
     {/*    <Footer whiteFont /> */}
      </div>
      <Dialog 
        open={open}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xs'}
        >
        <DialogTitle id="form-dialog-title">Acessso ADMINISTRADOR</DialogTitle>
        <DialogContent>
            <p style={{textAlign:'center'}}>Selecione um estabelecimento para prosseguir</p>
            <GridContainer style={{marginTop:'20px',marginBottom:'20px'}}>
            <GridItem xs={12} sm={12} md={12}>
                <InputLabel htmlFor="select-estabelecimento" style={{fontSize: "12px",marginBottom: "4px"}}>Estabelecimento</InputLabel>
                          <Select
                    
                            fullWidth
                            labelId="select-estabelecimento"
                            id="cod_estabelecimento"
                            name="cod_estabelecimento"
                            value={formik.values.cod_estabelecimento}
                            onChange={formik.handleChange}
                            error={formik.touched.cod_estabelecimento && Boolean(formik.errors.cod_estabelecimento)}
                            helperText={formik.touched.cod_estabelecimento && formik.errors.cod_estabelecimento}
                          >
                           {listaEstabelecimentos.map(estabelecimento=>
                                <MenuItem key={estabelecimento.cod_estabelecimento} value={estabelecimento.cod_estabelecimento}>{estabelecimento.estabelecimento}</MenuItem>
                            )}
                        </Select>
                </GridItem>              
            </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={formik.handleSubmit} color="primary">
            Continuar Login
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />
    </div>
  );
}
 