import React,{useState,useEffect} from "react";
// @material-ui/core components
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
/* import CustomInput from "components/CustomInput/CustomInput.js"; */
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import avatar from "assets/img/logo.png"
// dependencias para lidar com formulário
import { useFormik } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";

// operacoes assincronas
import PublicApiConnector from 'connectors/publicapiconnector.js'
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  description:{
    textAlign: "justify"
  },
  cardRecipient:{
    height:"calc(100vh - 180px)"
  }


  
};






const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  tipo_de_despesa: yup
  .string('Informe a Comissão')
  .required('Comissão é um dado obrigatório'),
  cod_estabelecimento: yup
  .string('Informe o estabelecimento')
  .required('Estabelecimento é um dado obrigatório'),

     
});


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}


export default function Barbearia({mode,teste}) {
 
  const componente = 'tiposdedespesas'
  const classes = useStyles();
  const formik = useFormik({
     initialValues: {
      tipo_de_despesa: mode === 'view' || mode === 'edit' ? teste.tipo_de_despesa : '',
      cod_estabelecimento: mode === 'view' || mode === 'edit' ? teste.cod_estabelecimento : '',
    }, 
   validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
     if(mode === "create"){
        criarTipoDeDespesa(componente,values)
      }else if(mode === "edit"){
        let payload = {...values}
        payload.cod_tipo_de_despesa = teste.cod_tipo_de_despesa
        editarTipoDeDespesa(componente,payload)
      }
    },
  });


  const [estabelecimentos,setEstabelecimentos] = useState([])
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [loading,setLoading] = useState(false)
  const [message,setMessage] = useState('')


/*   const handleClick = (Transition) => () => {
    console.log('entrou')
    setTransition(() => Transition);
    setOpen(true);
  }; */

  const handleClose = () => {
    setOpen(false);
  };

  async function criarTipoDeDespesa(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.add(componente,payload)
   
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Tipo de Despesa Registrada')
      formik.resetForm()
      
    }catch(error){
      console.log(error)
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Houve um erro e não foi possível registrar')

    }
  }

  async function editarTipoDeDespesa(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.update(componente,payload)
    
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Tipo de Despesa Atualizada')
      //formik.resetForm()
      
    }catch(error){
      console.log(error)
      if(error.status === 401){
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('Não foram encontradas alterações para realizar')
      }
    }
  }

  useEffect(()=>{
    ApiConnectorTemplate.list('estabelecimento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      console.log(r)
      setEstabelecimentos(r)
    })
  },[]) 


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.cardRecipient}>
            <CardHeader color="primary" /* style={{background:"black"}} */>
              <h4 className={classes.cardTitleWhite}>Dados Cadastrais Tipos de Despesas</h4>
              <p className={classes.cardCategoryWhite}>
              Edição e Exclusão de Tipos de Despesas
              </p>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="tipo_de_despesa"
                    name="tipo_de_despesa"
                    label="Tipos de Despesa"
                    value={formik.values.tipo_de_despesa}
                    onChange={formik.handleChange}
                    error={formik.touched.tipo_de_despesa && Boolean(formik.errors.tipo_de_despesa)}
                    helperText={formik.touched.tipo_de_despesa && formik.errors.tipo_de_despesa}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer >

                <GridItem xs={12} sm={12} md={4}>
                <InputLabel htmlFor="select-estabelecimento" style={{fontSize: "12px",marginBottom: "4px"}}>Estabelecimento</InputLabel>
                          <Select
                            disabled ={mode == 'create' || mode == 'edit' ? false : true}
                            fullWidth
                            labelId="select-estabelecimento"
                            id="cod_estabelecimento"
                            name="cod_estabelecimento"
                            value={formik.values.cod_estabelecimento}
                            onChange={formik.handleChange}
                            error={formik.touched.cod_estabelecimento && Boolean(formik.errors.cod_estabelecimento)}
                            helperText={formik.touched.cod_estabelecimento && formik.errors.cod_estabelecimento}
                          >
                           {estabelecimentos.map(estabelecimento=>
                                <MenuItem key={estabelecimento.cod_estabelecimento} value={estabelecimento.cod_estabelecimento}>{estabelecimento.estabelecimento}</MenuItem>
                            )}
                        </Select>
                </GridItem>

              </GridContainer  >
             {loading ? <LinearProgress style={{marginTop:"20px"}} /> : null}
            </CardBody>
            <CardFooter>
              {mode == 'create' ? <Button onClick={formik.handleSubmit} color="primary">Criar Tipo de Despesa</Button> : mode == 'edit' ? <Button onClick={formik.handleSubmit}  color="primary">Editar Tipo de Despesa</Button>:null}
              
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />


    </div>
  );
}
