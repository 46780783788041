import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import IconButton from '@material-ui/core/IconButton';
import TableCustom from "components/Table/Table.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import BlockIcon from '@material-ui/icons/Block';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'
import { Formik, useFormik } from 'formik';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import * as yup from 'yup';
import { Fragment } from "react";
import ListaCliente from "views/Cliente/ClienteLista";
import { Search } from "@material-ui/icons";
import ListaTiposDeDespesas from "views/Despesas/DespesasLista";


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,

    position: 'relative',
    minHeight: 500,
    paddingLeft: 0,
    paddingRight: 0
  },
  fab: {
    position: 'absolute',
    bottom:theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

const validationSchema = yup.object({
  /*
    cod_cliente: yup
    .string('Informe o Cliente')
    .required('Cliente é um dado obrigatório'),
     cod_profissional: yup
    .string('Informe o Profissional')
    .required('Profissional é um dado obrigatório'),
    cod_produto:  yup
    .string('Informe o Produto')
    .required('Produto é um dado obrigatório'),
    cod_comissao: yup
    .string('Informe a Comissao Fixa')
    .required('Comissao Fixa é um dado obrigatório'),
    quantidade_produto: yup
    .string('Informe a Quantidade de Produto')
    .required('Quantidade de Produto é um dado obrigatório'),
    valor_unitario_produto:  yup
    .string('Informe o Valor Unitário do Produto')
    .required('Valor Unitário do Produto é um dado obrigatório'),
    valor_venda_produto: yup
    .string('Informe o Valor de Venda')
    .required('Valor de Venda é um dado obrigatório'),
    cod_servico:  yup
    .string('Informe o Serviço')
    .required('Serviço é um dado obrigatório'),
    cod_comissao_servico:  yup
    .string('Informe a Comissão de Serviço')
    .required('Comissão de Serviço é um dado obrigatório'),
    valor_venda_servico: yup
    .string('Informe o Valor de Venda')
    .required('Valor de Vendaé um dado obrigatório'), */



});

export default function LancamentosContainer(TransitionLeft) {
  const classes = useStyles();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      cod_tipo_de_conta: '',
      cod_tipo_de_despesa: '',
      descricao_despesa: '',
      valor_despesa: '',
      data_de_pagamento: null,
      data_de_vencimento: null,
      cod_produto: '',
      valor_produto: '',
      quantidade_produto: '',
      valor_total: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openPagamento, setOpenPagamento] = useState({ abertura: false, cod_despesas: null });
  const [openCancelamento, setOpenCancelamento] = useState({ abertura: false, cod_despesas: null });
  const [openEntradas, setOpenEntradas] = useState({ abertura: false });
  const [openSaidas, setOpenSaidas] = useState({ abertura: false });
  const [openLancamentoEstoque, setOpenLancamentoEstoque] = useState(false)
  const [openSnack, setOpenSnak] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [message, setMessage] = useState('')
  const [listaTipoDeConta, setTipoDeConta] = useState([])
  const [listaDeDespesas, setListaDepesas] = useState([])
  const [listaEntradas, setListaEntradas] = useState([])
  const [listaSaidas, setListaSaidas] = useState([])
  const [listaEstoque, setListaEstoque] = useState([])
  const [itensDespesas, setItensListaDespesas] = useState([])
  const [itensEstoque, setItensListaEstoque] = useState([])
  const [resetForm, setResetForm] = useState(1)
  const [dataInicialPeriodo, setDataIncial] = useState()
  const [dataFinalPeriodo, setDataFinal] = useState()
  const [tipoDeFiltro, setTipoDeFiltro] = useState(0)
  const [listaRelatorio, setListaRelatorio] = useState([])
  const [listaFiltrada, setListaFiltrada] = useState([])
  const [dataLancamentoPagamento, setDataLancamentoPagamento] = useState(null)
  const [valorLancamentoPagamento, setValorLancamentoPagamento] = useState(null)
  const [dataLancamentoCancelamento, setDataLancamentoCancelamento] = useState(null)


  useEffect(() => {
    let data = formatDate()
    setDataIncial(data)
    setDataFinal(data)

  }, [])

  useEffect(() => {
    let tipoDeConta = [
      { cod_tipo_de_conta: 0, tipo: 'Conta Paga' },
      { cod_tipo_de_conta: 1, tipo: 'Conta à Pagar' },
      { cod_tipo_de_conta: 2, tipo: 'Cancelada' }
    ]
    setTipoDeConta(tipoDeConta)
  }, [])


  useEffect(() => {
    Promise.all([
      ApiConnectorTemplate.list('tiposdedespesas', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento') }),
      ApiConnectorTemplate.list('lancamentodeestoque', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento') })
    ])
      .then(values => {
        setListaDepesas(values[0])
        setListaEstoque(values[1])
      })
      .catch(error => {
        console.log(error)
      })

  }, [])



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const formatDate = () => {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenLancamentoEstoque = () => {
    setOpenLancamentoEstoque(true)
  }

  const handleClickOpenPagamento = (despesa) => {
    setOpenPagamento({ abertura: true, cod_despesas: despesa });
  };

  const handleClickOpenCancelamento = (despesa) => {
    setOpenCancelamento({ abertura: true, cod_despesas: despesa });
  };

  const handleClose = () => {
    formik.resetForm()
    setItensListaDespesas([])
    setOpen(false);
  };

  const handleClosePagamento = () => {
    setOpenPagamento({ abertura: false, cod_despesas: null });
  };

  const handleCloseLancamentoEstoque = () => {
    setOpenLancamentoEstoque(false)
  }

  const handleCloseCancelamento = () => {
    setOpenCancelamento({ abertura: false, cod_despesas: null });
  };

  const handleCloseSnack = () => {
    setOpenSnak(false);
  };

  //handleCloseEntradas
  const handleCloseEntradas = () => {
    setOpenEntradas({ abertura: false });
  };

  const handleCloseSaidas = () => {
    setOpenSaidas({ abertura: false });
  };

  const abreEntradas = async (produto) => {
    setOpenEntradas({ abertura: true });
    ApiConnectorTemplate.list('estoqueentradas', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento'), 'cod_produto': produto })
      .then(r => {

        setListaEntradas(r)
      })
      .catch(r => {
        setTransition(() => TransitionLeft);
        setOpenSnak(true);
        setMessage('Não foi possivel carregar')

      })
  };

  const abreSaidas = async (produto) => {
    setOpenSaidas({ abertura: true });
    ApiConnectorTemplate.list('estoquesaidas', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento'), 'cod_produto': produto })
      .then(r => {

        setListaSaidas(r)
      })
      .catch(r => {
        setTransition(() => TransitionLeft);
        setOpenSnak(true);
        setMessage('Não foi possivel carregar')

      })
  };

  const listaEstoqueGeral = async () => {

    ApiConnectorTemplate.list('lancamentodeestoque', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento') })
      .then(r => {

        setListaEstoque(r)
      })
      .catch(r => {
        setTransition(() => TransitionLeft);
        setOpenSnak(true);
        setMessage('Não foi possivel carregar')

      })
  };



  const handleSalvar = async () => {
    if (itensDespesas.length > 0) {
      console.log(itensDespesas)
      ApiConnectorTemplate.add('lancamentodedespesas', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento'), itensdespesas: itensDespesas })
        .then(r => {
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Lançamento Realizado')
          handleClose()
        })
        .catch(r => {
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Falha no lançamento')
          handleClose()
        })

    } else {
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Não há itens para lançar')
    }
  }

  const handleEntradaEstoque = () => {
    if (itensEstoque.length > 0) {
      console.log(itensEstoque)
      ApiConnectorTemplate.add('lancamentodeestoque', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento'), itensestoque: itensEstoque })
        .then(r => {
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Lançamento Realizado')
          setItensListaEstoque([])
          listaEstoqueGeral()

          handleCloseLancamentoEstoque()
        })
        .catch(r => {
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Falha no lançamento')
          handleCloseLancamentoEstoque()
        })

    } else {
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Não há itens para lançar')
    }
  }

  const handleAddDespesa = () => {
    let { cod_tipo_de_conta, cod_tipo_de_despesa, descricao_despesa, valor_despesa, data_de_pagamento, data_de_vencimento } = formik.values
    if (cod_tipo_de_conta === '' || cod_tipo_de_despesa === '' || descricao_despesa === '' || valor_despesa === '') {

      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Certifique se que os campos estejam preenchido')
    } else {
      if (cod_tipo_de_conta == 0) {
        if (data_de_pagamento == null) {
          console.log('b')
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Certifique se que os campos estejam preenchidos')
        } else {

          console.log(data_de_pagamento)
          console.log('eeeeeeeee')
          let obj = {}
          obj.cod_tipo_de_conta = cod_tipo_de_conta
          obj.cod_tipo_de_despesa = cod_tipo_de_despesa
          obj.descricao_despesa = descricao_despesa
          obj.valor_despesa = valor_despesa
          obj.data_de_pagamento = data_de_pagamento.toLocaleDateString('pt-BR').split('/').reverse().join('-')
          obj.data_de_vencimento = null
          let t = [obj]
          setItensListaDespesas([...itensDespesas, ...t])
          setResetForm(resetForm + 1)
          formik.resetForm()
        }
      } else if (cod_tipo_de_conta == 1) {
        if (data_de_vencimento == null) {
          console.log('c')
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Certifique se que os campos estejam preenchidos')
        } else {
          let obj = {}
          obj.cod_tipo_de_conta = cod_tipo_de_conta
          obj.cod_tipo_de_despesa = cod_tipo_de_despesa
          obj.descricao_despesa = descricao_despesa
          obj.valor_despesa = valor_despesa
          obj.data_de_pagamento = null
          obj.data_de_vencimento = data_de_vencimento

          let t = [obj]
          setItensListaDespesas([...itensDespesas, ...t])
          setResetForm(resetForm + 1)
          formik.resetForm()
        }
      }
    }
  }

  const handleAddItemListaEstoque = () => {
    let { cod_produto, valor_produto, quantidade_produto, valor_total } = formik.values

    if (cod_produto === '' || valor_produto === '' || quantidade_produto === '' || valor_total === '') {

      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Certifique se que os campos estejam preenchido')
    } else {
      let obj = {}
      obj.cod_produto = cod_produto
      obj.valor_produto = valor_produto
      obj.quantidade_produto = quantidade_produto
      obj.valor_total = valor_total

      let t = [obj]
      setItensListaEstoque([...itensEstoque, ...t])
      setResetForm(resetForm + 1)
      formik.resetForm()
    }
  }


  const handleRemoveItem = (key) => {
    let t = []
    itensDespesas.forEach((element, index) => {
      if (index !== key) {
        t.push(element)
      }
    });

    setItensListaDespesas(t)
  }

  const handleRemoveItemEstoque = (key) => {
    let t = []
    itensEstoque.forEach((element, index) => {
      if (index !== key) {
        t.push(element)
      }
    });
    setItensListaEstoque(t)
  }


  const handleSearchRelatorio = () => {

    ApiConnectorTemplate.list('lancamentodedespesas', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento') }).then(results => {
      setListaRelatorio(results)
      console.log(tipoDeFiltro)
      console.log(results)
      let filtradas = results.filter(x => {
        if (tipoDeFiltro == 3) {
          return x
        } else {
          return x.tipo_de_conta == tipoDeFiltro
        }
      })
      setListaFiltrada(filtradas)
    })
  }

  const lancarPagamento = () => {
    console.log(dataLancamentoPagamento)

    if (dataLancamentoPagamento && valorLancamentoPagamento) {
      console.log(itensDespesas)
      ApiConnectorTemplate.add('lancamentopagamentodedespesas', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento'), data_pagamento: dataLancamentoPagamento, valor_pagamento: valorLancamentoPagamento, referencia_despesa: listaFiltrada.reduce((p, a) => a.cod_despesas === openPagamento.cod_despesas ? a : p, null) })
        .then(r => {
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Pagamento Realizado')
          setDataLancamentoPagamento(null)
          setValorLancamentoPagamento(null)
          setOpenPagamento({ abertura: false, cod_despesas: null })
          handleSearchRelatorio()
        })
        .catch(r => {
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Falha no lançamento')
          setOpenPagamento({ abertura: false, cod_despesas: null })
        })

    } else {
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Preencha todos os campos')
    }

  }

  const cancelarDespesa = () => {

    if (dataLancamentoCancelamento) {
      console.log(itensDespesas)
      ApiConnectorTemplate.add('cancelamentodedespesas', { cod_estabelecimento: localStorage.getItem('cod_estabelecimento'), data_cancelamento: dataLancamentoCancelamento, referencia_despesa: listaFiltrada.reduce((p, a) => a.cod_despesas === openCancelamento.cod_despesas ? a : p, null) })
        .then(r => {
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Cancelamento Realizado')
          setDataLancamentoCancelamento(null)
          setOpenCancelamento({ abertura: false, cod_despesas: null })
          handleSearchRelatorio()
        })
        .catch(r => {
          setTransition(() => TransitionLeft);
          setOpenSnak(true);
          setMessage('Falha no lançamento')
          setOpenPagamento({ abertura: false, cod_despesas: null })
        })

    } else {
      setTransition(() => TransitionLeft);
      setOpenSnak(true);
      setMessage('Preencha todos os campos')
    }

  }

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: 'primary',
      className: classes.fab,
      icon: <AddIcon />,
      label: 'Add',
      callback: handleClickOpen
    },
    {
      color: 'secondary',
      className: classes.fab,
      icon: <ShoppingCartIcon />,
      label: 'Edit',
      callback: handleClickOpenLancamentoEstoque
    }

  ];




  if (localStorage.getItem('cod_estabelecimento') == 'null') {
    return <Redirect to="/login" />
  }


  return (
    <div className={classes.root} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
      <AppBar position="static" color="default">
        <Tabs
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <Tab label="Despesas" {...a11yProps(0)} />
          <Tab label="Estoque" {...a11yProps(1)} />
          {/* <Tab label="Estoque" {...a11yProps(2)} />  */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ height: "calc(100vh - 130px)"}}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>

          <GridContainer >
            <GridItem xs={12} sm={12} md={8}>
              <InputLabel htmlFor="select-tipode" style={{ fontSize: "12px", marginBottom: "4px" }}>Tipo De Lançamento :</InputLabel>
              <Select

                fullWidth
                labelId="select-tipode"
                id="tipode"
                name="tipode"
                value={tipoDeFiltro}
                onChange={(e) => setTipoDeFiltro(e.target.value)}

              >
                <MenuItem value={0}>Pagas</MenuItem>
                <MenuItem value={1}>Abertas</MenuItem>
                <MenuItem value={2}>Canceladas</MenuItem>
                <MenuItem value={3}>Todas</MenuItem>
              </Select>
            </GridItem>
            <IconButton size="small" color="primary" aria-label="edit item" onClick={handleSearchRelatorio}>
              <SearchIcon />
            </IconButton>
          </GridContainer>
          <Scrollbars style={{ height: 550, marginTop: '30px' }}>
            <Table stickyHeader className={classes.table} size="small" aria-label="sticky table"style={{ height: '90%' }} >
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 15, fontSize: 12, padding: 0 }}></TableCell>
                  <TableCell style={{ minWidth: 15, fontSize: 12, padding: 0 }}></TableCell>
                  <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Tipo de Conta</TableCell>
                  <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Tipo de Despesa</TableCell>
                  <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Descrição</TableCell>
                  <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Data de Vencimento</TableCell>
                  <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Data de Pagamento</TableCell>
                  <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Valor</TableCell>
                  <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listaFiltrada.map((row, key) => (

                  <TableRow key={key} style={{ fontSize: '10px' }}>
                    <TableCell style={{ minWidth: 15, fontSize: 12, padding: 0 }}>
                      <IconButton size="small" color="secondary" aria-label="delete item" onClick={() => handleClickOpenCancelamento(row.cod_despesas)} >
                        <BlockIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ minWidth: 15, fontSize: 12, padding: 0 }}>
                      <IconButton size="small" color="primary" aria-label="pay item" onClick={() => handleClickOpenPagamento(row.cod_despesas)} >
                        <MonetizationOnIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{listaTipoDeConta.reduce((p, a) => a.cod_tipo_de_conta == row.tipo_de_conta ? a.tipo : p, 'N/A')}</TableCell>
                    <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{listaDeDespesas.reduce((p, a) => a.cod_tipo_de_despesa == row.tipo_de_despesas ? a.tipo_de_despesa : p, 'N/A')}</TableCell>
                    <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.descricao}</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.data_de_vencimento ? moment(row.data_de_vencimento).format("DD/MM/YYYY") : ''}</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.data_de_pagamento ? moment(row.data_de_pagamento).format("DD/MM/YYYY") : ''}</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.valor.toFixed(2).replace(".", ",")}</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.tipo_de_conta == 0 ? 'Pago' : row.tipo_de_conta == 1 ? "Pendente" : row.tipo_de_conta == 2 ? "Cancelado" : ""}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbars >
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>

          <Scrollbars style={{ height: 'calc(100vh - 210px)', marginTop: '30px' }}>
            <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 15, fontSize: 12, padding: 0 }}></TableCell>
                  <TableCell style={{ minWidth: 15, fontSize: 12, padding: 0 }}></TableCell>
                  <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Descrição</TableCell>
                  <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Entrada</TableCell>
                  <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Saída</TableCell>
                  <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Estoque Atual</TableCell>
                  <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Preço médio Compra</TableCell>
                  <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Preço médio venda</TableCell>
                  <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Lucro médio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listaEstoque.map((row, key) => (

                  <TableRow key={key} style={{ fontSize: '10px' }}>
                    <TableCell style={{ minWidth: 15, fontSize: 12, padding: 0 }}>
                      <IconButton size="small" style={{ color: 'green' }} aria-label="movimentacao item" onClick={() => abreEntradas(row.cod_produto)}  >
                        <CompareArrowsIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ minWidth: 15, fontSize: 12, padding: 0 }}>
                      <IconButton size="small" style={{ color: 'red' }} aria-label="entrada item" onClick={() => abreSaidas(row.cod_produto)} >
                        <CompareArrowsIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.produto}</TableCell>
                    <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.entradas}</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.saidas}</TableCell>
                    <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.estoque_atual}</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.preco_medio_compra}</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.preco_medio_venda}</TableCell>

                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.lucro_medio}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbars >
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel>
      </SwipeableViews>
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          <Fab aria-label={fab.label} className={fab.className} color={fab.color} onClick={fab.callback}>
            {fab.icon}
          </Fab>
        </Zoom>
      ))}
      <Dialog
        open={open}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}

      >
        <DialogTitle style={{ paddingBottom: "2px" }} id="form-dialog-title">Lançamento de Despesas</DialogTitle>
        <DialogContent style={{ minHeight: '410px', paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px" }}>
          <GridContainer style={{ marginTop: "0px" }} >
            <GridItem xs={12} sm={12} md={12}>

              <GridContainer style={{ marginTop: "0px" }} >

                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    id="cod_tipo_de_conta"
                    noOptionsText={'Nenhum Tipo Localizado'}
                    size={'small'}
                    key={resetForm}
                    onInputChange={(e, value) => {
                      formik.setFieldValue("cod_tipo_de_conta", listaTipoDeConta.reduce((p, a) => a.tipo === value ? a.cod_tipo_de_conta : p, ''))
                    }}
                    TipoDeConta
                    options={listaTipoDeConta.filter(x => x.cod_tipo_de_conta < 2).map((option) => option.tipo)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Conta"
                        margin="normal"

                        name="cod_tipo_de_conta"
                        error={formik.touched.cod_tipo_de_conta && Boolean(formik.errors.cod_tipo_de_conta)}
                        helperText={formik.touched.cod_tipo_de_conta && formik.errors.cod_tipo_de_conta}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    id="cod_tipo_de_despesa"
                    TipoDeDespesa
                    noOptionsText={'Nenhum Tipo de Despesa Localizado'}
                    size={'small'}
                    key={resetForm}
                    onInputChange={(e, value) => {
                      formik.setFieldValue("cod_tipo_de_despesa", listaDeDespesas.reduce((p, a) => a.tipo_de_despesa === value && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento') ? a.cod_tipo_de_despesa : p, ''))
                    }}
                    options={listaDeDespesas.map((option) => option.tipo_de_despesa)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Despesa"
                        margin="normal"

                        id="cod_tipo_de_despesa"
                        name="cod_tipo_de_despesa"
                        error={formik.touched.cod_tipo_de_despesa && Boolean(formik.errors.cod_tipo_de_despesa)}
                        helperText={formik.touched.cod_tipo_de_despesa && formik.errors.cod_tipo_de_despesa}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ marginTop: "0px" }} >
                <GridItem xs={12} sm={12} md={5}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    margin="normal"
                    size={'small'}
                    key={resetForm}
                    id="descricao_despesa"
                    name="descricao_despesa"
                    value={formik.values.descricao_despesa}
                    onChange={(e) => {
                      formik.setFieldValue("descricao_despesa", e.target.value)
                    }}
                    error={formik.touched.descricao_despesa && Boolean(formik.errors.descricao_despesa)}
                    helperText={formik.touched.descricao_despesa && formik.errors.descricao_despesa}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"pt-br"} >
                    {formik.values.cod_tipo_de_conta == 0 ?
                      <DatePicker
                        style={{ marginTop: '13px' }}

                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="data_de_pagamento"
                        label="Data de Pagamento"
                        format="DD/MM/YYYY"
                        value={formik.values.data_de_pagamento}
                        onChange={date => formik.setFieldValue('data_de_pagamento', date._d, false)}
                        error={formik.touched.data_de_pagamento && Boolean(formik.errors.data_de_pagamento)}
                        helperText={formik.touched.data_de_pagamento && formik.errors.data_de_pagamento}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      /> :
                      <DatePicker
                        style={{ marginTop: '13px' }}

                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="data_de_vencimento"
                        label="Data de Vencimento"
                        format="DD/MM/YYYY"
                        value={formik.values.data_de_vencimento}
                        onChange={date => formik.setFieldValue('data_de_vencimento', date._d, false)}
                        error={formik.touched.data_de_vencimento && Boolean(formik.errors.data_de_vencimento)}
                        helperText={formik.touched.data_de_vencimento && formik.errors.data_de_vencimento}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    }
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <TextField
                    label="Valor"
                    margin="normal"
                    size={'small'}
                    id="valor_despesa"
                    name="valor_despesa"
                    value={formik.values.valor_despesa}
                    onChange={formik.handleChange}
                    error={formik.touched.valor_despesa && Boolean(formik.errors.valor_despesa)}
                    helperText={formik.touched.valor_despesa && formik.errors.valor_despesa}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1} style={{ display: 'grid' }}>
                  <IconButton size="small" color="primary" aria-label="edit item" onClick={handleAddDespesa}>
                    <AddIcon />
                  </IconButton>
                </GridItem>
              </GridContainer>
              <Divider variant="middle" />
              <Scrollbars style={{ height: 200 }}>
                <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Tipo de Conta</TableCell>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Tipo de Despesa</TableCell>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Descrição</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12 }}>Data de Vencimento</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Data de Pagamento</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Valor</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {itensDespesas.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row" style={{ minWidth: 120, fontSize: 12 }}>
                          {listaTipoDeConta.reduce((p, a) => a.cod_tipo_de_conta === row.cod_tipo_de_conta ? a.tipo : p, 'N/A')}
                        </TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }} >{listaDeDespesas.reduce((p, a) => a.cod_tipo_de_despesa === row.cod_tipo_de_despesa ? a.tipo_de_despesa : p, 'N/A')}</TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.descricao_despesa}</TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.data_de_vencimento ? moment(row.data_de_vencimento).format("DD/MM/YYYY") : ''}</TableCell>
                        <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{row.data_de_pagamento ? moment(row.data_de_pagamento).format("DD/MM/YYYY") : ''}</TableCell>
                        <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{parseFloat(row.valor_despesa.replace(",", ".")).toFixed(2).replace(".", ",")}</TableCell>
                        <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>
                          <IconButton size="small" color="secondary" aria-label="delete item" onClick={() => handleRemoveItem(key)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbars>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSalvar} color="primary">
            Lançar Despesas
          </Button>
        </DialogActions>
      </Dialog>
      {/*Lancamento das acaoes de pagamento */}
      <Dialog
        open={openPagamento.abertura}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClosePagamento}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ paddingBottom: "2px" }} id="form-dialog-title">Lançamento de Pagamentos</DialogTitle>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"pt-br"} >

                <DatePicker
                  style={{ marginTop: '13px' }}

                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="data_de_pagamento"
                  label="Data de Pagamento"
                  format="DD/MM/YYYY"
                  value={dataLancamentoPagamento}
                  onChange={date => setDataLancamentoPagamento(new Date(date._d))} 

                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                label="Valor de Pagamento"
                margin="normal"
                size={'small'}
                id="valor_depagamento"
                name="valor_depagamento"
                onChange={(e) => setValorLancamentoPagamento(e.target.value)}
                value={valorLancamentoPagamento}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePagamento} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={lancarPagamento}>
            Lançar Pagamento
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancelamento.abertura}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleCloseCancelamento}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ paddingBottom: "2px" }} id="form-dialog-title">Cancelamento</DialogTitle>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"pt-br"} >
                <DatePicker
                  style={{ marginTop: '13px' }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="data_de_cancelamento"
                  label="Data de Cancelamento"
                  format="DD/MM/YYYY"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  onChange={date => setDataLancamentoCancelamento(date._d)}
                  value={dataLancamentoCancelamento}

                />
              </MuiPickersUtilsProvider>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelamento} color="primary">
            Cancelar
          </Button>
          <Button color="primary" onClick={cancelarDespesa} >
            Lançar Cancelamento
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openLancamentoEstoque}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}

      >
        <DialogTitle style={{ paddingBottom: "2px" }} id="form-dialog-title">Entrada de Produto</DialogTitle>
        <DialogContent style={{ minHeight: '410px', paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px" }}>
          <GridContainer style={{ marginTop: "0px" }} >
            <GridItem xs={12} sm={12} md={4}>
              <Autocomplete
                id="cod_produto"
                noOptionsText={'Nenhum Produto Localizado'}
                size={'small'}
                key={resetForm}
                onInputChange={(e, value) => {
                  formik.setFieldValue("cod_produto", listaEstoque.reduce((p, a) => a.produto === value ? a.cod_produto : p, ''))
                }}
                TipoDeConta
                options={listaEstoque.map((option) => option.produto)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Produto"
                    margin="normal"

                    name="cod_produto"
                    error={formik.touched.cod_produto && Boolean(formik.errors.cod_produto)}
                    helperText={formik.touched.cod_produto && formik.errors.cod_produto}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}

              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <TextField
                label="Quantidade"
                margin="normal"

                size={'small'}
                key={resetForm}
                id="quantidade_produto"
                name="quantidade_produto"
                value={formik.values.quantidade_produto}
                onChange={(e) => {
                  if (formik.values.valor_produto !== '' && e.target.value !== '') {
                    let conta = parseFloat(e.target.value.replace(",", ".")) * parseFloat(formik.values.valor_produto.replace(",", "."))
                    formik.setFieldValue("valor_total", conta.toFixed(2).replace(".", ","))
                  } else {
                    formik.setFieldValue("valor_total", 0)
                  }
                  formik.setFieldValue("quantidade_produto", e.target.value)
                }}
                error={formik.touched.quantidade_produto && Boolean(formik.errors.quantidade_produto)}
                helperText={formik.touched.quantidade_produto && formik.errors.quantidade_produto}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <TextField
                label="Valor de Compra"
                margin="normal"

                size={'small'}
                key={resetForm}
                id="valor_produto"
                name="valor_produto"
                value={formik.values.valor_produto}
                onChange={(e) => {
                  console.log(e.target.value)
                  formik.setFieldValue("valor_produto", e.target.value)
                  if (formik.values.quantidade_produto !== '' && e.target.value !== '') {

                    let conta = parseFloat(e.target.value.replace(",", ".")) * parseFloat(formik.values.quantidade_produto.replace(",", "."))
                    formik.setFieldValue("valor_total", conta.toFixed(2).replace(".", ","))
                  } else {
                    formik.setFieldValue("valor_total", 0)
                  }

                }}
                error={formik.touched.valor_produto && Boolean(formik.errors.valor_produto)}
                helperText={formik.touched.valor_produto && formik.errors.valor_produto}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <TextField
                label="Valor Total"
                margin="normal"
                disabled
                size={'small'}
                key={resetForm}
                id="valor_total"
                name="valor_total"
                value={formik.values.valor_total}
                onChange={(e) => {
                  formik.setFieldValue("valor_total", e.target.value)
                }}
                error={formik.touched.valor_total && Boolean(formik.errors.valor_total)}
                helperText={formik.touched.valor_total && formik.errors.valor_total}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={1} style={{ display: 'grid' }}>
              <IconButton size="small" color="primary" aria-label="edit item" onClick={handleAddItemListaEstoque}>
                <AddIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
          <GridItem>
            <Scrollbars style={{ height: 200 }}>
              <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Descrição</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Quantidade</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Valor</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12 }}>Valor Total</TableCell>
                    <TableCell style={{ minWidth: 60, fontSize: 12 }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itensEstoque.map((row, key) => (
                    <TableRow key={key}>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 5 }}>{listaEstoque.reduce((p, a) => a.cod_produto === row.cod_produto ? a.produto : p, '')}</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{parseFloat(row.quantidade_produto.replace(",", ".")).toFixed(2).replace(".", ",")}</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{parseFloat(row.valor_produto.replace(",", ".")).toFixed(2).replace(".", ",")}</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12 }}>{parseFloat(row.valor_total.replace(",", ".")).toFixed(2).replace(".", ",")}</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>
                        <IconButton size="small" color="secondary" aria-label="delete item" onClick={() => handleRemoveItemEstoque(key)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbars>
          </GridItem>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLancamentoEstoque} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEntradaEstoque} color="primary">
            Lançar Entrada
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEntradas.abertura}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleCloseEntradas}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle style={{ paddingBottom: "2px" }} id="form-dialog-title">Entradas de Estoque</DialogTitle>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Scrollbars style={{ height: 320, marginTop: '30px' }}>
                <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>


                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Produto</TableCell>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Quantidade</TableCell>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Valor unitário</TableCell>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Valor Total</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaEntradas.map((row, key) => (
                      <TableRow key={key} style={{ fontSize: '10px' }}>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.produto}</TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.quantidade}</TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.valor_unitario}</TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.valor_total}</TableCell>
                        <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{moment(row.createdAt).format("DD/MM/YYYY")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbars >

            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEntradas} color="primary">
            Cancelar
          </Button>

        </DialogActions>
      </Dialog>
      <Dialog
        open={openSaidas.abertura}
        // disableBackdropClick
        disableEscapeKeyDown
        onClose={handleCloseEntradas}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle style={{ paddingBottom: "2px" }} id="form-dialog-title">Sáida de Estoque</DialogTitle>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Scrollbars style={{ height: 320, marginTop: '30px' }}>
                <Table stickyHeader className={classes.table} size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>


                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Produto</TableCell>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Quantidade</TableCell>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Valor</TableCell>
                      <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>Cliente</TableCell>
                      <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>Data</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaSaidas.map((row, key) => (
                      <TableRow key={key} style={{ fontSize: '10px' }}>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.produto}</TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.quantidade}</TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.valor}</TableCell>
                        <TableCell style={{ minWidth: 100, fontSize: 12, padding: 0 }}>{row.nome}</TableCell>
                        <TableCell style={{ minWidth: 60, fontSize: 12, padding: 0 }}>{moment(row.createdAt).format("DD/MM/YYYY")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Scrollbars >

            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaidas} color="primary">
            Cancelar
          </Button>

        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnack}
        onClose={handleCloseSnack}
        //TransitionComponent={transition}
        message={message}
      //key={transition ? transition.name : ''}
      />
    </div>
  );
}
