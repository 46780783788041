import React ,{useState,useEffect}from "react";
import { Fragment } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'
import GridItem from "components/Grid/GridItem.js";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';

import { Calendar, momentLocalizer,Views } from "react-big-calendar"
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment"
import "moment/locale/pt-br";
import GridContainer from "components/Grid/GridContainer";
import Autocomplete from '@material-ui/lab/Autocomplete';
//...
const localizer = momentLocalizer(moment) 







export default function AgendaContainer() {

  const formik = useFormik({
    initialValues: {
     cod_servico:  '',
     cod_profissional:'',
     cod_cliente:''
   }, 
   validationSchema: validationSchema,
   onSubmit: (values) => {
    console.log(values)
   },
   validateOnChange:false,
   validateOnBlur:false
 });

 const validationSchema = yup.object({
 cod_servico: yup
    .string('Informe o Serviço')
    .required('Serviço é um dado obrigatório'),
  cod_profissional: yup
    .string('Informe o Profissional')
    .required('Profissional é um dado obrigatório'),
  cod_cliente: yup
    .string('Informe o Cliente')
    .required('Cliente é um dado obrigatório'),

          
  });
 
  // const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [eventSelected,setEventSelected] = useState(false);
  const [inicioEvento,setInicioEvento]= useState(false);
  const [fimEvento,setFimEvento]= useState(false);
  const [listaServicos,setListaServicos] = useState([])
  const [listaProfissionais,setListaProfissionais] = useState([])
  const [funcaoTodos,setFuncaoTodos] = useState(true)
  const [listaCliente,setListaCliente] = useState([])
  const [listaEventos,setEventos] = useState([])
  const [listaEventosFiltrados,setEventosFiltrados] = useState([])
  const [profissionalFiltro,setProfissionalFiltro] = useState({cod_profissional:0,min:new Date(0,0,0,6,0,0),max:new Date(0,0,0,23,0,0),step:30})
  const [currentView,setCurrentView] = useState('week')

  useEffect(()=>{
    ApiConnectorTemplate.list('servicos',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      setListaServicos(r)
    })
    .catch(error=>{   
    })
  },[])

  useEffect(()=>{
    ApiConnectorTemplate.list('profissional',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      if(localStorage.getItem('perfil')== 3){
          let listaFiltradaPermitida = r.filter(x=>x.usuario == localStorage.getItem('usuario'))
          setListaProfissionais(listaFiltradaPermitida)
          setFuncaoTodos(false)

      }else{
        setListaProfissionais(r)
        setFuncaoTodos(true)
      }
      
    })
    .catch(error=>{ 
    })
  },[])

  useEffect(()=>{
    ApiConnectorTemplate.list('cliente',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      setListaCliente(r)
    })
    .catch(error=>{ 
    })
  },[])


  useEffect(()=>{
    searchAgenda()
  },[])


  useEffect(()=>{
    handleFiltrarListaEventos()
  },[listaEventos])

  useEffect(()=>{
    handleFiltrarListaEventos()
  },[profissionalFiltro])
  
  const handleClickOpen = (slotInfo) => {
    setInicioEvento(slotInfo.start)
    setFimEvento(slotInfo.end)

    console.log(currentView,profissionalFiltro.cod_profissional)
    if(currentView == 'day' || currentView == 'week'){
       if(profissionalFiltro.cod_profissional != 0){
        setOpen(true);
       }
    }

    
  };

  const handleClose = () => {
    setOpen(false);
    searchAgenda()
  };

  const handleCloseEvent = () => {
    setOpenEvent(false);
  };

  const handleProfissionalAgendado = (idProfissional)=>{
    console.log('eitaaaaaaaaaaaaaaaaaaaaaaaaa',idProfissional)
    console.log(listaProfissionais)
    let profissionalCheck = listaProfissionais.reduce((p,a)=>a.cod_profissional == idProfissional? a:p,null)
    if(profissionalCheck){

      let inicioJornada = profissionalCheck.hora_inicio.split(":")
      let fimJornada = profissionalCheck.hora_fim.split(":")
      let step = profissionalCheck.tempo_de_trabalho
      
      let min = new Date(0,0,0,inicioJornada[0],inicioJornada[1],0)
      let max = new Date(0,0,0,fimJornada[0],fimJornada[1],0)

      let objProfissionalSelecionado = {
          cod_profissional:profissionalCheck.cod_profissional,
          min:min,
          max:max,
          step:step
      }
      setProfissionalFiltro(objProfissionalSelecionado)
    }else{
      console.log('profisisonal genericos')
      let objProfissionalSelecionado = {
        cod_profissional:0,
        min:new Date(0,0,0,6,0,0),
        max:new Date(0,0,0,23,0,0),
        step:30
    }
    setProfissionalFiltro(objProfissionalSelecionado)
    }
    
  }

  const handleAgendar = ()=>{
    
    let novaLista = [...listaEventos]
    let profissional = listaProfissionais.reduce((p,a)=>a.cod_profissional == formik.values.cod_profissional? a.nome:p,'') 
    let servico = listaServicos.reduce((p,a)=>a.cod_servico === formik.values.cod_servico? a.servico:p,'')
    let cliente = listaCliente.reduce((p,a)=>a.cod_cliente === formik.values.cod_cliente? a.nome:p,'')
    let payload =  {
      id: novaLista.length + 1,
      title: `${servico}`,
      nomeProfissional:profissional,
      nomeServico:servico,
      nomeCliente:cliente,
      profissional:formik.values.cod_profissional,
      servico:formik.values.cod_servico,
      cliente:formik.values.cod_cliente,
      allDay: false,
      start: new Date(inicioEvento),
      end: new Date(fimEvento),
      type:'interno',
      cliente_externo:null
    }
  

    ApiConnectorTemplate.add('agenda',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),...payload}).then(r=>{
      setOpen(false);
      searchAgenda()
    })
    .catch(error=>{   
    })
    
  }

  const searchAgenda = ()=>{
    ApiConnectorTemplate.list('agenda',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
     console.log('retono da agenda',r)
        let eventosAgenda = r.map(item=>{
              let o =  {
                id: item.cod_agendamento,
                title: `${item.nome_cliente ? item.nome_cliente : item.cliente_externo}`,
                nomeProfissional:item.nome_profissional,
                nomeServico:item.servico,
                nomeCliente:item.nome_cliente,
                nomeClienteExterno:item.cliente_externo,
                typeEvent:item.type,
                confirmacaoExterna:item.confirmacao_externa,
                profissional:item.cod_profissional,
                servico:item.cod_servico,
                cliente:item.cod_cliente,
                allDay: false,
                start: new Date(Date.parse(item.data_inicio)),
                end: new Date(Date.parse(item.data_fim))
              }
              return o
        })
        console.log('eventos pos payload', eventosAgenda)
        setEventos(eventosAgenda)
    })
    .catch(error=>{   
    })
  }

  const handleFiltrarListaEventos = ()=>{
    let eventos = [...listaEventos]
    let profissional = profissionalFiltro.cod_profissional

    console.log('..................eventos',eventos)

    if(profissional == 0 ){
      setEventosFiltrados(eventos)
    }else{
      let listaFiltrada = eventos.filter(evento=>{
        return evento.profissional == profissional
      })

      setEventosFiltrados(listaFiltrada)
    }

  }

  const handleExluir = ()=>{
    ApiConnectorTemplate.delete('agenda',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),...eventSelected}).then(r=>{
      setOpenEvent(false);
      searchAgenda()
     })
     .catch(error=>{   
     })
  }

  const handleConfirmar = ()=>{
    ApiConnectorTemplate.update('agendaconfirmaexterno',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento'),...eventSelected}).then(r=>{
      setOpenEvent(false);
      searchAgenda()
     })
     .catch(error=>{   
     })
  }





  return (
    <div>
       <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle id="alert-dialog-title">{`Agendamento de Serviço`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <p> Início :{inicioEvento.toLocaleString()}</p>
            <p> Fim : {fimEvento.toLocaleString()} </p> 
          
             <GridContainer>
             <GridItem xs={12} sm={12} md={12}>
                <Autocomplete
                        
                        id="cod_servico"
                        noOptionsText={'Nenhum serviço selecionado'}
                        size={'small'}
                    
                        onInputChange={(e,value)=>{
                          listaServicos.reduce((p,a)=>a.servico === value? a:p,{})
                          formik.setFieldValue("cod_servico",listaServicos.reduce((p,a)=>a.servico === value  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.cod_servico:p,''))
                        }}
                        Clientes
                        options={listaServicos.map((option) => option.servico)}
                        renderInput={(params) => (
                        <TextField 
                          {...params} 
                          label="Serviço" 
                          margin="normal" 
                        
                          name="cod_servico"
                          error={formik.touched.cod_servico && Boolean(formik.errors.cod_servico)}
                          helperText={formik.touched.cod_servico && formik.errors.cod_servico}
                          InputLabelProps={{
                            shrink: true,
                          }}
                           />
                        )}

                />
                </GridItem>             
             </GridContainer>
             <GridContainer>
             <GridItem xs={12} sm={12} md={12}>
                <Autocomplete
                        id="cod_profissional"
                        noOptionsText={'Nenhum profissional selecionado'}
                        size={'small'}
                    
                        onInputChange={(e,value)=>{
                          listaProfissionais.reduce((p,a)=>a.nome === value? a:p,{})
                          formik.setFieldValue("cod_profissional",listaProfissionais.reduce((p,a)=>a.nome === value  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.cod_profissional:p,''))
                        }}
                        Clientes
                        options={listaProfissionais.map((option) => option.nome)}
                        renderInput={(params) => (
                        <TextField 
                          {...params} 
                          label="Profissional" 
                          margin="normal" 
                        
                          name="cod_servico"
                          error={formik.touched.cod_profissional && Boolean(formik.errors.cod_profissional)}
                          helperText={formik.touched.cod_profissional && formik.errors.cod_profissional}
                          InputLabelProps={{
                            shrink: true,
                          }}
                           />
                        )}

                />
                </GridItem>             
             </GridContainer>
             <GridContainer>
             <GridItem xs={12} sm={12} md={12}>
                <Autocomplete
  
                        id="cod_cliente"
                        noOptionsText={'Nenhum cliente selecionado'}
                        size={'small'}
                    
                        onInputChange={(e,value)=>{
                          listaCliente.reduce((p,a)=>a.nome === value? a:p,{})
                          formik.setFieldValue("cod_cliente",listaCliente.reduce((p,a)=>a.nome === value  && a.cod_estabelecimento == localStorage.getItem('cod_estabelecimento')? a.cod_cliente:p,''))
                        }}
                        Clientes
                        options={listaCliente.map((option) => option.nome)}
                        renderInput={(params) => (
                        <TextField 
                          {...params} 
                          label="Cliente" 
                          margin="normal" 
                        
                          name="cod_cliente"
                          error={formik.touched.cod_cliente && Boolean(formik.errors.cod_cliente)}
                          helperText={formik.touched.cod_cliente && formik.errors.cod_cliente}
                          InputLabelProps={{
                            shrink: true,
                          }}
                           />
                        )}

                />
                </GridItem>             
             </GridContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleAgendar} color="primary" >
              Agendar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openEvent}
          onClose={handleCloseEvent}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle id="alert-dialog-title">{`Serviço Agendado`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {
              eventSelected ? <Fragment>
                                <p>Tipo de Agendamento: {eventSelected.typeEvent == 'externo' ? 'Externo':'Interno'}</p>
                                <p>Confirmacao de Agendamento: {eventSelected.typeEvent == 'interno' ? 'Confirmado':eventSelected.confirmacaoExterna == 'autorizado' ? 'Confirmado':'Pendente'}</p>
                                <p>Serviço: {eventSelected.nomeServico}</p>
                                <p>Profissional :{eventSelected.nomeProfissional}</p>
                                <p>Cliente :{eventSelected.nomeCliente ? eventSelected.nomeCliente:eventSelected.nomeClienteExterno }</p>
                                <p>Início:{eventSelected.start.toLocaleString()}</p>
                                <p>Fim:{eventSelected.end.toLocaleString()}</p>
                    
                              </Fragment>:null
            }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Fragment>
            <Button onClick={handleCloseEvent} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleExluir} color="primary" >
              Excluir
            </Button>
            {eventSelected.typeEvent == 'externo' && eventSelected.confirmacaoExterna == null ?
              <Button  color="primary" onClick={handleConfirmar} >
                  Confirmar agendamento
              </Button> : false
            }
            </Fragment>
            
          </DialogActions>
        </Dialog>
        <GridContainer>   
        <GridItem xs={12} sm={12} md={6} style={{marginBottom:'10px'}}>
                <InputLabel htmlFor="select-profissionais" style={{fontSize: "12px",marginBottom: "4px"}}>Profissional :</InputLabel>
                          <Select
                           
                            fullWidth
                            labelId="select-profissionais"
                            id="profissionais"
                            name="profissionais"
                            value={profissionalFiltro.cod_profissional}
                            onChange={(e)=>handleProfissionalAgendado(e.target.value)}
                            
                            

                          >
                                {funcaoTodos ? <MenuItem  value={0}>Todos</MenuItem> : null}
                                {
                                  listaProfissionais.map(item=>{
                                    return <MenuItem  value={item.cod_profissional}>{item.nome}</MenuItem>
                                  })
                                }
                                
                               
                        </Select>
        </GridItem>
        
        <GridItem xs={12} sm={12} md={6} ><Button  color="primary" onClick={searchAgenda} >Atualizar</Button></GridItem>

        </GridContainer>
             
        <Calendar
          selectable
          localizer={localizer}
          events={listaEventosFiltrados}
          messages={{next:"Próximo",previous:"Anterior",today:"Hoje",month:"Mês",day:"Dia",agenda:"Agendamentos",week:"Semana",event:"Serviço e Profissional agendado",date:"Data",time:"Hora",noEventsInRange:"Sem serviços agendados",showMore: function(e) {
            return '+' + e + ' Agendamentos'
          }}}
          defaultView={Views.WEEK}
          scrollToTime={new Date()}
          defaultDate={new Date()}
          step={profissionalFiltro.step}
          min={profissionalFiltro.min}
          max={profissionalFiltro.max}
          onSelectEvent={event =>{
            console.log('atributos para abrir')
            console.log(Views)
            setEventSelected(event)
            setOpenEvent(true)
          }}
          style = {{height: '100vh', width: '95 % '}}
/*            onSelectSlot={(slotInfo) => alert(
            `Selecionado o horário: \n\nInício ${slotInfo.start.toLocaleString()} ` +
            `\nFim: ${slotInfo.end.toLocaleString()}` +
            `\naction: ${slotInfo.action}`
          )} */ 
          onSelectSlot={(slotInfo)=>{
            handleClickOpen(slotInfo)
          }}
          eventPropGetter={(event)=>{
              let style = {
                backgroundColor: event.typeEvent == 'externo' ?'#D53513' : 'black',
                fontSize:'9px',
                borderRadius: '5px',
                //opacity: 0.8,
                color: 'white',
                border: '0px',
                //display: 'block'
            };
            return {
                style: style
            };
          }}
          onView={(view) => {
            setCurrentView(view)
            //this.setState({currentView: view});
          }}

        />


    </div>
  );
}







    
    
    