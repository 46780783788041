import React,{useState,useEffect} from "react";
// @material-ui/core components
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select  from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
/* import CustomInput from "components/CustomInput/CustomInput.js"; */
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import avatar from "assets/img/logo.png"
// dependencias para lidar com formulário
import { useFormik } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";

// operacoes assincronas
// import PublicApiConnector from 'connectors/publicapiconnector.js'
import ApiConnectorTemplate from 'connectors/apiconnectorTemplate'



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  description:{
    textAlign: "justify"
  },
  cardRecipient:{
    height:"calc(100vh - 180px)"
  }


  
};

const TextMaskCelular = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/,' ', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

const TextMaskTelefone = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

const TextMaskCPF = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={[ /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/,'.', /[0-9]/, /[0-9]/, /[0-9]/,'-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

const TextMaskCEP = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={[ /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/,'-', /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}


const TextMaskNascimento = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      //ref={inputRef}
      ref={ref => {inputRef(ref ? ref.inputElement : null);}}
      mask={[ /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/,'/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      /* showMask */
    />
  );
}

TextMaskCelular.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskTelefone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskCPF.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskCEP.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

TextMaskNascimento.propTypes = {
  inputRef: PropTypes.func.isRequired,
};




const useStyles = makeStyles(styles);

const validationSchema = yup.object({
  servico: yup
  .string('Informe o Servico')
  .required('Serviço é um dado obrigatório'),
  cod_estabelecimento: yup
  .string('Informe o nome fantasia')
  .required('Estabelecimento é um dado obrigatório'),
  dias_para_retorno: yup
  .number().typeError("Deve ser informado um número positivo")
  .required('Dias para retorno é um dado obrigatório')
  .positive().typeError("Deve ser informado um número positivo")
  .integer().typeError("Deve ser informado um número positivo"),
  preco: yup
  .number().transform((_, value) => {
    if (value.includes('.')) {
      return null;
    }
    return +value.replace(/,/, '.');
  }).typeError("Deve ser informado um número positivo")
  .required('Preço  é um dado obrigatório')
  .positive().typeError("Deve ser informado um número positivo")
 
     
});


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}


export default function Barbearia({mode,teste}) {
 
  const componente = 'servicos'
  const classes = useStyles();
  const formik = useFormik({
     initialValues: {
      servico: mode === 'view' || mode === 'edit' ? teste.servico : '',
      cod_estabelecimento: mode === 'view' || mode === 'edit' ? teste.cod_estabelecimento : '',
      dias_para_retorno:mode === 'view' || mode === 'edit' ? teste.dias_para_retorno : '',
      preco: mode === 'view' || mode === 'edit' ? teste.preco.toFixed(2).toString().replace(".",",") : '' 
    }, 
   validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
     if(mode === "create"){
        criarServico(componente,values)
      }else if(mode === "edit"){
        let payload = {...values}
        payload.cod_servico = teste.cod_servico
        editarServico(componente,payload)
      }
 
     // criarEstabelecimento(componente,values)
      //alert(JSON.stringify(values, null, 2));
    },
  });


  const [estabelecimentos,setEstabelecimentos] = useState([])
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined)
  const [loading,setLoading] = useState(false)
  const [message,setMessage] = useState('')


/*   const handleClick = (Transition) => () => {
    console.log('entrou')
    setTransition(() => Transition);
    setOpen(true);
  }; */

  const handleClose = () => {
    setOpen(false);
  };

  async function criarServico(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.add(componente,payload)
   
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Serviço Registrado')
      formik.resetForm()
      
    }catch(error){
      console.log(error)
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Houve um erro e não foi possível registrar')

    }
  }

  async function editarServico(componente,payload,TransitionLeft){
    setLoading(true);
    try{
      const response = await ApiConnectorTemplate.update(componente,payload)
    
      setTransition(() => TransitionLeft);
      setOpen(true);
      setLoading(false);
      setMessage('Servico Atualizado')
      //formik.resetForm()
      
    }catch(error){
      console.log(error)
      if(error.status === 401){
        setTransition(() => TransitionLeft);
        setOpen(true);
        setLoading(false);
        setMessage('Não foram encontradas alterações para realizar')
      }
    }
  }

  useEffect(()=>{
    ApiConnectorTemplate.list('estabelecimento',{cod_estabelecimento:localStorage.getItem('cod_estabelecimento')}).then(r=>{
      console.log(r)
      setEstabelecimentos(r)
    })
  },[]) 


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.cardRecipient}>
            <CardHeader color="primary" /* style={{background:"black"}} */>
              <h4 className={classes.cardTitleWhite}>Dados Cadastrais do Serviço</h4>
              <p className={classes.cardCategoryWhite}>
              Edição e Exclusão de serviços
              </p>
              {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
            </CardHeader>
            <CardBody>
              <GridContainer >
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    disabled ={mode == 'create' || mode == 'edit' ? false : true}
                    fullWidth
                    id="servico"
                    name="servico"
                    label="Nome do Serviço"
                    value={formik.values.servico}
                    onChange={formik.handleChange}
                    error={formik.touched.servico && Boolean(formik.errors.servico)}
                    helperText={formik.touched.servico && formik.errors.servico}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer >

                <GridItem xs={12} sm={12} md={4}>
                <InputLabel htmlFor="select-estabelecimento" style={{fontSize: "12px",marginBottom: "4px"}}>Estabelecimento</InputLabel>
                          <Select
                            disabled ={mode == 'create' || mode == 'edit' ? false : true}
                            fullWidth
                            labelId="select-estabelecimento"
                            id="cod_estabelecimento"
                            name="cod_estabelecimento"
                            value={formik.values.cod_estabelecimento}
                            onChange={formik.handleChange}
                            error={formik.touched.cod_estabelecimento && Boolean(formik.errors.cod_estabelecimento)}
                            helperText={formik.touched.cod_estabelecimento && formik.errors.cod_estabelecimento}
                          >
                           {estabelecimentos.map(estabelecimento=>
                                <MenuItem key={estabelecimento.cod_estabelecimento} value={estabelecimento.cod_estabelecimento}>{estabelecimento.estabelecimento}</MenuItem>
                            )}
                        </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <TextField
                    disabled ={mode == 'create' || mode == 'edit'? false : true}
                    fullWidth
                    id="dias_para_retorno"
                    name="dias_para_retorno"
                    label="Dias para Retorno"
                    value={formik.values.dias_para_retorno}
                    onChange={formik.handleChange}
                    error={formik.touched.dias_para_retorno && Boolean(formik.errors.dias_para_retorno)}
                    helperText={formik.touched.dias_para_retorno && formik.errors.dias_para_retorno}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <TextField
                    disabled ={mode == 'create' || mode == 'edit'? false : true}
                    fullWidth
                    id="preco"
                    name="preco"
                    label="Preço (R$)"
                    value={formik.values.preco}
                    onChange={formik.handleChange}
                    error={formik.touched.preco && Boolean(formik.errors.preco)}
                    helperText={formik.touched.preco && formik.errors.preco}
                    InputLabelProps={{
                      shrink: true,
                    }}

                  />
                </GridItem>
              </GridContainer  >
             {loading ? <LinearProgress style={{marginTop:"20px"}} /> : null}
            </CardBody>
            <CardFooter>
              {mode == 'create' ? <Button onClick={formik.handleSubmit} color="primary">Criar Servico</Button> : mode == 'edit' ? <Button onClick={formik.handleSubmit}  color="primary">Editar Serviço</Button>:null}
              
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={transition}
        message={message}
        key={transition ? transition.name : ''}
      />


    </div>
  );
}
